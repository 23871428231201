import { getApiV3PromoBannersSizes, useQuery } from '@affiliate-cabinet/rest'
import { PromoNewComponents, ResponseErrorInterface } from '@affiliate-cabinet/types'

export const usePromoBannerSizes = (imageSizes?: string[]) => {
  const { data, isFetching, error } = useQuery<unknown, ResponseErrorInterface, PromoNewComponents['schemas']['BannerSizes']>(
    ['usePromoBannerSizes'],
    () => getApiV3PromoBannersSizes(),
    {
      enabled: !imageSizes,
    },
  )

  return { bannerSizes: data?.size || [], bannerSizesIsLoading: isFetching, bannerSizesError: error }
}
