import { DomainsInterface } from '../../../../../appConfig'

export const resolveDomain = (isForcedProduction?: boolean): string => {
  const host = window.location.hostname

  const config: DomainsInterface = window.__APP_CONFIG__.domains

  if (isForcedProduction || (window.location.protocol === 'http:' && host === 'localhost')) {
    return config.prod.api
  }

  // Prod
  if (config.prod.apps.includes(host)) {
    return config.prod.api
  }

  // Int
  if (config.int.apps.includes(host)) {
    return config.int.api
  }

  // Sb
  if (config.sb.isSandbox(host)) {
    return config.sb.api(host)
  }

  return ''
}
