import { PostbacksTypesApiPaths, PostbacksTypesComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(PostbacksTypesApiPaths.GetApiV3PostbacksUserSettings, {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const getPostbacksSettings = async () => await client.get<PostbacksTypesComponents['schemas']['UserSettings']>('')
