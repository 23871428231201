import { UsersTypesApiPaths, UsersTypesComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(UsersTypesApiPaths.PostApiV3AuthSignup, {}, resolveDomain(), { mode: 'cors', referrerPolicy: 'no-referrer' })

export const signUpHandler = async (user: UsersTypesComponents['schemas']['Signup']) => {
  await client.post<UsersTypesComponents['schemas']['Signup'], UsersTypesComponents['schemas']['SignupResponse']>(``, user, {
    'Content-Type': 'application/json',
  })
}
