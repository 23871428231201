import React, { FC } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Avatar, Box, Copy, Group, Stack, Text } from '@affstore-ds/uikit'

import { ManagerContactPropertiesInterface } from './ManagerContact.types'

import { buttonCopyTranslationKeys } from '../../../../../../shared/translations/buttonCopy.translationKeys'
import { STATIC_COPY_BUTTON_SELECTOR } from '../../constants/staticSelectors'

import { useManagerContactStyles } from './ManagerContact.styled'

export const ManagerContact: FC<ManagerContactPropertiesInterface> = ({ contactAvatar, contactName, contactType, ...rest }) => {
  const { i18n } = useI18n()

  const { classes, cx } = useManagerContactStyles()

  return (
    <Group className={classes.contactWrapper} spacing={8} align="flex-start" {...rest}>
      <Avatar className={classes.avatar}>{contactAvatar}</Avatar>
      <Stack spacing={0}>
        <Text elementSize="s" elementVariant="buttons" colorVariant="greyIron.18" className={classes.text}>
          {contactName}
        </Text>
        <Text elementSize="s" elementVariant="default" colorVariant="greyIron.12" className={cx(classes.text, classes.textCapitalize)}>
          {contactType}
        </Text>
      </Stack>
      <Box className={cx(classes.copyButton, STATIC_COPY_BUTTON_SELECTOR)} component="div" ml="auto">
        <Copy value={contactName} textButtonCopied={i18n.t(buttonCopyTranslationKeys.tooltipCopied)} />
      </Box>
    </Group>
  )
}
