import { I18nDictionaryLanguageKeysType } from '@affiliate-cabinet/i18n'

const en = 'https://player.vimeo.com/video/840499884'

export const mapLocaleToGuideVideoUrl: Record<I18nDictionaryLanguageKeysType, string> = {
  en,
  de: 'https://player.vimeo.com/video/851873419',
  es: 'https://player.vimeo.com/video/851873433',
  it: 'https://player.vimeo.com/video/851873463',
  pt: 'https://player.vimeo.com/video/851873481',
  ru: 'https://player.vimeo.com/video/851873506',
  sv: 'https://player.vimeo.com/video/851873527',
  th: 'https://player.vimeo.com/video/851873554',
  id: 'https://player.vimeo.com/video/851873450',
  zh: 'https://player.vimeo.com/video/851873402',
  vi: 'https://player.vimeo.com/video/851873584',
}
