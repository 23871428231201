import React, { FC } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Select, SelectPropertiesInterface } from '@affstore-ds/uikit'

import { useOffersModelsByAdvertiserId } from '../../entities/offer/model/useOffersModelsByAdvertiserId'

import { InputLoader } from '../../shared/components/InputLoader'
import { commonTranslationKeys } from '../../shared/translations/common.translationKeys'
import { selectTranslationKeys } from '../../shared/translations/select.translationKeys'

import { AdvertiserModelItem } from './components/OffersModelsByAdvertiserSelectItem'
import { OffersModelsByAdvertiserSelectTranslationKeys } from './translations/OffersModelsByAdvertiserSelect.translationKeys'

export const OffersModelsByAdvertiserSelect: FC<Omit<SelectPropertiesInterface, 'data'> & { advertiserId: number }> = ({
  label,
  disabled,
  advertiserId,
  ...rest
}) => {
  const { i18n } = useI18n()

  const { advertisersModels, isFetching } = useOffersModelsByAdvertiserId(advertiserId)

  return (
    <Select
      clearable={false}
      data={advertisersModels}
      itemComponent={AdvertiserModelItem}
      label={label || i18n.t(OffersModelsByAdvertiserSelectTranslationKeys.settingsAffiliateModel)}
      data-testid="advertiser-model-select"
      placeholder={i18n.t(selectTranslationKeys.selectPlaceholder)}
      nothingFound={i18n.t(commonTranslationKeys.commonNoData)}
      disabled={disabled || isFetching}
      rightSection={isFetching && <InputLoader />}
      {...rest}
    />
  )
}
