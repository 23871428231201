import React, { forwardRef } from 'react'

import { Stack, Text } from '@affstore-ds/uikit'

import { OffersModelsByAdvertiserSelectItemInterface } from './OffersModelsByAdvertiserSelectItem.types'

export const AdvertiserModelItem = forwardRef<HTMLDivElement, OffersModelsByAdvertiserSelectItemInterface>(
  ({ label, description, ...others }: OffersModelsByAdvertiserSelectItemInterface, reference) => (
    <Stack ref={reference} spacing={0} {...others}>
      <Text elementVariant="default" elementSize="s" colorVariant={others.disabled ? 'greyIron.9' : 'greyIron.20'}>
        {label}
      </Text>
      {description && (
        <Text elementVariant="default" elementSize="s" colorVariant="raspberry.12">
          {description}
        </Text>
      )}
    </Stack>
  ),
)

AdvertiserModelItem.displayName = 'PaymentsAccountItem'
