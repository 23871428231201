import { BillingTypesComponents } from '@affiliate-cabinet/types'

import { BillingPathBaseEnum, BillingPathsEnum } from '../types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(BillingPathBaseEnum['/api/rest/affiliate'], {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const getNextPaymentDate = () => client.get<BillingTypesComponents['schemas']['PaymentDate']>(BillingPathsEnum['/payments/next-payment-date'])
