import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import { useQuery } from '@affiliate-cabinet/rest'

import { I18nDictionaryLanguageKeysType } from '../languages/types'
import { I18nProviderInterface } from './types'

import { useLinkClickHandler } from '../hooks/useLinkClickHandler'

import { dictionaryLoad } from '../dictionary/dictionaryLoad'
import { languagesKeys } from '../languages'

import { i18n, I18nContext, i18nTranslationHandler } from './context'
import { useHtmlStyles } from './I18nProvider.styled'

const defaultLanguage: I18nDictionaryLanguageKeysType = 'en'

export const I18nProvider: FC<I18nProviderInterface> = ({ children, projects, keysAsValues, allowedLocales }) => {
  const allowedLanguages = useMemo(
    () => languagesKeys.filter((language) => (allowedLocales ? allowedLocales.includes(language) : languagesKeys)),
    [allowedLocales],
  )
  const [locale, setLocale] = useState<I18nDictionaryLanguageKeysType>()
  const [localeLocal, setLocaleLocal] = useState<I18nDictionaryLanguageKeysType>()

  const { classes } = useHtmlStyles()

  const setData = useCallback((locale: I18nDictionaryLanguageKeysType, dictionary: Record<string, string>) => {
    if (dictionary) {
      i18nTranslationHandler.locale(locale)
      i18nTranslationHandler.set(locale, dictionary)
      void i18next.changeLanguage(locale)
      setLocale(locale)
    }
  }, [])

  const handleLocale = useCallback((lang?: I18nDictionaryLanguageKeysType) => {
    const localLocale = lang as string

    if (!lang) {
      return localLocale
    }

    setLocaleLocal(localLocale as I18nDictionaryLanguageKeysType)

    return localLocale
  }, [])

  useEffect(() => {
    i18n.handleLocale = handleLocale

    i18next
      .use(LanguageDetector)
      .init({
        fallbackLng: defaultLanguage,
        supportedLngs: allowedLanguages,
        nonExplicitSupportedLngs: true,
        load: 'languageOnly',
        lowerCaseLng: true,
        cleanCode: true,
      })
      .then(() => {
        let autoDetectedLanguage = i18next.language.split('-')[0] as I18nDictionaryLanguageKeysType

        if (!allowedLanguages.includes(autoDetectedLanguage)) {
          autoDetectedLanguage = defaultLanguage
        }

        handleLocale(autoDetectedLanguage)
      })
      .catch(console.error)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useLinkClickHandler()

  const { isFetching: isTranslationsLoading } = useQuery(
    ['translations', localeLocal, projects],
    () => Promise.all(projects.map((project) => dictionaryLoad(localeLocal as I18nDictionaryLanguageKeysType, project.alias, project.version))),
    {
      enabled: Boolean(localeLocal && projects.some((project) => project.alias)),
      onSuccess: (dictionaries) => {
        const mergedDictionary = dictionaries.reduce((accumulator, dictionary) => ({ ...accumulator, ...dictionary }), {})

        setData(localeLocal as I18nDictionaryLanguageKeysType, mergedDictionary)
      },
      onError: console.error,
      retry: false,
      retryDelay: 2000,
    },
  )

  useEffect(() => {
    i18nTranslationHandler.setKeysAsValues(keysAsValues)
  }, [keysAsValues])

  const value = useMemo(() => {
    i18n.isTranslationsLoading = isTranslationsLoading

    return i18n
  }, [isTranslationsLoading])

  return (
    <I18nContext.Provider value={{ ...value }}>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      <div className={classes.html}>{children}</div>
    </I18nContext.Provider>
  )
}
