import React, { FC, memo, PropsWithChildren } from 'react'

import { useNavigationHandler } from '../hooks/useNavigationHandler'

import { ConfirmRedirectModal } from './components/ConfirmRedirectModal'
import { NavigationContainerContext } from './context'

export const NavigationContainerProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const { isRedirectConfirmationRequired, isRedirectAllowed, redirect, allowRedirect, blockRedirect, resetRedirectRestrictions } =
    useNavigationHandler()

  const value = {
    isRedirectConfirmationRequired,
    isRedirectAllowed,
    redirect,
    allowRedirect,
    blockRedirect,
    resetRedirectRestrictions,
  }

  return (
    <NavigationContainerContext.Provider value={value}>
      {children}
      <ConfirmRedirectModal />
    </NavigationContainerContext.Provider>
  )
})

NavigationContainerProvider.displayName = 'NavigationContainerProvider'
