import React, { FC, PropsWithChildren } from 'react'

import { ErrorBlock } from '@affstore-ds/uikit'

import { ErrorPagesPropertiesInterface } from './types/ErrorPage.types'

import { useErrorPageStyles } from './ErrorPage.styled'

export const ErrorPage: FC<ErrorPagesPropertiesInterface & PropsWithChildren> = (properties) => {
  const { title, description, codeError = 500, children } = properties
  const { classes } = useErrorPageStyles({ codeError })

  return (
    <div className={classes.pageContainer}>
      <ErrorBlock code={codeError} title={title} description={description}>
        {children}
      </ErrorBlock>
    </div>
  )
}
