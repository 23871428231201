import { localesEntityTranslationKeys } from './translations/localesEntity.translationKeys'

// TODO: TASK-https://mbt.tpondemand.com/entity/394715-fe-add-request-for-locales-in
const locales: (keyof typeof localesEntityTranslationKeys)[] = [
  'af_AF',
  'ar_AR',
  'de_DE',
  'en_US',
  'es_ES',
  'fr_FR',
  'hi_IN',
  'id_ID',
  'it_IT',
  'ko_KO',
  'ms_MY',
  'nl_NL',
  'no_NO',
  'pt_PT',
  'ru_RU',
  'sv_SE',
  'ta_IN',
  'th_TH',
  'tr_TR',
  'vi_VN',
  'zh_CN',
  'zh_TW',
  'zu_AF',
]

const LOCALE_LIST = locales.map((locale: keyof typeof localesEntityTranslationKeys) => ({
  value: locale,
  translationKey: localesEntityTranslationKeys[locale],
}))

export const localesEntity = {
  LOCALE_LIST,
  localesEntityTranslationKeys,
}
