import { OffersTypesApiPaths, OffersTypesComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(OffersTypesApiPaths.GetApiAdvertisers, {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const getApiAdvertisers = () => client.get<OffersTypesComponents['schemas']['AdvertisersCollection']>('')
