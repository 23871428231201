import React from 'react'

import { NavigationContainerContextInterface } from './types'

export const NavigationContainerContext = React.createContext<NavigationContainerContextInterface>({
  isRedirectConfirmationRequired: false,
  isRedirectAllowed: true,
  redirect: () => {
    throw new Error('Not implemented redirect method')
  },
  allowRedirect: () => {
    throw new Error('Not implemented allowRedirect method')
  },
  blockRedirect: () => {
    throw new Error('Not implemented blockRedirect method')
  },
  resetRedirectRestrictions: () => {
    throw new Error('Not implemented resetRedirectRestrictions method')
  },
})

NavigationContainerContext.displayName = 'NavigationContainerContext'
