import { createStyles } from '@affstore-ds/uikit'

export const useGuideModalStyles = createStyles(() => ({
  buttonGroup: {
    alignSelf: 'flex-end',
  },
  videoWrapper: {
    position: 'relative',
  },
}))
