import { linkTypesEntityTranslationKeys } from './translations/linkTypesEntity.translationKeys'

const linkTypeToTranslationKeys: {
  [key: string]: { labelTKey: string; tooltipTKey?: string }
} = {
  web: {
    labelTKey: linkTypesEntityTranslationKeys.offersPageOfferTabLinksLinkWeb,
  },
  mix: {
    labelTKey: linkTypesEntityTranslationKeys.offersPageOfferTabLinksLinkMix,
    tooltipTKey: linkTypesEntityTranslationKeys.offersPageOfferTabLinksLinkMixTooltip,
  },
  pwa: {
    labelTKey: linkTypesEntityTranslationKeys.offersPageOfferTabLinksLinkPwa,
    tooltipTKey: linkTypesEntityTranslationKeys.offersPageOfferTabLinksLinkPwaTooltip,
  },
  ios: {
    labelTKey: linkTypesEntityTranslationKeys.offersPageOfferTabLinksLinkIos,
    tooltipTKey: linkTypesEntityTranslationKeys.offersPageOfferTabLinksLinkIosTooltip,
  },
  android: {
    labelTKey: linkTypesEntityTranslationKeys.offersPageOfferTabLinksLinkAndroid,
  },
}

export const linkTypesEntity = {
  linkTypeToTranslationKeys,
}
