import { I18nDictionaryLanguageKeysType } from './types'

export const languagesMap: Record<I18nDictionaryLanguageKeysType, string> = {
  en: 'en_US',
  ru: 'ru_RU',
  it: 'it_IT',
  de: 'de_DE',
  es: 'es_ES',
  pt: 'pt_PT',
  zh: 'zh_CN',
  sv: 'sv_SE',
  th: 'th_TH',
  id: 'id_ID',
  vi: 'vi_VN',
}

export const languagesKeys = Object.keys(languagesMap) as I18nDictionaryLanguageKeysType[]

export const languagesNameMap: Record<I18nDictionaryLanguageKeysType, string> = {
  en: 'English',
  ru: 'Русский',
  it: 'Italiano',
  de: 'Deutsch',
  es: 'Español',
  pt: 'Português',
  zh: '中文',
  sv: 'Svenska',
  th: 'ไทย',
  id: 'Bahasa Indonesia',
  vi: 'Tiếng Việt',
}
