import { useCallback } from 'react'

import { useAdvertisers } from '../useAdvertisers'

export const useAdvertiser = (id?: number | string) => {
  const { data: advertisersData, loading } = useAdvertisers({ withUnknownAdvertiser: true })

  const findAdvertiserById = useCallback(
    (id?: number | string) => {
      const lastAdvertiser = advertisersData[advertisersData.length - 1]

      if (!id) {
        return lastAdvertiser
      }

      return advertisersData.find((advertiserItem) => Number(advertiserItem.value) === Number(id)) || lastAdvertiser
    },
    [advertisersData],
  )

  return {
    advertiser: findAdvertiserById(id),
    findAdvertiserById,
    loading,
  }
}
