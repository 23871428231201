import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Button, Group } from '@affstore-ds/uikit'

import { ErrorPage } from '../../shared/components/ErrorPage'
import { errorPageTranslationKeys } from '../../shared/translations/errorPage.translationKeys'

export const InternalError = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()

  return (
    <ErrorPage
      codeError={500}
      title={i18n.t(errorPageTranslationKeys.errorPage500Title)}
      description={<>{i18n.t(errorPageTranslationKeys.errorPage500Description)}</>}
    >
      <Group mt={24}>
        <Button onClick={() => navigate('/')}>{i18n.t(errorPageTranslationKeys.errorPageButtonGoToHomePage)}</Button>
      </Group>
    </ErrorPage>
  )
}
