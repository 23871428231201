import { resolveDomain, RestClient } from '@affiliate-cabinet/rest'

import { I18nDictionaryLanguageKeysType } from '../languages/types'

import { languagesMap } from '../languages'

const client = new RestClient('/api/translations', {}, resolveDomain(!window.__APP_CONFIG__.domains.sb.isSandbox(window.location.host)), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const dictionaryLoad = async (langKey: I18nDictionaryLanguageKeysType, projectAlias: string, version?: string) => {
  if (!projectAlias) {
    throw new Error('You need to pass project alias')
  }

  const parameters: { version?: string } = {}

  if (version) {
    parameters.version = version
  }

  try {
    return client.get<Record<string, string>>(`${projectAlias}-${languagesMap[langKey]}.json`, parameters)
  } catch (error) {
    console.error(error, "Can't get dictionary from translation service")

    return {}
  }
}
