import { useAuth } from '@affiliate-cabinet/auth'

import { useAnalyticsEvents } from './useAnalyticsEvents'

import { getEventDataWithUserId, getSystemEventData } from '../utils/getEventData'

type UseSendOnClickParametersType = {
  eventName: string
  gaEventName?: string
}

export const useSendOnClick = ({ eventName, gaEventName }: UseSendOnClickParametersType) => {
  const { sendEvent, sendGaEvent } = useAnalyticsEvents()
  const {
    auth: { user },
  } = useAuth()

  const systemEventData = getSystemEventData(eventName)
  const eventDataWithUserId = getEventDataWithUserId(eventName, user?.id)

  const eventData = {
    ...systemEventData,
    ...eventDataWithUserId,
  }

  const gaEventData = {
    name: gaEventName,
  }

  return {
    sendEvent,
    eventData,
    sendGaEvent,
    gaEventData,
  }
}
