interface MenuItemWithAvailabilityConditionInterface {
  value: string
  isAvailable?: boolean
}

// TODO: remove
/** Deprecated (all menu items are available for all users) */
export const useUnavailableNavMenuItems = () => {
  const menuItemsWithAvailabilityCondition: MenuItemWithAvailabilityConditionInterface[] = []

  return {
    // eslint-disable-next-line sonarjs/no-empty-collection
    data: menuItemsWithAvailabilityCondition.filter((item) => !item.isAvailable).map((item) => item.value),
  }
}
