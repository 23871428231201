import React, { FC } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { FilterButtons as FilterButtonsComponent } from '@affstore-ds/uikit'

import { FilterButtonsParametersInterface } from './FilterButtons.types'

import { filtersTranslationKeys } from '../../shared/translations/filters.translationKeys'

export const FilterButtons: FC<FilterButtonsParametersInterface> = ({ form, resetFormValues, filters, ...rest }) => {
  const { i18n } = useI18n()

  const handleClearAll = () => {
    form.setValues(resetFormValues)
    form.resetDirty(resetFormValues)
    form.resetTouched()
  }

  return (
    <FilterButtonsComponent
      formValues={form.values}
      onClearAll={handleClearAll}
      clearAllButtonText={i18n.t(filtersTranslationKeys.statisticsFiltersClearAll)}
      submitButtonText={i18n.t(filtersTranslationKeys.statisticsFiltersApplyFilters)}
      filters={filters}
      resetFormValues={resetFormValues}
      {...rest}
    />
  )
}
