import { EventDataParametersType } from '@affiliate-frontend-analytics/events'

import { EventParameterName } from '../../../config/events'
import { EventName, EVENTS_WITH_USER_ID, GaEventName } from '../../../config/events/constants'
import { offerTabSettings } from '../../navigation/NavMenuData'

export const getSystemEventData = (name: string) => ({
  name,
  parameters: {
    [EventParameterName.url]: window.location.href,
  },
})

export const getEventDataWithUserId = (eventName: string, id?: number): Partial<EventDataParametersType> =>
  EVENTS_WITH_USER_ID.includes(eventName) && Boolean(id) ? { [EventParameterName.userId]: id } : {}

const mapOfferTabToEventName = {
  [offerTabSettings.activation]: {
    eventName: EventName.offerActivationOpen,
    gaEventName: GaEventName.offerActivationOpen,
  },
  [offerTabSettings.advertiser]: {
    eventName: EventName.offerAdvertiserOpen,
    gaEventName: GaEventName.offerAdvertiserOpen,
  },
  [offerTabSettings.links]: {
    eventName: EventName.offerLinksOpen,
    gaEventName: GaEventName.offerLinksOpen,
  },
  [offerTabSettings.rates]: {
    eventName: EventName.offerRatesOpen,
    gaEventName: GaEventName.offerRatesOpen,
  },
}

export const getEventNameByOfferTabValue = (tabValue: string) => mapOfferTabToEventName[tabValue]?.eventName ?? EventName.offerRulesOpen
export const getGaEventNameByOfferTabValue = (tabValue: string) => mapOfferTabToEventName[tabValue]?.gaEventName ?? GaEventName.offerRulesOpen
