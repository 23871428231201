import { useState } from 'react'

type UseModalType = {
  opened: boolean
  openModal: () => void
  closeModal: () => void
}

export const useModal = (): UseModalType => {
  const [opened, setIsOpened] = useState<boolean>(false)
  const openModal = () => setIsOpened(true)
  const closeModal = () => setIsOpened(false)

  return { opened, openModal, closeModal }
}
