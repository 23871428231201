export const localesEntityTranslationKeys = {
  af_AF: 'cmn_locales.af_AF',
  ar_AR: 'cmn_locales.ar_AR',
  de_DE: 'cmn_locales.de_DE',
  en_US: 'cmn_locales.en_US',
  es_ES: 'cmn_locales.es_ES',
  fr_FR: 'cmn_locales.fr_FR',
  hi_IN: 'cmn_locales.hi_IN',
  id_ID: 'cmn_locales.id_ID',
  it_IT: 'cmn_locales.it_IT',
  ko_KO: 'cmn_locales.ko_KO',
  ms_MY: 'cmn_locales.ms_MY',
  nl_NL: 'cmn_locales.nl_NL',
  no_NO: 'cmn_locales.no_NO',
  pt_PT: 'cmn_locales.pt_PT',
  ru_RU: 'cmn_locales.ru_RU',
  sv_SE: 'cmn_locales.sv_SE',
  ta_IN: 'cmn_locales.ta_IN',
  th_TH: 'cmn_locales.th_TH',
  tr_TR: 'cmn_locales.tr_TR',
  vi_VN: 'cmn_locales.vi_VN',
  zh_CN: 'cmn_locales.zh_CN',
  zh_TW: 'cmn_locales.zh_TW',
  zu_AF: 'cmn_locales.zu_AF',
  language: 'promo.languageSelectLabel',
}
