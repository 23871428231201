import { addTransitionForCssProperties, createStyles, mediaHelper, rem } from '@affstore-ds/uikit'

import { STATIC_COPY_BUTTON_SELECTOR } from '../../constants/staticSelectors'

export const useManagerContactStyles = createStyles(() => ({
  contactWrapper: {
    ':hover': {
      [`& .${STATIC_COPY_BUTTON_SELECTOR}`]: {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: rem(118),
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  avatar: {
    minWidth: rem(36),
    width: rem(36),
    height: rem(36),
  },
  copyButton: {
    visibility: 'hidden',
    opacity: 0,
    transition: addTransitionForCssProperties(['opacity']),

    [`${mediaHelper.md}`]: {
      visibility: 'visible',
      opacity: 1,
    },
  },
}))
