import { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from '@affiliate-cabinet/auth'
import { useLocalStorage } from '@affstore-ds/helpers'

import { GUIDE_BY_OLD_FORM, GUIDE_BY_OLD_FORM_QUERY_PARAM } from '../constants/storage'

export const useOldLoginGuide = () => {
  const {
    auth: { pushOnLogoutCallback, removeOnLogoutCallback },
  } = useAuth()
  const isPushedInLogoutCallback = useRef(false)

  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])

  const [isOldLoginGuide, setIsOldLoginGuide] = useLocalStorage({
    key: GUIDE_BY_OLD_FORM,
    defaultValue: localStorage.getItem(GUIDE_BY_OLD_FORM) === 'true',
    deserialize: (value) => value === 'true',
  })

  useEffect(() => {
    const byOldFormParameterValue = query.get(GUIDE_BY_OLD_FORM_QUERY_PARAM)
    const isByOldFormHasAllowedValues = typeof byOldFormParameterValue === 'string' && ['0', '1'].includes(byOldFormParameterValue)

    if (isByOldFormHasAllowedValues) {
      setIsOldLoginGuide(byOldFormParameterValue === '1')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  useEffect(() => {
    const logOutCallbackKey = GUIDE_BY_OLD_FORM

    if (!isPushedInLogoutCallback.current) {
      isPushedInLogoutCallback.current = true

      pushOnLogoutCallback(logOutCallbackKey, () => setIsOldLoginGuide(false))
    }

    return () => {
      removeOnLogoutCallback(logOutCallbackKey)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { isOldLoginGuide, setIsOldLoginGuide }
}
