import React, { FC } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { getContentImagePath } from '@affstore-ds/images'
import { ErrorBlock, Image } from '@affstore-ds/uikit'

import { TableEmptyDataInterface } from './TableEmptyData.types'

import { commonTranslationKeys } from '../../translations/common.translationKeys'

export const TableEmptyData: FC<TableEmptyDataInterface> = (properties) => {
  const { title, description, withDescription = true, mt = 48, mb = 52, ...rest } = properties
  const { i18n } = useI18n()

  return (
    <ErrorBlock
      withIcon
      icon={<Image src={getContentImagePath('EmptyBox')} width={156} mb={26} />}
      title={title ?? i18n.t(commonTranslationKeys.commonNoData)}
      description={withDescription ? description ?? i18n.t(commonTranslationKeys.tableError) : undefined}
      mt={mt}
      mb={mb}
      {...rest}
    />
  )
}
