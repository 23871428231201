import { CoreTypesApiPaths, CoreTypesComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(CoreTypesApiPaths.AffiliateController_updateProfile, {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const onChangeLocaleHandler = async (data: Pick<CoreTypesComponents['schemas']['UpdateProfileQueryDto'], 'locale'>) => {
  await client.patch<Pick<CoreTypesComponents['schemas']['UpdateProfileQueryDto'], 'locale'>, Response>(``, data, {
    'Content-Type': 'application/json',
  })
}
