import { UsersTypesComponents } from '@affiliate-cabinet/types'

export const useFirstLastName = (
  legalProfile?: UsersTypesComponents['schemas']['UserProfile']['legal_profile'],
): Record<'firstName' | 'lastName', string> => {
  const baseValue = {
    firstName: '-',
    lastName: '-',
  }

  if (!legalProfile) {
    return baseValue
  }

  if ('first_name' in legalProfile) {
    return {
      firstName: legalProfile.first_name,
      lastName: legalProfile.last_name,
    }
  }

  if ('representative_first_name' in legalProfile) {
    return {
      firstName: legalProfile.representative_first_name,
      lastName: legalProfile.representative_last_name,
    }
  }

  return baseValue
}
