import React, { FC } from 'react'

import { mediaHelper, Navbar, ScrollArea, Stack, useMediaQuery } from '@affstore-ds/uikit'

import { useScrollPosition } from './hooks/useScrollPosition'

import { getBrandConfig } from '../../config/brands'

import { NavLinkLogo } from './components/NavLinkLogo'
import { NavMenuButtonLogout } from './components/NavMenuButtonLogout'
import { NavMenuLinks } from './components/NavMenuLinks'
import { NavMenuManagerInfo } from './components/NavMenuManagerInfo'
import { useNavMenuStyles } from './NavMenu.styled'

const { brandConfig } = getBrandConfig()

export const NavMenu: FC = () => {
  const { isShowManagerBlockShadow, onScrollPositionChange } = useScrollPosition()

  const isMobileResolution = useMediaQuery(mediaHelper.maxDeviceWidthValue('md'))

  const { classes, cx } = useNavMenuStyles()

  return (
    <Navbar className={classes.container}>
      <Navbar.Section h={24} pl={8} mr={16}>
        <NavLinkLogo iconName={brandConfig.logo} />
      </Navbar.Section>

      <Navbar.Section
        style={{ minHeight: isMobileResolution ? 620 : 0 }}
        grow={!isMobileResolution}
        component={ScrollArea}
        mt={24}
        onScrollPositionChange={onScrollPositionChange}
        pr={16}
      >
        {/* scrollable content here */}
        <NavMenuLinks />
      </Navbar.Section>

      <Navbar.Section>
        {/* Footer with user */}
        <Stack spacing={16} mr={16} className={cx({ [classes.navMenuManagerBlockTopShadowOnScroll]: isShowManagerBlockShadow })}>
          <NavMenuManagerInfo />
          <NavMenuButtonLogout />
        </Stack>
      </Navbar.Section>
    </Navbar>
  )
}
