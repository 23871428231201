import { DATA_TRANSLATION_ATTRIBUTE } from '@affiliate-cabinet/i18n'
import { createStyles } from '@affstore-ds/uikit'

export const useMainPageHeaderStyles = createStyles((theme) => ({
  subtitle: {
    [`&& [data-translation="${DATA_TRANSLATION_ATTRIBUTE}"]`]: {
      font: theme.other.fonts.desktop.s.m.default,
    },
  },
}))
