import { createStyles } from '@affstore-ds/uikit'

export const useTransferLoaderStyles = createStyles(() => ({
  container: {
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    width: 48,
    height: 48,
  },
}))
