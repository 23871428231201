const AFFSTORE_BASIC_NAME = 'ty-ui/pg-web/p-affstore'
const AFFSTORE_TRANSFER_NAME = 'ty-ui/pg-web/o-affiliate_migration/m-funnel_'

const getFullEventName = (specificEventName: string) => `${AFFSTORE_BASIC_NAME}${specificEventName}`
const getTransferEventName = (specificEventName: string) => `${AFFSTORE_TRANSFER_NAME}${specificEventName}`

export const EventName = {
  signinOpen: getFullEventName('_signin/tr-open'),
  signupOpen: getFullEventName('_signup/tr-open'),
  resetPasswordOpen: getFullEventName('_reset_password/tr-open'),
  onboardingFirstStepOpen: getFullEventName('_onboarding_first/tr-open'),
  onboardingSecondStepOpen: getFullEventName('_onboarding_second/tr-open'),
  statisticsGeneralOpen: getFullEventName('_statistics_general/tr-open'),
  statisticsByUserOpen: getFullEventName('_statistics_by_users/tr-open'),
  promoOpen: getFullEventName('_promo/tr-open'),
  paymentsOpen: getFullEventName('_payments/tr-open'),
  top10Open: getFullEventName('_top10/tr-open'),
  offerListOpen: getFullEventName('_offers/tr-open'),
  offerLinksOpen: getFullEventName('_offer_details/o-links/tr-open'),
  offerActivationOpen: getFullEventName('_offer_details/o-activation/tr-open'),
  offerRatesOpen: getFullEventName('_offer_details/o-rates/tr-open'),
  offerAdvertiserOpen: getFullEventName('_offer_details/o-advertiser/tr-open'),
  offerAdvertiserTabOfferClick: getFullEventName('_offer_details/o-advertiser/m-offers/tr-click'),
  offerListByAdvertiserOpen: getFullEventName('_offers_by_advertisers/tr-open'),
  offerListByAdvertiserClick: getFullEventName('_offers_by_advertisers/tr-click'),
  offerListByOfferOpen: getFullEventName('_offers_by_offers/tr-open'),
  offerListByOfferClick: getFullEventName('_offers_by_offers/tr-click'),
  offerListAnyOpenProblem: getFullEventName('_offers_fail/tr-open'),
  trafficLinkCopyClick: getFullEventName('_offer_details/o-links/m-traffic_links_copy/tr-click'),
  goToPromoLinkClick: getFullEventName('_offer_details/o-links/m-go_to_promo_button/tr-click'),
  offerRulesOpen: getFullEventName('_offer_details/o-rules/tr-open'),
  guideVideoModalOpen: getTransferEventName('welcome_affstore/tr-open'),
  guideLegalModalOpen: getTransferEventName('legal_notice/tr-open'),
  guideVideoCoverClick: getTransferEventName('welcome_affstore/tr-click'),
  contestsListOpen: getFullEventName('_contest_catalog/tr-open'),
  contestClick: getFullEventName('_contest_catalog/o-contest/tr-click'),
  contestInfoOpen: getFullEventName('_contest_page_info/tr-open'),
  contestLeaderboardOpen: getFullEventName('_contest_page_leaderboard/tr-open'),
} as const

export const GaEventName = {
  onboardingFirstStepOpen: 'onboarding_first/tr-open',
  onboardingSecondStepOpen: 'onboarding_second/tr-open',

  offerLinksOpen: 'o-links/tr-open',
  offerActivationOpen: 'o-activation/tr-open',
  offerRatesOpen: 'o-rates/tr-open',
  offerAdvertiserOpen: 'o-advertiser/tr-open',
  offerRulesOpen: 'o-rules/tr-open',

  contestsListOpen: 'c_catalog/tr-open',
  contestClick: 'c_catalog/o-contest/tr-click',
  contestInfoOpen: 'c_page_info/tr-open',
  contestLeaderboardOpen: 'c_page_leaderboard/tr-open',
}

export const SYSTEM_EVENTS_NAMES = [
  EventName.signinOpen,
  EventName.signupOpen,
  EventName.resetPasswordOpen,
  EventName.onboardingFirstStepOpen,
  EventName.onboardingSecondStepOpen,
  EventName.statisticsGeneralOpen,
  EventName.statisticsByUserOpen,
  EventName.promoOpen,
  EventName.paymentsOpen,
  EventName.offerListOpen,
  EventName.offerLinksOpen,
  EventName.offerActivationOpen,
  EventName.offerRatesOpen,
  EventName.offerAdvertiserOpen,
  EventName.offerAdvertiserTabOfferClick,
  EventName.offerListByAdvertiserOpen,
  EventName.offerListByAdvertiserClick,
  EventName.offerListByOfferOpen,
  EventName.offerListByOfferClick,
  EventName.offerListAnyOpenProblem,
  EventName.trafficLinkCopyClick,
  EventName.goToPromoLinkClick,
  EventName.offerRulesOpen,
  EventName.guideVideoModalOpen,
  EventName.guideLegalModalOpen,
  EventName.guideVideoCoverClick,
  EventName.contestsListOpen,
  EventName.contestClick,
  EventName.contestInfoOpen,
  EventName.contestLeaderboardOpen,
]

export const EVENTS_WITH_USER_ID = [
  EventName.onboardingFirstStepOpen,
  EventName.onboardingSecondStepOpen,
  EventName.statisticsGeneralOpen,
  EventName.statisticsByUserOpen,
  EventName.promoOpen,
  EventName.paymentsOpen,
  EventName.offerListOpen,
  EventName.offerLinksOpen,
  EventName.offerActivationOpen,
  EventName.offerRatesOpen,
  EventName.offerAdvertiserOpen,
  EventName.offerAdvertiserTabOfferClick,
  EventName.offerListByAdvertiserOpen,
  EventName.offerListByAdvertiserClick,
  EventName.offerListByOfferOpen,
  EventName.offerListByOfferClick,
  EventName.offerListAnyOpenProblem,
  EventName.trafficLinkCopyClick,
  EventName.goToPromoLinkClick,
  EventName.offerRulesOpen,
  EventName.guideVideoModalOpen,
  EventName.guideLegalModalOpen,
  EventName.guideVideoCoverClick,
]

export const EventParameterName = {
  url: 'url',
  userId: 'user_id',
  offerId: 'offer_id',
}
