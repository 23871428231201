import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { LoaderApp } from '@affstore-ds/uikit'

import { useAffstoreTransferStatus } from '../../../entities/hooks/useAffstoreTransferStatus'

import { affstoreTransferStatusEntity } from '../../../entities/constants/affstoreTransferStatus.entity'
import { useFrom } from '../../../shared/hooks'
import { routes } from '../../../shared/routes'
import { TransferLoader } from '../../../widgets'

import { Logo } from './LoaderLogos'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Loader: FC<{ children: ReactNode }> = ({ children }) => {
  const [isFirstTranslationLoading, setIsFirstTranslationLoading] = useState(false)
  const [isFirstIs2FaEnabledLoading, setIsFirstIs2FaEnabledLoading] = useState(false)
  const [isFirstUserLoading, setIsFirstUserLoading] = useState(false)
  const {
    i18n: { isTranslationsLoading, table, locale },
  } = useI18n()
  const navigate = useNavigate()
  const location = useLocation()
  const { returnToFromURLSearch } = useFrom(routes.ROUTES_DASHBOARD.dashboard)

  const {
    auth: { isUserLoading, is2FaEnabledData, is2FaEnabledLoading, isAuthorizedData, isAuthorizedLoading },
  } = useAuth()

  const { affstoreTransferData, affstoreTransferIsFetching, affstoreTransferIsRefetching } = useAffstoreTransferStatus()
  const isTransferInProgress = useMemo(
    () => !affstoreTransferIsFetching && affstoreTransferData.transferStatus === affstoreTransferStatusEntity.AFFSTORE_TRANSFER_STATUS.inProgress,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [affstoreTransferData.transferStatus],
  )

  useEffect(() => {
    if (!isTranslationsLoading && !isUserLoading) {
      document.querySelector('#appLoaderHTML')?.remove()

      if (!isFirstTranslationLoading) {
        setIsFirstTranslationLoading(true)
      }
    }
  }, [isFirstTranslationLoading, isTranslationsLoading, isUserLoading])

  useEffect(() => {
    if (!isFirstUserLoading && !isUserLoading && isAuthorizedData !== undefined) {
      setIsFirstUserLoading(true)
    }
  }, [isAuthorizedData, isFirstUserLoading, isUserLoading])

  useEffect(() => {
    if (isAuthorizedData === 'isPending2FA' && !is2FaEnabledLoading && !isAuthorizedLoading && !isFirstIs2FaEnabledLoading) {
      if (is2FaEnabledData) {
        navigate(routes.ROUTES_AUTH.twoFA, {
          state: {
            ...location.state,
            returnTo: returnToFromURLSearch,
          },
        })
      }

      setIsFirstIs2FaEnabledLoading(true)
    }

    if (isAuthorizedData === true || isAuthorizedData === false) {
      setIsFirstIs2FaEnabledLoading(true)
    }
  }, [
    isAuthorizedData,
    is2FaEnabledData,
    isFirstIs2FaEnabledLoading,
    is2FaEnabledLoading,
    isAuthorizedLoading,
    navigate,
    location.state,
    returnToFromURLSearch,
  ])

  const isLoading =
    (affstoreTransferIsFetching && !affstoreTransferIsRefetching) ||
    (isTranslationsLoading && !isFirstTranslationLoading) ||
    (isUserLoading && !isFirstUserLoading) ||
    !isFirstIs2FaEnabledLoading ||
    !table(locale())

  if (isTransferInProgress && isAuthorizedData === true) {
    return isLoading ? <LoaderApp id="app-loader" logo={<Logo />} /> : <TransferLoader />
  }

  return isLoading ? <LoaderApp id="app-loader" logo={<Logo />} /> : <>{children}</>
}
