
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GraphqlMetricsGroupBy": [
      "StatisticsGroupByAdvertiser",
      "StatisticsGroupByAffTrack",
      "StatisticsGroupByAffTrackAndDate",
      "StatisticsGroupByAffiliateModel",
      "StatisticsGroupByCountry",
      "StatisticsGroupByDay",
      "StatisticsGroupByMonth",
      "StatisticsGroupByPlatformGroup",
      "StatisticsGroupByRegulation",
      "StatisticsGroupByWeek"
    ],
    "GraphqlReferralMetricsGroupBy": [
      "Referral",
      "StatisticsGroupByAffTrack",
      "StatisticsGroupByAffTrackAndDate",
      "StatisticsGroupByDay",
      "StatisticsGroupByMonth",
      "StatisticsGroupByWeek"
    ],
    "GraphqlTraderMetricsGroupBy": [
      "StatisticsGroupByDay",
      "StatisticsGroupByMonth",
      "StatisticsGroupByWeek",
      "TradersGroupByAdvertiser",
      "TradersGroupByTrader"
    ]
  }
};
      export default result;
    