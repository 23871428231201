import React, { memo, PropsWithChildren } from 'react'

import { AuthProvider } from '@affiliate-cabinet/auth'

import { routes } from '../../shared/routes'

export const AppAuthProvider = memo(({ children }: PropsWithChildren) => (
  <AuthProvider ROUTES_SHARED_VALUES={routes.ROUTES_SHARED_VALUES} ROUTES_ERROR={routes.ROUTES_ERROR} signInRoute={routes.ROUTES_AUTH.signIn}>
    {children}
  </AuthProvider>
))
AppAuthProvider.displayName = 'AppAuthProvider'
