import { TwoFactorAuthTypesApiPaths, TwoFactorAuthTypesComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(TwoFactorAuthTypesApiPaths.PostApiAuth2faInit, {}, resolveDomain(), { mode: 'cors', referrerPolicy: 'no-referrer' })

export const init2FaAuthHandler = (
  data: TwoFactorAuthTypesComponents['schemas']['2FaInitInput'],
): Promise<TwoFactorAuthTypesComponents['schemas']['_api_auth_2fa_init_post_200_response']> =>
  client.post<
    TwoFactorAuthTypesComponents['schemas']['2FaInitInput'],
    TwoFactorAuthTypesComponents['schemas']['_api_auth_2fa_init_post_200_response']
  >('', data, {
    'Content-Type': 'application/json',
  })
