import * as Sentry from '@sentry/react'

import { ResponseErrorCallbackType } from '@affstore-ds/restclient/dist/restclient/types'

export const errorCallback: ResponseErrorCallbackType = (response) => {
  const { response: realResponse, ...otherErrorInfo } = response

  if ([400, 401, 429].includes(realResponse.status)) {
    return
  }

  Sentry.withScope((scope) => {
    scope.setExtras({
      httpStatus: realResponse.status,
      httpURL: realResponse.url,
      httpType: realResponse.type,
    })

    try {
      const stringifiedError = JSON.stringify(otherErrorInfo)

      scope.setExtra('error', stringifiedError)
      Sentry.captureException(new Error(stringifiedError), scope)
    } catch {
      console.error(otherErrorInfo)

      scope.setExtra('error', otherErrorInfo)
      Sentry.captureException(otherErrorInfo, scope)
    }
  })
}
