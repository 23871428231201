import React, { memo, PropsWithChildren } from 'react'
import { ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache } from '@apollo/client'

import { introspectionResult } from '@affiliate-cabinet/graphql'
import { resolveDomain } from '@affiliate-cabinet/rest'

const uri = `${resolveDomain()}/api/graphql`

const httpLink = createHttpLink({
  uri,
  credentials: 'include',
})

const gqlClient = new ApolloClient({
  uri,
  cache: new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
  }),
  link: from([httpLink]),
})

export const AppApolloProvider = memo(({ children }: PropsWithChildren) => <ApolloProvider client={gqlClient}>{children}</ApolloProvider>)
AppApolloProvider.displayName = 'AppApolloProvider'
