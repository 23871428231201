export const signUpTranslationKeys = {
  authFormTitleSignUp: 'auth-form.title-sign-up-common',
  authFormTitleSignUpAdvertiser: 'auth-form.title-sign-up-common-advertiser',

  authFormEmail: 'auth-form.email',
  authFormEmailPlaceholder: 'auth-form.email-placeholder',

  authFormPassword: 'auth-form.password',
  authFormPasswordPlaceholder: 'auth-form.password-placeholder',

  authFormCommonError: 'auth-form.common-error',

  authFormButtonRegister: 'auth-form.button-register',

  authFormHaveAnAccount: 'auth-form.have-an-account',
  authFormLoginLink: 'auth-form.login-link',

  authFormTermsAndConditionsText: 'auth-form.terms-and-conditions-text',
  authFormTermsAndConditionsLink: 'auth-form.terms-and-conditions-link',
  authFormPrivacyNotice: 'auth-form.privacy-notice',

  errorSignUpSomethingWentWrong: 'auth-form.error-signup-something-went-wrong',
  errorSignUpEmailAlreadyExist: 'auth-form.error-signup-email-already-exist',
  errorSignUpOldEmail: 'auth-form.error-signup-old-email',

  errorAuthFormPasswordIsToShort: 'auth-form.error-password-is-to-short',

  authFormWantToTryAnotherEmail: 'auth-form.want-to-try-another-email',
  authFormButtonLogin: 'auth-form.button-login',
}
