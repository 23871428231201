import xss, { IFilterXSSOptions } from 'xss'

const defaultOptions: IFilterXSSOptions = {
  whiteList: {
    h1: [],
    h2: [],
    h3: [],
    h4: [],
    h5: [],
    h6: [],
    blockquote: [],
    ol: [],
    ul: [],
    li: [],
    a: ['target', 'href', 'title'],
    p: [],
    b: [],
    strong: [],
    u: [],
    s: [],
    em: [],
    i: [],
    br: [],
    div: [],
    span: [],
    img: [],
    table: [],
    tbody: [],
    td: [],
    tfoot: [],
    th: [],
    thead: [],
    tr: [],
    iframe: ['src', 'width', 'height', 'frameborder', 'allow', 'allowfullscreen'],
  },
}

export const sanitizeHtml = (dirty: string, options: IFilterXSSOptions = defaultOptions) => xss(dirty, options)
