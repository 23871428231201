import React, { FC, memo, PropsWithChildren } from 'react'

import { useTransferGuideHandler } from '../hooks/useTransferGuideHandler'

import { TransferGuide } from '../components/TransferGuide'

import { TransferGuideContainerContext } from './context'

export const TransferGuideContainerProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const { isGuideOpened, closeGuide, finishGuide, isIntroStep, setLegalStep, isOldLoginGuide, setIsOldLoginGuide } = useTransferGuideHandler()

  const value = {
    isGuideOpened,
    closeGuide,
    finishGuide,
    isIntroStep,
    setLegalStep,
    isOldLoginGuide,
    setIsOldLoginGuide,
  }

  return (
    <TransferGuideContainerContext.Provider value={value}>
      {children}
      <TransferGuide />
    </TransferGuideContainerContext.Provider>
  )
})

TransferGuideContainerProvider.displayName = 'TransferGuideContainerProvider'
