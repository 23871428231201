/**
 * Check is active link or not
 *
 * @param linkValue Link value
 * @param currentPath Current path
 * @param depthCheck Depth to check overlap URLs from start
 */
export const isActiveLink = ({ linkValue, currentPath, depthCheck = 1 }: { linkValue: string; currentPath: string; depthCheck?: number }) => {
  const linkParameters = linkValue.split('/')
  const currentPathParameters = currentPath?.split('/')

  /* START check only full match */
  if (linkValue === currentPath) {
    return true
  }

  if (depthCheck === 0) {
    return false
  }
  /* END check only full match */

  /* START Check partially match */
  let count = 0

  for (const [index, currentPathElement] of currentPathParameters.entries()) {
    if (count !== index) {
      break
    }

    if (currentPathElement === linkParameters?.[index] && count === index) {
      count += 1
    }
  }
  /* END Check partially match */

  return count > depthCheck
}
