import { CoreTypesApiPaths, CoreTypesComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(CoreTypesApiPaths.ResetPasswordController_reset, {}, resolveDomain(), { mode: 'cors', referrerPolicy: 'no-referrer' })

export const resetPasswordHandler = async (data: CoreTypesComponents['schemas']['ResetPasswordDto']) => {
  await client.post<CoreTypesComponents['schemas']['ResetPasswordDto'], Response>(``, data, {
    'Content-Type': 'application/json',
  })
}
