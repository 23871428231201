import React from 'react'
import { DateTime } from 'luxon'

import {
  AlignBottom0213,
  BarChartSquare0113,
  BookOpen0213,
  CodeCircle0313,
  Image0113,
  ImageIndentLeft13,
  Tag0113,
  Trophy0113,
  UsersPlus13,
  UserSquare13,
  Wallet0213,
} from '@affstore-ds/icons'

import { NavMenuLinkInterface } from './NavMenu.types'

import { featuresStatuses } from '../../config/brands'
import { routes } from '../routes'
import { navMenuTranslationKeys } from '../translations/NavMenu.translationKeys'

export const profileTabSettings = {
  personal: 'personal',
  contactInfo: 'contact-info',
}

export const profileTabSettingsPersonal = routes.ROUTES_PROFILE.profileSettingsTab.replace(':tabValue', profileTabSettings.personal)

const offerRulesTabSettings = {
  ruleRestriction: 'rules-general-restrictions',
  ruleMarketingGuidelines: 'rules-marketing-guidelines',
  ruleTrafficTypes: 'rules-traffic-types',
  ruleNegativeKeywords: 'rules-negative-keywords',
  ruleAppsGuidelines: 'rules-apps-guidelines',
  rulePaymentTerms: 'rules-payment-terms',
}

export const offerTabSettings = {
  links: 'links',
  activation: 'activation',
  rates: 'rates',
  advertiser: 'advertiser',
  rules: offerRulesTabSettings,
  backlink: 'backlink',
}

export const paymentsTabSettings = {
  regularAndRequests: 'regular-and-requests',
  history: 'history',
  settings: 'settings',
}

export const paymentsTabSettingsRegularAndRequests = routes.ROUTES_PAYMENTS.paymentsTab.replace(':tabValue', paymentsTabSettings.regularAndRequests)

export const promoTabSettings = {
  landings: 'landings',
  banners: 'banners',
  videos: 'videos',
  registrationFrames: 'registration-frames',
  backlinks: 'backlinks',
}

const promoTabSettingsLandings = routes.ROUTES_PROMO.promoTab.replace(':tabValue', promoTabSettings.landings)

const displayNewBadgeUpToDateForTop10Page = DateTime.fromObject({ year: 2024, month: 4, day: 16 }).plus({ day: 45 }).toMillis()
const displayNewBadgeUpToDateForPostbacksPage = DateTime.fromObject({ year: 2024, month: 6, day: 16 }).plus({ day: 45 }).toMillis()
const displayNewBadgeUpToDateForBlogLink = DateTime.fromObject({ year: 2024, month: 6, day: 28 }).plus({ day: 45 }).toMillis()
const displayNewBadgeUpToDateForContestsPage = DateTime.fromObject({ year: 2024, month: 9, day: 24 }).plus({ day: 45 }).toMillis()

export const NAV_MENU_DATA: NavMenuLinkInterface[] = [
  // TODO: revert after dashboard in release
  // {
  //   value: routes.routes.ROUTES_DASHBOARD.dashboard,
  //   labelTranslationKey: navMenuTranslationKeys.navMenuItemDashboard,
  //   icon: <Grid0113 strokeWidth={1.6} />,
  // },
  {
    labelTranslationKey: navMenuTranslationKeys.navMenuItemStatistics,
    icon: <BarChartSquare0113 strokeWidth={1.6} />,
    children: [
      { depthCheck: 2, value: routes.ROUTES_STATISTICS.general, labelTranslationKey: navMenuTranslationKeys.navMenuItemStatisticsGeneral },
      { depthCheck: 2, value: routes.ROUTES_STATISTICS.users, labelTranslationKey: navMenuTranslationKeys.navMenuItemStatisticsUsers },
    ],
    value: routes.ROUTES_STATISTICS.root,
  },
  ...(featuresStatuses.isNewOffersPageEnabled
    ? [
        {
          value: routes.ROUTES_OFFERS.offers,
          labelTranslationKey: navMenuTranslationKeys.navMenuItemOffers,
          icon: <Tag0113 strokeWidth={1.6} />,
          children: [
            {
              depthCheck: 2,
              value: routes.ROUTES_OFFERS.offersAdvertisers,
              labelTranslationKey: navMenuTranslationKeys.navMenuItemOffersByAdvertisers,
            },
            { depthCheck: 2, value: routes.ROUTES_OFFERS.offers, labelTranslationKey: navMenuTranslationKeys.navMenuItemOffersByOffers },
          ],
        },
      ]
    : [{ value: routes.ROUTES_OFFERS.offers, labelTranslationKey: navMenuTranslationKeys.navMenuItemOffers, icon: <Tag0113 strokeWidth={1.6} /> }]),
  ...(featuresStatuses.isPromoPageEnabled
    ? [
        {
          value: promoTabSettingsLandings,
          labelTranslationKey: navMenuTranslationKeys.navMenuItemPromo,
          icon: <Image0113 strokeWidth={1.6} />,
        },
      ]
    : []),
  {
    value: paymentsTabSettingsRegularAndRequests,
    labelTranslationKey: navMenuTranslationKeys.navMenuItemPayments,
    icon: <Wallet0213 strokeWidth={1.6} />,
  },
  ...(featuresStatuses.isTop10PageEnabled
    ? [
        {
          value: routes.ROUTES_TOP.root,
          labelTranslationKey: navMenuTranslationKeys.navMenuItemTop,
          icon: <Trophy0113 strokeWidth={1.6} />,
          displayNewBadgeUpToDate: displayNewBadgeUpToDateForTop10Page,
        },
      ]
    : []),
  ...(featuresStatuses.isContestsPageEnabled
    ? [
        {
          value: routes.ROUTES_CONTESTS.tabsPath.replace(routes.ROUTES_CONTESTS_TABS._paramsKey, routes.ROUTES_CONTESTS_TABS.active),
          labelTranslationKey: navMenuTranslationKeys.navMenuItemContests,
          icon: <AlignBottom0213 strokeWidth={1.6} />,
          displayNewBadgeUpToDate: displayNewBadgeUpToDateForContestsPage,
        },
      ]
    : []),
  ...(featuresStatuses.isInfoPageEnabled
    ? [
        {
          value: routes.ROUTES_INFO.info,
          labelTranslationKey: navMenuTranslationKeys.navMenuItemInfo,
          icon: <BookOpen0213 strokeWidth={1.6} />,
          external: true,
        },
      ]
    : []),
  ...(featuresStatuses.isBlogLinkEnabled
    ? [
        {
          value: routes.ROUTES_INFO.blog,
          labelTranslationKey: navMenuTranslationKeys.navMenuItemBlog,
          icon: <ImageIndentLeft13 strokeWidth={1.6} />,
          displayNewBadgeUpToDate: displayNewBadgeUpToDateForBlogLink,
          external: true,
        },
      ]
    : []),
  ...(featuresStatuses.isSubAffiliatePageEnabled
    ? [
        {
          value: routes.ROUTES_SUB_AFFILIATE.subAffiliate,
          labelTranslationKey: navMenuTranslationKeys.navMenuItemSubAffiliate,
          icon: <UsersPlus13 strokeWidth={1.6} />,
          depthCheck: 2,
        },
      ]
    : []),
  ...(featuresStatuses.isTelegramPostbacksEnabled
    ? [
        {
          value: routes.ROUTES_POSTBACKS.postbacksList,
          labelTranslationKey: navMenuTranslationKeys.navMenuItemPostbacks,
          icon: <CodeCircle0313 strokeWidth={1.6} />,
          displayNewBadgeUpToDate: displayNewBadgeUpToDateForPostbacksPage,
          children: [
            {
              depthCheck: 2,
              value: routes.ROUTES_POSTBACKS.postbacksList,
              labelTranslationKey: navMenuTranslationKeys.navMenuItemPostbacksGeneral,
            },
            {
              depthCheck: 2,
              value: routes.ROUTES_POSTBACKS.postbacksTelegram,
              labelTranslationKey: navMenuTranslationKeys.navMenuItemPostbacksTelegram,
              displayNewBadgeUpToDate: displayNewBadgeUpToDateForPostbacksPage,
            },
          ],
        },
      ]
    : [
        {
          value: routes.ROUTES_POSTBACKS.postbacksList,
          labelTranslationKey: navMenuTranslationKeys.navMenuItemPostbacks,
          icon: <CodeCircle0313 strokeWidth={1.6} />,
        },
      ]),
  {
    value: routes.ROUTES_PROFILE.profile,
    labelTranslationKey: navMenuTranslationKeys.navMenuItemProfile,
    icon: <UserSquare13 strokeWidth={1.6} />,
    children: [
      ...(featuresStatuses.isKYCEnabled
        ? [
            {
              value: routes.ROUTES_PROFILE.profileKYC,
              labelTranslationKey: navMenuTranslationKeys.navMenuItemProfileKyc,
              depthCheck: 2,
            },
          ]
        : []),
      {
        value: routes.ROUTES_PROFILE.profileSecurity,
        labelTranslationKey: navMenuTranslationKeys.navMenuItemProfileSecurity,
        depthCheck: 2,
      },
      {
        value: profileTabSettingsPersonal,
        labelTranslationKey: navMenuTranslationKeys.navMenuItemProfileSettings,
        depthCheck: 2,
      },
    ],
  },
]
