import React from 'react'
import { Navigate, Route } from 'react-router-dom'

import { LoaderProgress } from '@affstore-ds/uikit'

import { useIsPostbacksEnabledQuery } from './base/hooks/useIsPostbacksEnabledQuery'

import { routes } from '../../shared/routes'
import { lazyLoadPageWithRetry } from '../../shared/utils/lazyLoadPageWithRetry'

const PostbacksList = lazyLoadPageWithRetry(() => import('./list'))
const PostbacksCreate = lazyLoadPageWithRetry(() => import('./create'))
const PostbacksEdit = lazyLoadPageWithRetry(() => import('./edit'))
const PostbacksInfo = lazyLoadPageWithRetry(() => import('./info'))
const PostbacksTelegram = lazyLoadPageWithRetry(() => import('./telegram'))
const PostbacksTelegramCreate = lazyLoadPageWithRetry(() => import('./telegram/create'))
const PostbacksTelegramEdit = lazyLoadPageWithRetry(() => import('./telegram/edit'))

export const renderPostbacks = () => {
  // TODO: TASK-https://mbt.tpondemand.com/entity/394724-fe-remove-react-hook-from-function
  const { data: isPostbacksEnabled, isLoading, isTelegramPostbacksEnabled } = useIsPostbacksEnabledQuery() // eslint-disable-line react-hooks/rules-of-hooks

  if (isLoading) {
    return <Route path={routes.ROUTES_POSTBACKS.postbacksAll} element={<LoaderProgress loading={isLoading} timeoutInterval={500} isFixed />} />
  }

  return (
    <>
      {isPostbacksEnabled ? (
        <Route>
          <Route path={routes.ROUTES_POSTBACKS.postbacksInfo} element={<Navigate to={routes.ROUTES_POSTBACKS.postbacksList} />} />
          <Route path={routes.ROUTES_POSTBACKS.postbacksList} element={<PostbacksList />} />
          <Route path={routes.ROUTES_POSTBACKS.postbacksCreate} element={<PostbacksCreate />} />
          <Route path={routes.ROUTES_POSTBACKS.postbacksEditId} element={<PostbacksEdit />} />
          {isTelegramPostbacksEnabled ? (
            <>
              <Route path={routes.ROUTES_POSTBACKS.postbacksTelegram} element={<PostbacksTelegram />} />
              <Route path={routes.ROUTES_POSTBACKS.postbacksTelegramEdit} element={<PostbacksTelegramEdit />} />
              <Route path={routes.ROUTES_POSTBACKS.postbacksTelegramCreate} element={<PostbacksTelegramCreate />} />
            </>
          ) : null}
        </Route>
      ) : (
        <Route>
          <Route path={routes.ROUTES_POSTBACKS.postbacksAll} element={<Navigate to={routes.ROUTES_POSTBACKS.postbacksInfo} />} />
          <Route path={routes.ROUTES_POSTBACKS.postbacksInfo} element={<PostbacksInfo />} />
        </Route>
      )}
    </>
  )
}
