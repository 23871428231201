import { PromoNewComponents } from '@affiliate-cabinet/types'

import { promoPlatformsEntityTranslationKeys } from './translations/promoPlatformsEntityTranslationKeys'

const PROMO_BANNER_FILE_FORMATS_LIST: { value: Exclude<PromoNewComponents['schemas']['FileFormat'], null> | ''; translationKey: string }[] = [
  { value: 'image', translationKey: promoPlatformsEntityTranslationKeys.promoMediaTypeImage },
  { value: 'html5', translationKey: promoPlatformsEntityTranslationKeys.promoMediaTypeHTML },
  { value: 'gif', translationKey: promoPlatformsEntityTranslationKeys.promoMediaTypeGIF },
]

export const promoBannersFileFormatsEntity = {
  PROMO_BANNER_FILE_FORMATS_LIST,
}
