import { createStyles } from '@affstore-ds/uikit'

export const useLegalNoticeModalStyles = createStyles(() => ({
  link: {
    fontSize: 14,
  },
  buttonGroup: {
    alignSelf: 'flex-end',
  },
  children: {
    gap: 18,
  },
  content: {
    padding: '24px 24px 26px 24px !important',
  },
}))
