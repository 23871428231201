import { getApiV3PromoBannersIdLandings, useQuery } from '@affiliate-cabinet/rest'

export const usePromoBannersByIdWithLandings = (bannerId: number) => {
  const { data, isFetching } = useQuery(['usePromoBannersByIdWithLandings', bannerId], () => getApiV3PromoBannersIdLandings({ id: bannerId }))

  return {
    landings: data?.data || [],
    landingsIsFetching: isFetching,
  }
}
