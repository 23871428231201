import React, { memo, PropsWithChildren, useEffect, useState } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { AnalyticsProvider } from '@affiliate-frontend-analytics/events'

import { getBrandConfig } from '../../config/brands'
import { createEventConfig } from '../../config/events'
import { initEnvironmentReact } from '../../shared/initEnvironmentReact'

const { brandConfig } = getBrandConfig()

const gaMeasurementIdFromEnviroment = import.meta.env.VITE_GA_MEASUREMENT_ID ?? initEnvironmentReact.GA_MEASUREMENT_ID

export const AppAnalyticsProvider = memo(({ children }: PropsWithChildren) => {
  const [previousLocale, setPreviousLocale] = useState<null | string>(null)
  const {
    i18n: { isTranslationsLoading, locale },
  } = useI18n()

  useEffect(() => {
    const currentLocale = locale()

    if (!currentLocale || currentLocale === previousLocale) {
      return
    }

    const gaMeasurementId = brandConfig.eventsEnabled ? gaMeasurementIdFromEnviroment : undefined

    createEventConfig({ gaMeasurementId, locale: currentLocale })
    setPreviousLocale(currentLocale)
  }, [isTranslationsLoading, locale, previousLocale])

  return <AnalyticsProvider>{children}</AnalyticsProvider>
})

AppAnalyticsProvider.displayName = 'AppAnalyticsProvider'
