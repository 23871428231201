import React, { FC } from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { Play13 } from '@affstore-ds/icons'
import { getIdentityImagePath } from '@affstore-ds/images'
import { BackgroundImage, Button, ButtonVariantEnum, Group, Image, Stack, Title } from '@affstore-ds/uikit'

import { VideoCoverPropertiesInterface } from './VideoCover.types'

import { addCdnToUrl } from '../../../../../../../../../assets'
import { transferPartnerTranslationKeys } from '../../../../translations/TransferPartner.translationKeys'

import { useVideoCoverStyles } from './VideoCover.styled'

export const VideoCover: FC<VideoCoverPropertiesInterface> = ({ onClick }) => {
  const { i18n } = useI18n()
  const {
    auth: { user },
  } = useAuth()
  const { classes } = useVideoCoverStyles()

  const isAffiliateProgram = user?.program === 'affiliate'

  return (
    <BackgroundImage
      src={addCdnToUrl(isAffiliateProgram ? '/assets/img/guideVideoCoverBackground.png' : '/assets/img/imageForB2B.jpg')}
      className={classes.background}
      onClick={onClick}
    >
      <Stack className={classes.container}>
        {isAffiliateProgram && (
          <Group position="apart">
            <Image src={getIdentityImagePath('LogoDarkBrandEmblemWord')} width={180} />
          </Group>
        )}
        {isAffiliateProgram && (
          <Stack spacing={2}>
            <Title className={classes.title} elementSize="xxl" order={6}>
              {i18n.t(transferPartnerTranslationKeys.guideModalCoverTitle)}
            </Title>
            {/*<Text className={classes.subtitle} elementVariant="default" elementSize="l">*/}
            {/*  {i18n.t(transferPartnerTranslationKeys.guideModalCoverSubtitle)}*/}
            {/*</Text>*/}
          </Stack>
        )}
      </Stack>
      {isAffiliateProgram && (
        <Button
          isIconOnly
          leftIcon={<Play13 className={classes.playIcon} />}
          className={classes.playButton}
          elementVariant={ButtonVariantEnum.SecondaryMain}
        />
      )}
    </BackgroundImage>
  )
}
