import { createStyles } from '@affstore-ds/uikit'

export const useNavMenuStyles = createStyles((theme) => ({
  container: {
    width: 256,
    height: theme.other.viewport.height,
    padding: theme.other.spacings[16],
    paddingRight: 0,
    paddingTop: theme.other.spacings[24],
    borderRight: `1px solid ${theme.other.colors.greyIron[4]}`,
    boxSizing: 'border-box',
    zIndex: 0,
  },

  navMenuManagerBlockTopShadowOnScroll: {
    position: 'relative',
    '&::after': {
      content: '""',
      background: 'linear-gradient(180deg, rgba(78, 74, 81, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%)',
      width: 256,
      height: 40,
      top: -40,
      right: -16,
      bottom: 0,
      position: 'absolute',
      transform: 'rotate(180deg)',
      pointerEvents: 'none',
    },
  },
}))
