import { resolveCookieDomain } from '@affiliate-cabinet/rest'
import { Cookies } from '@affstore-ds/helpers'

export const setReferrerToCookies = () => {
  const cookieDomain = resolveCookieDomain()

  Cookies.setCookie('referrer', document.referrer, { domain: cookieDomain })
}

export const getReferrerFromCookies = () => Cookies.getCookie('referrer') || undefined
