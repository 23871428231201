import { OffersTypesApiPaths, OffersTypesComponents, OffersTypesOperations } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(OffersTypesApiPaths.GetApiV2Offers, {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const getApiV2Offers = (parameters: OffersTypesOperations['GetApiV2Offers']['parameters']['query']) =>
  client.get<OffersTypesComponents['schemas']['OffersCollectionV2']>('', parameters)
