import React, { useCallback } from 'react'
import * as Sentry from '@sentry/react'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { LogOut0113 } from '@affstore-ds/icons'
import { Button, ButtonVariantEnum } from '@affstore-ds/uikit'

import { clearLocalStorageOnLogout } from '../../../../shared/localStorage'
import { OnboardingToOfferConversionFunnel } from '../../../../shared/utils'

// import { useSurvey } from '../../../../shared/hooks/useSurvey'
import { navMenuButtonLogoutTranslationKeys } from './translations/NavMenuButtonLogout.translationKeys'
import { useNavMenuButtonLogoutStyles } from './NavMenuButtonLogout.styled'

export const NavMenuButtonLogout = () => {
  const { i18n } = useI18n()
  const {
    auth: { signOut },
  } = useAuth()
  // TODO: remove formbricks and this hook if survey is not needed during 2024
  // const { logoutFromSurvey } = useSurvey()
  const { classes } = useNavMenuButtonLogoutStyles()

  const handleSignOut = useCallback(() => {
    Sentry.setUser(null)
    // logoutFromSurvey()
    clearLocalStorageOnLogout()
    OnboardingToOfferConversionFunnel.clearCookies()
    signOut()
  }, [signOut])

  return (
    <Button
      classNames={classes}
      onClick={handleSignOut}
      elementVariant={ButtonVariantEnum.TertiaryMain}
      rightIcon={<LogOut0113 />}
      data-testid="navbar-logout"
      fullWidth
    >
      {i18n.t(navMenuButtonLogoutTranslationKeys.navMenuButtonLogout)}
    </Button>
  )
}
