import React from 'react'

import { Box, useComponentDefaultProps } from '@affstore-ds/uikit'

import { AdvertiserLogoPropertiesInterface } from './AdvertiserLogo.types'

import { getAdvertiserLogoPath } from '../../utils'

import { useAdvertiserLogoStyles } from './AdvertiserLogo.styled'

const defaultProperties: Pick<AdvertiserLogoPropertiesInterface, 'size'> = {
  size: 's',
}

export const AdvertiserLogo = (properties: AdvertiserLogoPropertiesInterface) => {
  const { size, iconName, className, classNames, styles, unstyled, alt, ...rest } = useComponentDefaultProps(
    'AdvertiserLogo',
    defaultProperties,
    properties,
  )
  const { classes, cx } = useAdvertiserLogoStyles(undefined, { classNames, styles, unstyled, name: 'AdvertiserLogo' })

  if (!iconName) {
    return null
  }

  return (
    <Box
      data-testid="advertiser-logo"
      component="img"
      className={cx(classes.root, className)}
      alt={alt || 'Advertiser logo'}
      src={getAdvertiserLogoPath({ size, iconName })}
      {...rest}
    />
  )
}
