import React, { FC, memo, PropsWithChildren, useRef } from 'react'

import { ScrollArea } from '@affstore-ds/uikit'

import { MainScrollContainerContext } from './context'
import { useMainScrollContainerStyles } from './MainScrollContainer.styled'

export const MainScrollContainerProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const viewportReference = useRef(null) as React.MutableRefObject<HTMLDivElement | null>

  const { classes } = useMainScrollContainerStyles()

  const scrollToBottom = () => viewportReference?.current?.scrollTo({ top: viewportReference?.current.scrollHeight, behavior: 'smooth' })
  const scrollToTop = () => viewportReference?.current?.scrollTo({ top: 0, behavior: 'smooth' })

  const value = {
    viewportReference,
    scrollToBottom,
    scrollToTop,
  }

  return (
    <MainScrollContainerContext.Provider value={value}>
      <ScrollArea classNames={{ root: classes.scrollContainerRoot, scrollbar: classes.scrollbar }} viewportRef={viewportReference}>
        {children}
      </ScrollArea>
    </MainScrollContainerContext.Provider>
  )
})

MainScrollContainerProvider.displayName = 'MainScrollContainerProvider'
