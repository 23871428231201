import { createStyles } from '@affstore-ds/uikit'

export const useNavMenuLinksStyles = createStyles((theme) => ({
  container: {
    gap: theme.other.spacings[4],
    marginBottom: 4,
  },
  labelGroup: {
    // just for safety
    gap: 1,
  },
}))
