import { DateTime } from 'luxon'

import { UsersTypesComponents } from '@affiliate-cabinet/types'

import { AvatarElementVariantType, ManagerVacationStatusType } from '../NavMenuManagerInfo.types'

import { MINUTES_IN_HOUR } from '../../../../../shared/constants'
import { managersInfoTranslationKeys } from '../translations/managersInfo.translationKeys'

export const managerStatusToAvatarElementVariantMap: { [key in Exclude<ManagerVacationStatusType, 'none'>]: AvatarElementVariantType } = {
  trip: 'BusinessTrip',
  sick: 'SickLeave',
  vacation: 'Vacation',
  holidays: 'Vacation',
}

export const managerStatusToTranslationKeyVariantMap: { [key in Exclude<ManagerVacationStatusType, 'none'>]: string } = {
  trip: managersInfoTranslationKeys.managerOnBusinessTripUntilDate,
  sick: managersInfoTranslationKeys.managerOnSickUntilDate,
  vacation: managersInfoTranslationKeys.managerOnVacationUntilDate,
  holidays: managersInfoTranslationKeys.managerOnHolidaysUntilDate,
}

export const mapManagerStatusToAvatarElementVariant = (
  userManager?: UsersTypesComponents['schemas']['Manager'],
): AvatarElementVariantType | undefined => {
  if (!userManager) {
    return undefined
  }

  let avatarElementVariant: AvatarElementVariantType = userManager?.is_online ? 'Online' : 'Offline'
  const managerVacation = userManager?.leave?.leave_type === 'none' ? undefined : userManager?.leave

  if (managerVacation) {
    avatarElementVariant = managerVacation.leave_type
      ? managerStatusToAvatarElementVariantMap[managerVacation.leave_type as Exclude<ManagerVacationStatusType, 'none'>]
      : 'Vacation'
  }

  return avatarElementVariant
}

export const getVacationData = ({ vacation, locale }: { vacation?: UsersTypesComponents['schemas']['Manager']['leave']; locale: string }) => {
  if (!vacation) {
    return {}
  }

  const dateToFormatted = DateTime.fromISO(vacation.to, { zone: 'utc' }).setLocale(locale).toFormat('dd.LL')
  const vacationType = vacation.leave_type
  const managerOnVacationTypeTranslationKey = vacationType
    ? managerStatusToTranslationKeyVariantMap[vacationType as Exclude<ManagerVacationStatusType, 'none'>]
    : ''

  return { dateToFormatted, vacationType, managerOnVacationTypeTranslationKey }
}

const getAdaptedTime = ({ hours, minutes, locale, timezone }: { hours: string; minutes: string; locale: string; timezone: string }) => {
  const localTimezoneOffset = DateTime.local().offset / MINUTES_IN_HOUR
  const managerTimezoneOffset = DateTime.local().setZone(timezone).offset / MINUTES_IN_HOUR
  const diff = Math.abs(localTimezoneOffset - managerTimezoneOffset)
  const time = DateTime.local(2023, 1, 1, Number(hours), Number(minutes), { locale })

  if (managerTimezoneOffset === localTimezoneOffset) {
    return time
  }

  return managerTimezoneOffset > localTimezoneOffset ? time.minus({ hours: diff }) : time.plus({ hours: diff })
}

export const getWorkingHours = ({
  workingHours,
  locale,
}: {
  workingHours: UsersTypesComponents['schemas']['Manager']['working_hours']
  locale: string
}) => {
  const { from, timezone, to } = workingHours
  const [fromHours, fromMinutes] = from?.split(':') || ''

  const adaptedManagerFromTime = getAdaptedTime({ hours: fromHours, minutes: fromMinutes, locale, timezone })

  const [toHours, toMinutes] = to?.split(':') || ''
  const adaptedManagerToTime = getAdaptedTime({ hours: toHours, minutes: toMinutes, locale, timezone })

  return { adaptedManagerFromTime, adaptedManagerToTime }
}
