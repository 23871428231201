export const featureFlagsDictionary = {
  // Enable show restriction grade field/column in offers table
  offersRestrictionGrade: {
    name: 'restrictionGrade',
    category: 'offers',
  },
  // Enable support page in Menu
  infoPage: {
    name: 'infoPage',
    category: 'info',
  },
  // Enable links to support pages
  infoLink: {
    name: 'infoLink',
    category: 'info',
  },
  // Enable IB program on onboarding page
  onboardingProgramIB: {
    name: 'IB',
    category: 'onboarding-step1',
  },
  // Enable Affiliate program on onboarding page
  onboardingProgramAFFILIATE: {
    name: 'AFFILIATE',
    category: 'onboarding-step1',
  },
  // Show PhoneNumberInput on onboarding step 2
  onboardingPhoneNumberInput: {
    name: 'PhoneNumberInput',
    category: 'onboarding-step2',
  },
  // Enable KYC
  kyc: {
    name: 'fullPage',
    category: 'accountSettings-kyc',
  },
  // Enable "Personal" tab in account settings
  profilePersonalTab: {
    name: 'personalTab',
    category: 'accountSettings-profile',
  },
  // Enable "Contact info" personal tab in account settings
  profileContactInfoTab: {
    name: 'contactInfoTab',
    category: 'accountSettings-profile',
  },
  // Enable "Type of affiliation" section on "Personal" tab in account settings
  profilePersonalTypeOfAffiliation: {
    name: 'typeOfAffiliation',
    category: 'accountSettings-profile-personal',
  },
  // Enable "Subscription" section on "Personal" tab in account settings
  profilePersonalSubscription: {
    name: 'subscription',
    category: 'accountSettings-profile-personal',
  },
  // Enable promo page in Menu
  promoPage: {
    name: 'promoPage',
    category: 'promo',
  },
  // Enable sub-affiliate page in Menu
  subAffiliatePage: {
    name: 'subAffiliatePage',
    category: 'subAffiliate',
  },
  // Enable registration process on auth pages
  registration: {
    name: 'registration',
    category: 'auth',
  },
  // Enable signin process on auth pages
  signin: {
    name: 'signin',
    category: 'auth',
  },
  // Enable new offers page
  newOffersPages: {
    name: 'pages',
    category: 'offers',
  },
  // Enable offers filters
  offersFilters: {
    name: 'offers',
    category: 'filters',
  },
  // Enable add to favourite
  offerFavourite: {
    name: 'offers',
    category: 'favourite',
  },
  // Enable top 10 info for payment method / comission description
  top10: {
    name: 'top10',
    category: 'top',
  },
  // Enable top 10 page in Menu
  top10Page: {
    name: 'top10Page',
    category: 'top10',
  },
  // Enable new backlink tab in the offer page
  newBacklinkTabOfferPage: {
    name: 'backlinkTab',
    category: 'offer',
  },
  // Enable affstore survey
  transferedClientSurvey: {
    name: 'survey',
    category: 'app',
  },
  // Enable group by dates in user statistics
  userStatisticsGroupByDates: {
    name: 'groupByDates',
    category: 'statistics',
  },
  // Enable telegram postbacks
  telegramPostBacks: {
    name: 'telegramPostbacks',
    category: 'postbacks',
  },
  // Enable blog link in Menu
  blogLink: {
    name: 'blog',
    category: 'info',
  },
  // Enable contests page in Menu
  contestsPage: {
    name: 'contestsPage',
    category: 'contests',
  },
  // Enable contests filters - My contests
  contestsPageMyContestsFilter: {
    name: 'contestsPageMyContestsFilter',
    category: 'contests',
  },
  // Enable payment method Pix
  paymentMethodPix: {
    name: 'paymentMethodPix',
    category: 'payments',
  },
  // Disable initial request for user statistics
  userStatisticsInitialRequest: {
    name: 'initialRequest',
    category: 'statistics',
  },
  userStatisticsProfitColumn: {
    name: 'profitColumn',
    category: 'statistics',
  },
}
