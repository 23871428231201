import React, { FC, useState } from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { Box, Button, Modal, rem, Stack, Text, Title } from '@affstore-ds/uikit'

import { GuideModalPropertiesInterface } from './GuideModal.types'

import { useSendOnClick } from '../../../../../../../hooks/useSendOnClick'

import { EventName } from '../../../../../../../../config/events'
import { transferPartnerTranslationKeys } from '../../translations/TransferPartner.translationKeys'

import { VideoCover } from './components/VideoCover'
import { VideoLoader } from './components/VideoLoader'
import { useGuideModalStyles } from './GuideModal.styled'

export const GuideModal: FC<GuideModalPropertiesInterface> = ({ opened, onClose, onButtonClick, iFrameURL }) => {
  const { i18n } = useI18n()
  const { classes } = useGuideModalStyles()
  const { sendEvent, eventData: videoClickEventData } = useSendOnClick({ eventName: EventName.guideVideoCoverClick })
  const {
    auth: { user },
  } = useAuth()

  const isAffiliateProgram = user?.program === 'affiliate'

  const [isCoverShown, setCoverShown] = useState<boolean>(true)

  const showCover = () => setCoverShown(true)
  const hideCover = () => {
    sendEvent(videoClickEventData)
    setCoverShown(false)
  }

  const handleModalClose = () => {
    showCover()
    onClose()
  }

  const title = i18n.t(transferPartnerTranslationKeys.guideModalTitle)

  return (
    <Modal centered hideHeader hideOpenButton size="540px" elementVariant="default" opened={opened} onClose={handleModalClose}>
      {isCoverShown ? (
        <VideoCover onClick={hideCover} />
      ) : (
        <Box className={classes.videoWrapper}>
          <iframe
            allowFullScreen
            allow="autoplay"
            width={rem(540)}
            height={rem(304)}
            title={title}
            src={`${iFrameURL}?autoplay=1&transparent=0&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479`}
            style={{
              border: 0,
              margin: `${rem(-25)} ${rem(-24)} ${rem(-24)} ${rem(-25)}`,
              borderTopLeftRadius: rem(8),
              borderTopRightRadius: rem(8),
            }}
          />
          <VideoLoader />
        </Box>
      )}
      <Stack mt={32} spacing={8}>
        <Title elementSize="l" order={3}>
          {title}
        </Title>
        <Text elementVariant="default" elementSize="s">
          {i18n.t(
            isAffiliateProgram
              ? transferPartnerTranslationKeys.guideModalDescriptionPart1
              : transferPartnerTranslationKeys.guideModalDescriptionPart1Ib,
          )}
        </Text>
        <Text elementVariant="default" elementSize="s" pt={12}>
          {i18n.t(
            isAffiliateProgram
              ? transferPartnerTranslationKeys.guideModalDescriptionPart2
              : transferPartnerTranslationKeys.guideModalDescriptionPart2Ib,
          )}
        </Text>
      </Stack>
      <Box className={classes.buttonGroup}>
        <Button onClick={onButtonClick}>{i18n.t(transferPartnerTranslationKeys.guideModalButton)}</Button>
      </Box>
    </Modal>
  )
}
