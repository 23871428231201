import React, { memo, MouseEventHandler, SyntheticEvent } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { DateTime } from 'luxon'

import { useAuth } from '@affiliate-cabinet/auth'
import { I18nType, useI18n } from '@affiliate-cabinet/i18n'
import { Badge, Group, NavLink, NavLinkPropertiesInterface, Stack } from '@affstore-ds/uikit'

import { NavMenuLinkInterface } from '../../../../shared/navigation/NavMenu.types'

import { useUnavailableNavMenuItems } from '../../hooks/useUnavailableNavMenuItems'

import { NAV_MENU_DATA } from '../../../../shared/navigation/NavMenuData'
import { useNavigationContainer } from '../../../../shared/providers'
import { routes } from '../../../../shared/routes'
import { commonTranslationKeys } from '../../../../shared/translations/common.translationKeys'
import { getOfferLinkPathParameters, isActiveLink, OFFER_FROM_URL_PARAM } from '../../../../shared/utils'

import { useNavMenuLinksStyles } from './NavMenuLinks.styled'

const isOfferIdPathname = (pathname?: string) =>
  pathname?.startsWith(routes.ROUTES_OFFERS.offers) && !pathname?.startsWith(routes.ROUTES_OFFERS.offersAdvertisers)

const mapNavLinkValueToComponent = ({
  link,
  i18n,
  currentPathName,
  externalClassName,
  isOnboardingSuccessful,
  sharedLinkClickHandler,
  labelClasses,
}: {
  link: NavMenuLinkInterface
  i18n: I18nType
  currentPathName: string
  externalClassName: string
  isOnboardingSuccessful?: boolean
  sharedLinkClickHandler?: (nextPath: string) => MouseEventHandler
  labelClasses: string
}) => {
  const hasChildren = !!link.children
  const isExternal = link.external
  const isActive = isActiveLink({ linkValue: link.value || '', currentPath: currentPathName, depthCheck: link.depthCheck })
  const isShowNewBadge = Boolean((link.displayNewBadgeUpToDate || 0) > DateTime.now().toMillis())

  const component = () => {
    let componentProperty: NavLinkPropertiesInterface['component'] = RouterLink

    if (hasChildren) {
      componentProperty = 'button'
    }

    if (isExternal) {
      componentProperty = 'a'
    }

    return componentProperty
  }

  const handleLinkClick = (componentProperty: string) => {
    if (componentProperty === 'button' || isExternal) {
      return
    }

    return sharedLinkClickHandler?.(link?.value ?? '')
  }

  return (
    <NavLink
      active={!hasChildren && isActive}
      component={component()}
      to={isExternal ? undefined : link?.value}
      href={isExternal ? link?.value : undefined}
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      label={
        <Group className={labelClasses}>
          <span>{i18n.t(link.labelTranslationKey)}</span>
          {isShowNewBadge && (
            <Badge ml="auto" elementSize="s" elementVariant="brand">
              {i18n.t(commonTranslationKeys.commonNew)}
            </Badge>
          )}
        </Group>
      }
      leftSection={link.icon}
      key={link.labelTranslationKey}
      disabled={!isOnboardingSuccessful}
      onClick={handleLinkClick(component())}
      defaultOpened={isActive}
      data-testid={`navbar-${link.labelTranslationKey}`}
    >
      {link.children ? (
        <Stack className={externalClassName}>
          {link.children.map((linkChild) =>
            mapNavLinkValueToComponent({
              link: linkChild,
              i18n,
              currentPathName,
              externalClassName,
              isOnboardingSuccessful,
              sharedLinkClickHandler,
              labelClasses,
            }),
          )}
        </Stack>
      ) : null}
    </NavLink>
  )
}

export const NavMenuLinks = memo(() => {
  const { i18n } = useI18n()
  const {
    auth: { user, userRefetch, isUserLoading },
  } = useAuth()
  const location = useLocation()

  const { data: unavailableNavMenuItems } = useUnavailableNavMenuItems()

  const { classes } = useNavMenuLinksStyles()

  const { redirect } = useNavigationContainer()

  const sharedLinkClickHandler =
    (nextPath: string | undefined = ''): MouseEventHandler =>
    (event: SyntheticEvent) => {
      event.preventDefault()
      redirect(nextPath)

      if (!isUserLoading) {
        userRefetch()
      }
    }

  const currentPathname =
    location.search.includes(OFFER_FROM_URL_PARAM) || isOfferIdPathname(location.pathname)
      ? getOfferLinkPathParameters(location.search)
      : location.pathname

  return (
    <Stack className={classes.container}>
      {NAV_MENU_DATA.filter((item) => !(item.value && unavailableNavMenuItems.includes(item.value))).map((link) =>
        mapNavLinkValueToComponent({
          link,
          i18n,
          currentPathName: currentPathname,
          externalClassName: classes.container,
          isOnboardingSuccessful: user?.is_onboarding_passed,
          sharedLinkClickHandler,
          labelClasses: classes.labelGroup,
        }),
      )}
    </Stack>
  )
})

NavMenuLinks.displayName = 'NavMenuLinks'
