import React, { FC } from 'react'
import { createRoot } from 'react-dom/client'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'
import {
  captureConsoleIntegration,
  dedupeIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
  reportingObserverIntegration,
} from '@sentry/integrations'
import * as Sentry from '@sentry/react'

import { initEnvironmentReact } from './shared/initEnvironmentReact'
import App from './app'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN ?? initEnvironmentReact.SENTRY_DSN,
  integrations: [
    dedupeIntegration(),
    captureConsoleIntegration({
      levels: ['warn', 'error'],
    }),
    httpClientIntegration(),
    extraErrorDataIntegration({
      depth: 15,
    }),
    reportingObserverIntegration({
      types: ['crash', 'intervention'],
    }),
    Sentry.moduleMetadataIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin, 'static.cdnaffs.com', 'api.affstore.com'],
      networkRequestHeaders: ['Cache-Control', 'Content-Type'],
      networkResponseHeaders: ['Referrer-Policy'],
    }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  /**
   * https://docs.sentry.io/platforms/javascript/configuration/filtering/
   */
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Other
    'Non-Error exception captured',
    'Failed to fetch',
    'Load failed',
    'TypeError: Cancelled',
    "Cannot get CSS styles from text's parentNode",
    'jsb2',
  ],
  /**
   * https://docs.sentry.io/platforms/javascript/configuration/filtering/
   */
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_us\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  attachStacktrace: true,
  normalizeDepth: 15,
  environment: window.location.hostname,
  release: import.meta.env.VITE_SENTRY_RELEASE ?? initEnvironmentReact.SENTRY_RELEASE,
  tracePropagationTargets: [window.location.origin, 'static.cdnaffs.com', 'api.affstore.com'],

  /**
   * https://docs.sentry.io/platforms/javascript/configuration/options/#sample-rate
   */
  sampleRate: 0.2,
  /**
   * https://docs.sentry.io/platforms/javascript/guides/electron/configuration/options/#traces-sample-rate
   * We recommend adjusting this value in production, or using tracesSampler
   * for finer control
   */
  tracesSampleRate: 0.2,
  /**
   * https://docs.sentry.io/platforms/javascript/guides/ember/session-replay/#sampling
   * This sets the sample rate to be 10%. You may want this to be 100% while
   * in development and sample at a lower rate in production
   */
  replaysSessionSampleRate: 0.2,
  /**
   * https://docs.sentry.io/platforms/javascript/guides/ember/session-replay/#sampling
   * If the entire session is not sampled, use the below sample rate to sample
   * sessions when an error occurs.
   */
  replaysOnErrorSampleRate: 0.2,
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 0.2,
})

const Renderer: FC = () => (
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

const container = document.querySelector('#root')
const root = createRoot(container as Element)

root.render(<Renderer />)
