import { useMemo } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { getApiAdvertisers, useQuery } from '@affiliate-cabinet/rest'
import { OffersTypesComponents, ResponseErrorInterface } from '@affiliate-cabinet/types'

import { selectTranslationKeys } from '../../../../shared/translations/select.translationKeys'

import { advertisersTranslationKeys } from './translations/advertisersTranslationKeys'
import { unknownAdvertiser } from './constants'

export const useAdvertisers = ({ withUnknownAdvertiser = false, withNotSelectedAdvertiser = false }) => {
  const { i18n } = useI18n()
  const { data, error, isFetching, refetch } = useQuery<unknown, ResponseErrorInterface, OffersTypesComponents['schemas']['AdvertisersCollection']>(
    ['useAdvertisers'],
    () => getApiAdvertisers(),
    {
      staleTime: Number.POSITIVE_INFINITY,
    },
  )

  const preparedData = useMemo(() => {
    const firstElement = {
      id: 0,
      name: '',
      alias: '',
      value: '',
      description: '',
      shortDescription: '',
      links: [],
      platforms: [],
      logo: '',
      regions: [],
      restrictionGrade: 0,
      theme: [],
      website: '',
      translationKey: selectTranslationKeys.selectNotSelected,
      'data-testid': `advertiser-item-not-selected`,
    }

    const advertisers = (data?.data || []).map((advertiser) => ({
      ...advertiser,
      value: advertiser.id.toString(),
      translationKey: '',
      'data-testid': `advertiser-item-${advertiser.id}`,
    }))

    if (withUnknownAdvertiser) {
      advertisers.push(unknownAdvertiser)
    }

    let preparedAdvertisers = advertisers.map(({ translationKey, ...advertiser }) => ({
      ...advertiser,
      label: i18n.t(translationKey) || advertiser.name,
    }))

    if (withNotSelectedAdvertiser) {
      preparedAdvertisers = [
        {
          ...firstElement,
          alias: firstElement.alias,
          value: firstElement.value,
          label: i18n.t(firstElement.translationKey),
          'data-testid': firstElement['data-testid'],
        },
        ...preparedAdvertisers,
      ]
    }

    return preparedAdvertisers
  }, [data?.data, withUnknownAdvertiser, withNotSelectedAdvertiser, i18n])

  const advertisersLabels = useMemo(() => {
    const advertisers = data?.data
      ? [
          ...data.data,
          { id: unknownAdvertiser.value, name: i18n.t(advertisersTranslationKeys.advertiserXXX.replace('xxx', unknownAdvertiser.alias)) },
        ]
      : []

    return Object.fromEntries(advertisers.map((v) => [Number(v.id), v.name]))
  }, [data?.data, i18n])

  return {
    error,
    loading: isFetching,
    data: preparedData,
    advertisersLabels,
    refetch,
  }
}
