import { createStyles } from '@affstore-ds/uikit'

import { MoneyPropertiesInterface } from './Money.types'

export const useMoneyStyles = createStyles((_, p: MoneyPropertiesInterface & { isNegative: boolean; spacing: number }) => ({
  wrapper: {
    whiteSpace: 'nowrap',
    '&::before': {
      display: 'inline',
      content: `"${p.isNegative ? '-' : p.positiveSign}${p.currency}"`,
    },
  },
  thousand: {
    '&:not(:last-child)': {
      paddingRight: `${p.spacing}em`,
    },
  },
}))
