import React, { FC } from 'react'

import { formatMoney } from '@affstore-ds/helpers'

import { MoneyPropertiesInterface } from './Money.types'

import { useMoneyStyles } from './Money.styled'

/**
 * @deprecated Please use from @affstore-ds/uikit */
export const Money: FC<MoneyPropertiesInterface> = ({ value = 0, zeroFraction, spacing = 0.25, currency = '$', positiveSign = '' }) => {
  const [whole, frac] = formatMoney(value, {
    currency: '',
    zeroFraction,
    withNegative: false,
  }).split('.')

  const isNan = Number.isNaN(Number(value))

  const isNegative = value < 0

  const { classes } = useMoneyStyles({
    value,
    zeroFraction,
    spacing,
    currency,
    positiveSign,
    isNegative,
  })

  if (isNan) {
    return <>{value}</>
  }

  return (
    <span className={classes.wrapper}>
      {whole.split(' ').map((digits, index) => (
        <span className={classes.thousand} key={index}>
          {digits}
        </span>
      ))}
      {frac ? `.${frac}` : ''}
    </span>
  )
}
