import { BillingTypesPaths } from '@affiliate-cabinet/types'

import { BillingPathBaseEnum, BillingPathsEnum } from '../types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(BillingPathBaseEnum['/api/rest/affiliate'], {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const patchV3AffstoreTransferStatus = (
  body: BillingTypesPaths['/v3/affstore-transfer']['patch']['requestBody']['content']['application/json'],
) =>
  client.patch<
    BillingTypesPaths['/v3/affstore-transfer']['patch']['requestBody']['content']['application/json'],
    BillingTypesPaths['/v3/affstore-transfer']['patch']['responses']['204']
  >(BillingPathsEnum['/v3/affstore-transfer'], body, {
    'Content-Type': 'application/json',
  })
