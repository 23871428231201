import React, { FC, memo, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  AuthorizationStatusType,
  checkIs2FaEnabledHandler,
  checkIsAuthorizedHandler,
  confirmTwoFactorAuthHandler,
  disable2FaAuthHandler,
  emailConfirmHandler,
  enable2FaAuthHandler,
  getAffiliateEntity,
  init2FaAuthHandler,
  removeCookies,
  resetPasswordHandler,
  resetPasswordRequestHandler,
  RestClient,
  signInHandler,
  SignInHandlerParametersInterface,
  signOutHandler,
  signUpHandler,
  useMutation,
  useQuery,
} from '@affiliate-cabinet/rest'
import {
  CoreTypesComponents,
  ResponseErrorInterface,
  TrackerTypesApiPaths,
  TwoFactorAuthTypesComponents,
  UsersTypesComponents,
} from '@affiliate-cabinet/types'

import { AuthErrorInterface } from './types'

import { errorCallback } from '../sentry/errorCallback'

import { AuthContext } from './context'

export const AuthProvider: FC<PropsWithChildren & { ROUTES_SHARED_VALUES: string[]; ROUTES_ERROR: Record<string, string>; signInRoute: string }> =
  memo(({ children, ROUTES_SHARED_VALUES, signInRoute, ROUTES_ERROR }) => {
    const onLogoutCallbacks = useRef<Record<string, () => void>>({})

    const [error, setError] = useState<AuthErrorInterface | null>(null)

    const {
      data: isAuthorizedData,
      isFetching: isAuthorizedLoading,
      error: isAuthorizedError,
      refetch: refetchIsAuthorizedData,
      isRefetching: isAuthorizedRefetching,
    } = useQuery<unknown, ResponseErrorInterface, AuthorizationStatusType>(['isAuthorized'], checkIsAuthorizedHandler, {
      keepPreviousData: true,
    })

    const {
      data: is2FaEnabledData,
      isFetching: is2FaEnabledLoading,
      error: is2FaEnabledError,
      refetch: refetchIs2FaEnabledData,
    } = useQuery<unknown, ResponseErrorInterface, boolean>(['is2FaEnabled'], checkIs2FaEnabledHandler, {
      retry: false,
      keepPreviousData: true,
      enabled: isAuthorizedData === true || isAuthorizedData === 'isPending2FA',
    })

    const {
      data,
      error: userError,
      isFetching: isUserLoading,
      refetch,
    } = useQuery<unknown, ResponseErrorInterface, UsersTypesComponents['schemas']['UserProfile']>(
      ['getAffiliateEntity'],
      () => getAffiliateEntity(),
      {
        retry: false,
        keepPreviousData: true,
        enabled: isAuthorizedData === true,
      },
    )

    const signInHandlerMutation = useMutation<unknown, ResponseErrorInterface, SignInHandlerParametersInterface>(signInHandler)
    const signUpHandlerMutation = useMutation<unknown, ResponseErrorInterface, UsersTypesComponents['schemas']['Signup']>(signUpHandler)
    const confirmTwoFactorAuthMutation = useMutation<unknown, ResponseErrorInterface, TwoFactorAuthTypesComponents['schemas']['2FaConfirm']>(
      confirmTwoFactorAuthHandler,
    )
    const init2FaAuthHandlerMutation = useMutation<
      TwoFactorAuthTypesComponents['schemas']['_api_auth_2fa_init_post_200_response'],
      ResponseErrorInterface,
      TwoFactorAuthTypesComponents['schemas']['2FaInitInput']
    >(init2FaAuthHandler)
    const enable2FaAuthHandlerMutation = useMutation<unknown, ResponseErrorInterface, TwoFactorAuthTypesComponents['schemas']['2FaEnableInput']>(
      enable2FaAuthHandler,
    )
    const disable2FaAuthHandlerMutation = useMutation<unknown, ResponseErrorInterface, TwoFactorAuthTypesComponents['schemas']['2FaDisableInput']>(
      disable2FaAuthHandler,
    )
    const resetPasswordRequestHandlerMutation = useMutation<
      unknown,
      ResponseErrorInterface,
      CoreTypesComponents['schemas']['RequestResetPasswordDto']
    >(resetPasswordRequestHandler)
    const resetPasswordHandlerMutation = useMutation<unknown, ResponseErrorInterface, CoreTypesComponents['schemas']['ResetPasswordDto']>(
      resetPasswordHandler,
    )
    const emailConfirmHandlerMutation = useMutation<unknown, ResponseErrorInterface, CoreTypesComponents['schemas']['EmailConfirmDto']>(
      emailConfirmHandler,
    )

    const isLocalLoading =
      signInHandlerMutation.isLoading ||
      signUpHandlerMutation.isLoading ||
      confirmTwoFactorAuthMutation.isLoading ||
      init2FaAuthHandlerMutation.isLoading ||
      enable2FaAuthHandlerMutation.isLoading ||
      resetPasswordRequestHandlerMutation.isLoading ||
      resetPasswordHandlerMutation.isLoading ||
      emailConfirmHandlerMutation.isLoading ||
      confirmTwoFactorAuthMutation.isLoading ||
      disable2FaAuthHandlerMutation.isLoading

    const user = useMemo(
      () => (userError || (isAuthorizedData === false && !isAuthorizedLoading && !isAuthorizedRefetching) ? undefined : data),
      [data, isAuthorizedData, isAuthorizedLoading, isAuthorizedRefetching, userError],
    )

    const refetchIsAuthorized = useCallback(
      async (callback?: VoidFunction) =>
        refetchIsAuthorizedData()
          .catch(console.error)
          .finally(() => {
            callback?.()
          }),
      [refetchIsAuthorizedData],
    )

    const userRefetch = useCallback(
      async (callback?: VoidFunction, shouldRefetchIsAuthorized = false) => {
        if (shouldRefetchIsAuthorized) {
          void refetchIsAuthorized()
        }

        return refetch()
          .catch(console.error)
          .finally(() => {
            callback?.()
          })
      },
      [refetch, refetchIsAuthorized],
    )

    const refetchIs2FaEnabled = useCallback(
      async (callback?: (isEnabled?: boolean) => void) => {
        let isEnabled: boolean | undefined = false

        return refetchIs2FaEnabledData()
          .then((result) => {
            isEnabled = result.data
          })
          .catch(console.error)
          .finally(() => {
            if (isEnabled) {
              callback?.(isEnabled)
            } else {
              userRefetch(callback, true)
            }
          })
      },
      [refetchIs2FaEnabledData, userRefetch],
    )

    const handleError = useCallback(
      (keyPage: AuthErrorInterface['keyPage']) => (error: ResponseErrorInterface) => {
        setError({
          keyPage,
          error: 'error' in error ? error.error : error,
        } as AuthErrorInterface)
      },
      [],
    )

    const signIn = useCallback(
      async (user: SignInHandlerParametersInterface, callback?: (isEnabled?: boolean) => void) => {
        setError(null)

        signInHandlerMutation.mutate(user, {
          onSuccess: () => {
            refetchIs2FaEnabled(callback)
          },
          onError: handleError('signin'),
        })
      },
      [signInHandlerMutation, handleError, refetchIs2FaEnabled],
    )

    const signUp = useCallback(
      async (user: UsersTypesComponents['schemas']['Signup'], callback?: VoidFunction) => {
        setError(null)

        signUpHandlerMutation.mutate(user, {
          onSuccess: () => userRefetch(callback, true),
          onError: handleError('signup'),
        })
      },
      [handleError, userRefetch, signUpHandlerMutation],
    )

    const confirmTwoFactorAuth = useCallback(
      async (data: TwoFactorAuthTypesComponents['schemas']['2FaConfirm'], callback?: VoidFunction) => {
        setError(null)

        confirmTwoFactorAuthMutation.mutate(data, {
          onSuccess: () => callback?.(),
          onError: handleError('confirmTwoFactorAuth'),
        })
      },
      [handleError, confirmTwoFactorAuthMutation],
    )

    const init2FaAuth = useCallback(
      async (
        data: TwoFactorAuthTypesComponents['schemas']['2FaInitInput'],
        callback?: (data: TwoFactorAuthTypesComponents['schemas']['_api_auth_2fa_init_post_200_response']) => void,
      ) => {
        setError(null)

        init2FaAuthHandlerMutation.mutate(data, {
          onSuccess: (data) => callback?.(data),
          onError: handleError('init2FaAuth'),
        })
      },
      [handleError, init2FaAuthHandlerMutation],
    )

    const enable2FaAuth = useCallback(
      async (data: TwoFactorAuthTypesComponents['schemas']['2FaEnableInput'], callback?: VoidFunction) => {
        setError(null)

        enable2FaAuthHandlerMutation.mutate(data, {
          onSuccess: () => {
            callback?.()
          },
          onError: handleError('enable2FaAuth'),
        })
      },
      [enable2FaAuthHandlerMutation, handleError],
    )

    const disable2FaAuth = useCallback(
      async (data: TwoFactorAuthTypesComponents['schemas']['2FaDisableInput'], callback?: VoidFunction) => {
        setError(null)

        disable2FaAuthHandlerMutation.mutate(data, {
          onSuccess: () => {
            callback?.()
          },
          onError: handleError('disable2FaAuth'),
        })
      },
      [disable2FaAuthHandlerMutation, handleError],
    )

    const signOut = useCallback(() => {
      for (const key of Object.keys(onLogoutCallbacks.current)) {
        onLogoutCallbacks.current[key]()
      }

      signOutHandler()
    }, [])

    const resetPasswordRequest = useCallback(
      (data: CoreTypesComponents['schemas']['RequestResetPasswordDto'], callback?: VoidFunction) => {
        resetPasswordRequestHandlerMutation.mutate(data, {
          onSuccess: () => callback?.(),
          onError: handleError('resetPasswordRequest'),
        })
      },
      [handleError, resetPasswordRequestHandlerMutation],
    )

    const resetPassword = useCallback(
      (data: CoreTypesComponents['schemas']['ResetPasswordDto'], callback?: VoidFunction) => {
        resetPasswordHandlerMutation.mutate(data, {
          onSuccess: () => callback?.(),
          onError: handleError('resetPassword'),
        })
      },
      [handleError, resetPasswordHandlerMutation],
    )

    const confirmEmail = useCallback(
      (data: CoreTypesComponents['schemas']['EmailConfirmDto'], callback?: VoidFunction) => {
        emailConfirmHandlerMutation.mutate(data, {
          onSuccess: () => userRefetch(callback, true),
          onError: handleError('confirmEmail'),
        })
      },
      [emailConfirmHandlerMutation, handleError, userRefetch],
    )

    const pushOnLogoutCallback = useCallback((callbackKey: string, callbackFunction: () => void) => {
      onLogoutCallbacks.current = {
        ...onLogoutCallbacks.current,
        [callbackKey]: callbackFunction,
      }
    }, [])

    const removeOnLogoutCallback = useCallback((callbackKey: string) => {
      Reflect.deleteProperty(onLogoutCallbacks.current, callbackKey)
    }, [])

    const clearError = useCallback(() => setError(null), [])

    const value = {
      user,
      userError,
      userRefetch,
      isUserLoading,
      signIn,
      signUp,
      confirmTwoFactorAuth,
      isConfirmTwoFactorAuthLoading: confirmTwoFactorAuthMutation.isLoading,
      init2FaAuth,
      enable2FaAuth,
      disable2FaAuth,
      resetPasswordRequest,
      resetPassword,
      signOut,
      confirmEmail,
      isLocalLoading,
      error,
      clearError,
      pushOnLogoutCallback,
      removeOnLogoutCallback,

      isAuthorizedData,
      isAuthorizedLoading: isAuthorizedLoading,
      isAuthorizedError,
      refetchIsAuthorized,

      is2FaEnabledData,
      is2FaEnabledLoading,
      is2FaEnabledError,
      refetchIs2FaEnabled,
    }

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
      RestClient.setResponseCallback((response) => {
        if (response.status === 401) {
          removeCookies()

          if (isAuthorizedData !== false && !(isAuthorizedLoading || isAuthorizedRefetching)) {
            void refetchIsAuthorized()
          }

          const isSharedRouteLocation = ROUTES_SHARED_VALUES.some((item) => window.location.href.includes(item))
          const isErrorRouteLocation = Object.keys(ROUTES_ERROR).some((item) => window.location.href.includes(item))
          const isTracker = response.url.includes(TrackerTypesApiPaths.GetApiTrackerAffiliate)

          if (!window.location.href.includes('auth') && !isSharedRouteLocation && !isErrorRouteLocation && !isTracker) {
            navigate(`${signInRoute}${location.search}`, { state: { from: location } })
          }
        }
      })

      RestClient.setResponseErrorCallback(errorCallback)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, isAuthorizedData, isAuthorizedLoading, isAuthorizedRefetching])

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  })

AuthProvider.displayName = 'AuthProvider'
