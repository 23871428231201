import { createStyles } from '@affstore-ds/uikit'

export const useErrorPageStyles = createStyles((_theme, { codeError }: { codeError: number }) => ({
  pageContainer: {
    width: '100%',
    height: codeError === 404 ? 'calc(100vh - 140px)' : '100vh', // 404 page is in main layout
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
