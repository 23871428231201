import React from 'react'

import { Box, Loader } from '@affstore-ds/uikit'

import { useVideoLoaderStyles } from './VideoLoader.styled'

export const VideoLoader = () => {
  const { classes } = useVideoLoaderStyles()

  return (
    <Box className={classes.container}>
      <Loader />
    </Box>
  )
}
