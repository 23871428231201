import { createStyles } from '@affstore-ds/uikit'

export const useNavLinkLogoStyles = createStyles((theme) => ({
  root: {
    backgroundColor: 'transparent !important',
    minHeight: 0,
    height: 24,
    padding: 0,
    paddingLeft: theme.other.spacings[8],
    outlineOffset: '4px !important',
  },
}))
