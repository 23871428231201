// Only one import allowed to handle eventsEnabled in brand config
// eslint-disable-next-line no-restricted-imports
import { useAnalyticsContext } from '@affiliate-frontend-analytics/events'

import { getBrandConfig } from '../../config/brands'

const { brandConfig } = getBrandConfig()

export const useAnalyticsEvents = () => {
  const { sendEvent: sendAnalyticsEvent, sendGaEvent: sendGoogleAnalyticsEvent } = useAnalyticsContext()

  const sendEvent: typeof sendAnalyticsEvent = async (eventData) => {
    if (!brandConfig.eventsEnabled) {
      return
    }

    sendAnalyticsEvent(eventData)
  }

  const sendGaEvent: typeof sendGoogleAnalyticsEvent = async (eventData) => {
    if (!brandConfig.eventsEnabled) {
      return
    }

    sendGoogleAnalyticsEvent(eventData)
  }

  return { sendEvent, sendGaEvent }
}
