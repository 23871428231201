import { PromoNewApiPaths, PromoNewComponents, PromoNewOperations } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

export const getApiV3PromoBannersId = async (data: PromoNewOperations['GetApiV3PromoBannersId']['parameters']['path']) => {
  const client = new RestClient(PromoNewApiPaths.GetApiV3PromoBannersId.replace(':id', data.id.toString()), {}, resolveDomain(), {
    mode: 'cors',
    referrerPolicy: 'no-referrer',
  })

  return client.get<PromoNewComponents['schemas']['Banner']>('')
}
