import { useCallback } from 'react'

import { PromoBannersIdFiltersType } from '../../containers/PromoBannersIdFilters/PromoLandingsFilters.types'

import { useBannerFiltersLocalStorageKeyWithUserId } from '../useBannerFiltersLocalStorageKeyWithUserId'

import { useFilters } from '../../../../../../../shared/hooks'
import { PROMO_BANNERS_ID_DEFAULT_FILTERS } from '../../constants/filters'
import { preparePromoBannerIdFiltersInput } from '../../utils/preparePromoBannerIdFiltersInput'

export const useBannersIdFilters = (id: string) => {
  const { filters, onFiltersChange } = useFilters<PromoBannersIdFiltersType>({
    filtersLocalStorageKey: useBannerFiltersLocalStorageKeyWithUserId(id),
    defaultFilterList: PROMO_BANNERS_ID_DEFAULT_FILTERS,
    prepareFiltersInput: preparePromoBannerIdFiltersInput,
    addedToSaveFiltersKeys: ['locales', 'imageSizes', 'fileFormats'],
  })

  const handleFiltersChange = useCallback(
    (values: PromoBannersIdFiltersType) => {
      onFiltersChange({
        ...filters,
        ...values,
      })
    },
    [filters, onFiltersChange],
  )

  return {
    filters,
    handleFiltersChange,
  }
}
