import { useEffect } from 'react'

import { DATA_TRANSLATION_ATTRIBUTE } from '../constants'

export const useLinkClickHandler = () => {
  useEffect(() => {
    const handleClick: EventListener = (event) => {
      const linkTarget = event.target as HTMLLinkElement

      if (linkTarget.href && linkTarget.closest(`[data-translation=${DATA_TRANSLATION_ATTRIBUTE}]`)) {
        event.preventDefault()
        event.stopPropagation()

        window.open(linkTarget.href, '_blank', 'noopener,noreferrer')?.focus()
      }
    }

    window?.addEventListener('click', handleClick)

    return () => {
      window?.removeEventListener('click', handleClick)
    }
  }, [])
}
