import { createStyles, rem } from '@affstore-ds/uikit'

import { GUEST_MAIN_STATIC_SELECTOR } from './constants'

export const useGuestStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: rem(20),
    paddingBottom: rem(20),
    minHeight: theme.other.viewport.height,
    boxSizing: 'border-box',
  },
  main: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    'p, span, li': {
      '& > a': {
        fontSize: 'inherit !important',
      },
    },
  },
  mainWrapper: {
    display: 'flex',
    width: '100%',
    [`& .${GUEST_MAIN_STATIC_SELECTOR}`]: {
      flex: 'unset',
      width: '100%',
    },
  },
}))
