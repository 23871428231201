import React, { memo, PropsWithChildren } from 'react'

import { NotificationsProvider, ThemeProvider } from '@affstore-ds/uikit'

import { getBrandConfig } from '../../config/brands'
import { getTagifyGlobalStyles } from '../../shared/styles/tagifyGlobalStyles'

const { brandConfig } = getBrandConfig()
const colorThemes = brandConfig.theme?.defaultColorScheme ? { [brandConfig.theme.defaultColorScheme]: brandConfig.theme?.colorTheme } : {}

export const AppThemeProvider = memo(({ children }: PropsWithChildren) => (
  <ThemeProvider defaultColorScheme={brandConfig.theme?.defaultColorScheme} colorThemes={colorThemes} getGlobalBodyStyles={getTagifyGlobalStyles}>
    <NotificationsProvider />
    {children}
  </ThemeProvider>
))
AppThemeProvider.displayName = 'AppThemeProvider'
