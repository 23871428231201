import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Button, ButtonVariantEnum, Group } from '@affstore-ds/uikit'

import { ErrorPage } from '../../shared/components/ErrorPage'
import { errorPageTranslationKeys } from '../../shared/translations/errorPage.translationKeys'

export const NotFound = () => {
  const { i18n } = useI18n()
  const navigate = useNavigate()

  return (
    <ErrorPage
      codeError={404}
      title={i18n.t(errorPageTranslationKeys.errorPage404Title)}
      description={<>{i18n.t(errorPageTranslationKeys.errorPage404Description)}</>}
    >
      <Group mt={24}>
        <Button
          elementVariant={ButtonVariantEnum.SecondaryMain}
          onClick={() => {
            /* Navigate to previous page */
            navigate(-1)
          }}
        >
          {i18n.t(errorPageTranslationKeys.errorPageButtonPreviousPage)}
        </Button>
        <Button onClick={() => navigate('/')}>{i18n.t(errorPageTranslationKeys.errorPageButtonGoToHomePage)}</Button>
      </Group>
    </ErrorPage>
  )
}
