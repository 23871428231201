import { BillingTypesPaths } from '@affiliate-cabinet/types'

import { BillingPathBaseEnum, BillingPathsEnum } from '../types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(BillingPathBaseEnum['/api/rest/affiliate'], {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const getV3Balance = () =>
  client.get<BillingTypesPaths['/v3/balance']['get']['responses']['200']['content']['application/json']>(BillingPathsEnum['/v3/balance'])
