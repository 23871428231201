import { createStyles, rem } from '@affstore-ds/uikit'

export const useVideoLoaderStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    top: rem(-24),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 490,
    height: 304,
    zIndex: -100,
  },
}))
