import { useAuth } from '@affiliate-cabinet/auth'
import { getV3AffstoreTransferStatus, patchV3AffstoreTransferStatus, useMutation, useQuery } from '@affiliate-cabinet/rest'
import { BillingTypesComponents, BillingTypesPaths, ResponseErrorInterface } from '@affiliate-cabinet/types'

import { getBrandConfig } from '../../../config/brands'
import { affstoreTransferStatusEntity } from '../../constants/affstoreTransferStatus.entity'

const { brandConfig } = getBrandConfig()

export const useAffstoreTransferStatus = () => {
  const {
    auth: { isAuthorizedData },
  } = useAuth()

  const {
    data: affstoreTransferData,
    isFetching: affstoreTransferIsFetching,
    refetch: affstoreTransferRefetch,
    isRefetching: affstoreTransferIsRefetching,
  } = useQuery<unknown, ResponseErrorInterface, BillingTypesComponents['schemas']['AffstoreTransferStatus']>(
    ['getV3AffstoreTransferStatus'],
    () => getV3AffstoreTransferStatus(),
    {
      staleTime: 3000,
      enabled: isAuthorizedData === true && brandConfig.brandName === 'affstore',
      refetchInterval: (data) => {
        if (data?.transferStatus !== 'in_progress') {
          return false
        }

        return 3000
      },
      refetchIntervalInBackground: true,
      retry: false,
      keepPreviousData: true,
      onError: (error) => {
        console.error(error)
      },
    },
  )

  const setAffstoreTransferStatusMutation = useMutation<
    BillingTypesPaths['/v3/affstore-transfer']['patch']['responses']['204'],
    ResponseErrorInterface,
    BillingTypesPaths['/v3/affstore-transfer']['patch']['requestBody']['content']['application/json']
  >(patchV3AffstoreTransferStatus, {
    onError: (error) => {
      console.error(error)
    },
    retry: false,
  })

  return {
    affstoreTransferData: {
      transferStatus: affstoreTransferData?.transferStatus || affstoreTransferStatusEntity.AFFSTORE_TRANSFER_STATUS.notStarted,
      legalNoticeConfirmed: affstoreTransferData?.legalNoticeConfirmed || null,
      finishedAt: affstoreTransferData?.finishedAt || null,
    },
    affstoreTransferIsFetching,
    affstoreTransferRefetch,
    affstoreTransferIsRefetching,
    setAffstoreTransferStatus: setAffstoreTransferStatusMutation.mutate,
    setAffstoreTransferStatusIsLoading: setAffstoreTransferStatusMutation.isLoading,
  }
}
