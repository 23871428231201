import React, { FC } from 'react'

import { Group, Stack, useForm } from '@affstore-ds/uikit'

import { PromoBannersIdFiltersType, PromoLandingsFiltersInterface } from './PromoLandingsFilters.types'

import { useDropdownStyles } from '../../../../../../../shared/styles/useDropdown.styled'

import { FilterButtons } from '../../../../../../../features/FilterButtons'
import { LocalesMultiSelect } from '../../../../../../../features/LocalesMultiSelect'
import { PromoBannerFileFormatsMultiSelect } from '../../../../../../../features/PromoBannerFileFormatsMultiSelect'
import { PromoBannerSizesMultiSelect } from '../../../../../../../features/PromoBannerSizesMultiSelect'
import { PROMO_BANNERS_ID_DEFAULT_FILTERS } from '../../constants/filters'

import { usePromoLandingsFiltersStyles } from './PromoLandingsFilters.styled'

export const PromoBannersIdFilters: FC<PromoLandingsFiltersInterface> = ({ filters, onChange, loading, imageSizes }) => {
  const { classes } = usePromoLandingsFiltersStyles()
  const { classes: dropdownClasses } = useDropdownStyles()

  const form = useForm<PromoBannersIdFiltersType>({
    initialValues: {
      fileFormats: filters.fileFormats || PROMO_BANNERS_ID_DEFAULT_FILTERS.fileFormats,
      imageSizes: filters.imageSizes || PROMO_BANNERS_ID_DEFAULT_FILTERS.imageSizes,
      locales: filters.locales || PROMO_BANNERS_ID_DEFAULT_FILTERS.locales,
    },
  })

  return (
    <form onSubmit={form.onSubmit(onChange)} data-testid="promo-banner-item-filters">
      <Stack spacing={0} className={classes.contentWrapper}>
        <Group spacing={20} pt={32} px={24} className={classes.filterWrapper}>
          <LocalesMultiSelect
            name="locales"
            classNames={{ dropdown: dropdownClasses.dropdown }}
            style={{ width: 290 }}
            disabled={loading}
            {...form.getInputProps('locales')}
          />
          <PromoBannerSizesMultiSelect
            name="imageSizes"
            imageSizes={imageSizes}
            classNames={{ dropdown: dropdownClasses.dropdown }}
            style={{ width: 290 }}
            disabled={loading}
            {...form.getInputProps('imageSizes')}
          />
          <PromoBannerFileFormatsMultiSelect
            name="fileFormat"
            classNames={{ dropdown: dropdownClasses.dropdown }}
            style={{ width: 290 }}
            disabled={loading}
            {...form.getInputProps('fileFormats')}
          />
        </Group>
        <FilterButtons
          form={form}
          resetFormValues={PROMO_BANNERS_ID_DEFAULT_FILTERS}
          filters={filters}
          loading={loading}
          omittedToCheckFormValues={['first', 'skip']}
        />
      </Stack>
    </form>
  )
}
