import React from 'react'

import { AuthContextInterface } from './types'

export const AuthContext = React.createContext<AuthContextInterface>({
  user: undefined,
  userError: null,
  userRefetch() {
    throw new Error('Not implemented `userRefetch`')
  },
  signIn() {
    throw new Error('Not implemented `signIn`')
  },
  signUp() {
    throw new Error('Not implemented `signUp`')
  },
  signOut() {
    throw new Error('Not implemented `signOut`')
  },
  confirmTwoFactorAuth() {
    throw new Error('Not implemented `confirmTwoFactorAuth`')
  },
  isConfirmTwoFactorAuthLoading: false,
  init2FaAuth() {
    throw new Error('Not implemented `init2FaAuth`')
  },
  enable2FaAuth() {
    throw new Error('Not implemented `enable2FaAuth`')
  },
  disable2FaAuth() {
    throw new Error('Not implemented `disable2FaAuth`')
  },
  resetPasswordRequest() {
    throw new Error('Not implemented `resetPasswordRequest`')
  },
  resetPassword() {
    throw new Error('Not implemented `resetPassword`')
  },
  clearError() {
    throw new Error('Not implemented `clearError`')
  },
  confirmEmail() {
    throw new Error('Not implemented `confirmEmail`')
  },
  pushOnLogoutCallback() {
    throw new Error('Not implemented `pushOnLogoutCallback`')
  },
  removeOnLogoutCallback() {
    throw new Error('Not implemented `removeOnLogoutCallback`')
  },
  isUserLoading: true,
  isLocalLoading: false,
  error: null,

  isAuthorizedData: undefined,
  isAuthorizedLoading: false,
  isAuthorizedError: null,
  refetchIsAuthorized() {
    throw new Error('Not implemented `refetchIsAuthorized`')
  },

  is2FaEnabledData: undefined,
  is2FaEnabledLoading: false,
  is2FaEnabledError: null,
  refetchIs2FaEnabled() {
    throw new Error('Not implemented `refetchIs2FaEnabled`')
  },
})
