import { MouseEventHandler } from 'react'
import { NavigateFunction } from 'react-router-dom'

export const handleNavigate =
  (navigate: NavigateFunction | ((path: string) => void), to: string): MouseEventHandler =>
  (event) => {
    event.preventDefault()

    navigate(to)

    return false
  }
