import React, { useMemo } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { ButtonSizeEnum, ButtonVariantEnum, Group, Link, Stack, Text } from '@affstore-ds/uikit'

import { getBrandConfig } from '../../config/brands'
import { LanguageDropdown } from '../../features/LanguageDropdown'

import { guestFooterTranslationKeys } from './translations/GuestFooter.translationKeys'
import { useGuestFooterStyles } from './GuestFooter.styled'

const { brandConfig } = getBrandConfig()

export const GuestFooter = () => {
  const { i18n } = useI18n()

  const { classes } = useGuestFooterStyles()

  const currentYear = useMemo(() => new Date().getFullYear(), [])

  const copyRightsText = useMemo(() => {
    // eslint-disable-next-line sonarjs/no-small-switch
    switch (brandConfig.brandName) {
      case '8xtrade': {
        return `${brandConfig.copyRights}-${currentYear}`
      }
      default: {
        return `${brandConfig.copyRights}, ${currentYear}`
      }
    }
  }, [currentYear])

  return (
    <Stack className={classes.footer} align="center">
      <LanguageDropdown type="guest" />
      <Group className={classes.footerAgreements}>
        <Link
          elementVariant={ButtonVariantEnum.SecondaryMainLine}
          elementSize={ButtonSizeEnum.S}
          href={brandConfig.termsAndConditionUrl}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="link-agreement"
        >
          {i18n.t(guestFooterTranslationKeys.agreement)}
        </Link>
        <Link
          elementVariant={ButtonVariantEnum.SecondaryMainLine}
          elementSize={ButtonSizeEnum.S}
          href={brandConfig.privacyPolicyUrl}
          target="_blank"
          rel="noopener noreferrer"
          data-testid="link-privacy-notice"
        >
          {i18n.t(guestFooterTranslationKeys.privacyNotice)}
        </Link>
      </Group>
      <Group className={classes.footerSupport} align="baseline">
        <Link
          elementVariant={ButtonVariantEnum.SecondaryMainLine}
          elementSize={ButtonSizeEnum.S}
          href={`mailto:${brandConfig.supportEmail}`}
          data-testid="link-mailto-support"
        >
          {brandConfig.supportEmail}
        </Link>
        <Text component="span" elementSize="xs" elementVariant="important" colorVariant="greyIron.13">
          &copy; {copyRightsText}
        </Text>
      </Group>
    </Stack>
  )
}
