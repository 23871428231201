import React, { ChangeEvent, forwardRef } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { useUncontrolled } from '@affstore-ds/helpers'
import { TextInput } from '@affstore-ds/uikit'

import { AffTrackInputPropertiesInterface, AffTrackInputPropertiesType } from './AffTrackInput.types'

import { affTrackSearchRegExp } from '../../shared/constants'

import { AffTrackSelectTranslationKeys } from './translations/AffTrackSelect.translationKeys'

export const AffTrackInput: AffTrackInputPropertiesType = forwardRef<HTMLInputElement, AffTrackInputPropertiesInterface>(
  ({ label, value: formValue, onChange, defaultValue, ...rest }, reference) => {
    const [value, setValue] = useUncontrolled({
      defaultValue,
      value: formValue,
      onChange,
    })
    const { i18n } = useI18n()

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const preparedSearchValue = event.target.value.replace(affTrackSearchRegExp, '')

      setValue(preparedSearchValue)
    }

    return (
      <TextInput
        ref={reference}
        value={value}
        label={label || i18n.t(AffTrackSelectTranslationKeys.statisticsAfftrackLabel)}
        data-testid="afftrack-input"
        placeholder={i18n.t(AffTrackSelectTranslationKeys.statisticsAfftrackPlaceholder)}
        labelIconTooltipText={i18n.t(AffTrackSelectTranslationKeys.statisticsAfftrackLabelTooltip)}
        onChange={handleChange}
        {...rest}
      />
    )
  },
)

AffTrackInput.displayName = 'AffTrackInput'
