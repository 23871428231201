import { useEffect, useState } from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { useLocalStorage } from '@affstore-ds/helpers'

import { useAffstoreTransferStatus } from '../../../../entities/hooks/useAffstoreTransferStatus'
import { useOldLoginGuide } from './useOldLoginGuide'

import { EventName } from '../../../../config/events'
import { affstoreTransferStatusEntity } from '../../../../entities/constants/affstoreTransferStatus.entity'
import { useAnalyticsEvents, useModal } from '../../../hooks'
import { getEventDataWithUserId, getSystemEventData } from '../../../utils'
import { GUIDE_STEP_INTRO, GUIDE_STEP_KEY, GUIDE_STEP_LEGAL } from '../constants/storage'

const { AFFSTORE_TRANSFER_STATUS, LEGAL_NOTICE_CONFIRMED } = affstoreTransferStatusEntity

export const useTransferGuideHandler = () => {
  const { isOldLoginGuide, setIsOldLoginGuide } = useOldLoginGuide()
  const [isModalShown, setModalShown] = useState<boolean>(false)
  const { opened: isGuideOpened, openModal: openGuide, closeModal: closeGuide } = useModal()
  const { affstoreTransferData, setAffstoreTransferStatus, affstoreTransferRefetch } = useAffstoreTransferStatus()
  const { sendEvent } = useAnalyticsEvents()
  const {
    auth: { user },
  } = useAuth()

  const shouldOpenGuide =
    affstoreTransferData.transferStatus === AFFSTORE_TRANSFER_STATUS.done && !affstoreTransferData.legalNoticeConfirmed && !isModalShown
  const shouldClearStorage = affstoreTransferData.legalNoticeConfirmed

  const [guideStep, setGuideStep] = useLocalStorage({
    key: GUIDE_STEP_KEY,
    defaultValue: localStorage.getItem(GUIDE_STEP_KEY) ?? GUIDE_STEP_INTRO,
  })

  const setLegalStep = () => setGuideStep(GUIDE_STEP_LEGAL)
  const isIntroStep = guideStep === GUIDE_STEP_INTRO

  const finishGuide = () => {
    setAffstoreTransferStatus({ [LEGAL_NOTICE_CONFIRMED]: true })
    affstoreTransferRefetch()
    closeGuide()
  }

  useEffect(() => {
    if (shouldClearStorage) {
      localStorage.removeItem(GUIDE_STEP_KEY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (shouldOpenGuide) {
      openGuide()
      setModalShown(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affstoreTransferData])

  useEffect(() => {
    if (isGuideOpened && user?.id) {
      const currentEventName = isIntroStep ? EventName.guideVideoModalOpen : EventName.guideLegalModalOpen
      const systemEventData = getSystemEventData(currentEventName)
      const eventDataWithUserId = getEventDataWithUserId(currentEventName, user?.id)

      const eventData = {
        ...systemEventData,
        ...eventDataWithUserId,
      }

      sendEvent(eventData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntroStep, isGuideOpened, user?.id])

  return {
    isOldLoginGuide,
    setIsOldLoginGuide,
    isGuideOpened,
    closeGuide,
    finishGuide,
    isIntroStep,
    setLegalStep,
  }
}
