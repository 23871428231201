import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'

import { HelmetPageTitlePropertiesInterface } from './HelmetPageTitle.types'

import { getBrandConfig } from '../../../config/brands'

const { brandConfig } = getBrandConfig()

export const HelmetPageTitle: FC<HelmetPageTitlePropertiesInterface> = ({ pageTitle, helmetTitle }) => (
  <Helmet title={`${brandConfig.helmet.baseTitle} | ${helmetTitle || (typeof pageTitle === 'string' ? pageTitle : '')}`}></Helmet>
)
