import { ReportsTypesComponents } from '@affiliate-cabinet/types'

const ROUTES_ROOT = {
  root: '/',
}

const ROUTES_AUTH = {
  signIn: '/auth/signin',
  signUp: '/auth/signup',
  twoFA: '/auth/2fa',
  resetPasswordRequest: '/auth/reset-password/request',
  resetPassword: '/auth/reset-password',
  activate: '/auth/activate/:token',
}

const ROUTES_ONBOARDING = {
  onboarding: '/onboarding',
}

const ROUTES_STATISTICS = {
  root: '/statistics',
  general: '/statistics/general',
  users: '/statistics/users',
}

const ROUTES_PROFILE = {
  profile: '/profile',
  profileSettings: '/profile/settings',
  profileSettingsTab: '/profile/settings/:tabValue',
  profileSecurity: '/profile/security',
  profileKYC: '/profile/kyc',
}

const ROUTES_INFO = {
  info: 'https://support.affstore.com',
  blog: 'https://blog.affstore.com/?utm_source=cabinet&utm_medium=new_section&utm_campaign=cabinet_promo',
}

const ROUTES_PAYMENTS = {
  paymentsTab: '/payments/:tabValue',
}

const ROUTES_POSTBACKS = {
  postbacksRoot: '/postbacks',
  postbacksAll: '/postbacks/*',
  postbacksList: '/postbacks/list',
  postbacksCreate: '/postbacks/create',
  postbacksEdit: '/postbacks/edit/',
  postbacksEditId: '/postbacks/edit/:id',
  postbacksInfo: '/postbacks/info',
  postbacksTelegram: '/postbacks/telegram',
  postbacksTelegramCreate: '/postbacks/telegram/create',
  postbacksTelegramEdit: '/postbacks/telegram/edit/:id',
}

const ROUTES_PROMO = {
  promoTab: '/promo/:tabValue',
  promoTabById: '/promo/:tabValue/:id',
}

const ROUTES_OFFERS = {
  offers: '/offers',
  offersAdvertisers: '/offers/advertisers',
  offerTab: '/offers/:advertAlias/:model/:tabValue',
  offerModel: '/offers/:advertAlias/:model/',
}

const ROUTES_SUB_AFFILIATE = {
  root: '/statistics',
  subAffiliate: '/statistics/referral',
}

const ROUTES_ERROR = {
  404: '/404',
  500: '/500',
}

const ROUTES_SHARED = {
  authenticatorRedirect: '/authenticator/redirect',
  paymentAccountConfirm: '/payment-account/confirm/:token',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  byTransferToken: '/auth/by-transfer-token/:token',
}

const ROUTES_SHARED_VALUES = Object.values(ROUTES_SHARED)

const ROUTES_DASHBOARD = {
  dashboard: ROUTES_OFFERS.offers, // '/dashboard', TODO: revert after dashboard in release
}

const ROUTES_TOP = {
  root: '/top10',
}

const ROUTES_CONTESTS_TABS: Record<
  Exclude<ReportsTypesComponents['schemas']['ContestStatus'], 'calculating'> | '_paramsKey',
  Exclude<ReportsTypesComponents['schemas']['ContestStatus'], 'calculating'> | string
> = {
  _paramsKey: ':contestsState',
  active: 'active',
  upcoming: 'upcoming',
  completed: 'completed',
}
const ROUTES_CONTEST = {
  _paramsKey: ':id',
}
const ROUTES_CONTEST_TABS: Record<'info' | 'leaderboard' | '_paramsKey', string> = {
  _paramsKey: ':contestTab',
  info: 'info',
  leaderboard: 'leaderboard',
}
const ROUTES_CONTESTS = {
  root: '/contests',
  tabsPath: `/contests/${ROUTES_CONTESTS_TABS._paramsKey}`,
  contestPath: `/contests/contest/${ROUTES_CONTEST._paramsKey}`,
  contestTabsPath: `/contests/contest/${ROUTES_CONTEST._paramsKey}/${ROUTES_CONTEST_TABS._paramsKey}`,
}

export const routes = {
  ROUTES_ROOT,
  ROUTES_AUTH,
  ROUTES_ONBOARDING,
  ROUTES_STATISTICS,
  ROUTES_PROFILE,
  ROUTES_INFO,
  ROUTES_PAYMENTS,
  ROUTES_POSTBACKS,
  ROUTES_PROMO,
  ROUTES_OFFERS,
  ROUTES_SUB_AFFILIATE,
  ROUTES_ERROR,
  ROUTES_SHARED,
  ROUTES_SHARED_VALUES,
  ROUTES_DASHBOARD,
  ROUTES_TOP,
  ROUTES_CONTESTS,
  ROUTES_CONTESTS_TABS,
  ROUTES_CONTEST,
  ROUTES_CONTEST_TABS,
}
