import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const useNavigationHandler = () => {
  const [isRedirectAllowed, setRedirectAllowed] = useState<boolean>(true)
  const [isRedirectConfirmationRequired, setRedirectConfirmationRequired] = useState<boolean>(false)
  const [path, setPath] = useState<string | null>(null)

  const navigate = useNavigate()

  const allowRedirect = () => setRedirectAllowed(true)
  const blockRedirect = () => setRedirectAllowed(false)
  const requireRedirectConfirmation = () => setRedirectConfirmationRequired(true)
  const resetRedirectConfirmation = () => setRedirectConfirmationRequired(false)
  const resetPath = () => setPath(null)

  const resetRedirectRestrictions = () => {
    resetRedirectConfirmation()
    resetPath()
    allowRedirect()
  }

  const redirect = (nextPath: string) => {
    if (!path) {
      setPath(nextPath)
    }

    if (!isRedirectAllowed) {
      requireRedirectConfirmation()

      return
    }

    navigate(nextPath)
    resetRedirectRestrictions()
  }

  useEffect(() => {
    if (!path) {
      return
    }
    if (!isRedirectAllowed) {
      return
    }

    navigate(path)
    resetRedirectRestrictions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, isRedirectAllowed, path])

  return {
    isRedirectConfirmationRequired,
    isRedirectAllowed,
    redirect,
    allowRedirect,
    blockRedirect,
    resetRedirectRestrictions,
  }
}
