import React from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { Badge, ButtonVariantEnum, Group, Link, Skeleton, Text } from '@affstore-ds/uikit'

import { useGetNextPaymentDate } from '../../../../entities/hooks/useGetNextPaymentDate'

import { handleNavigate } from '../../../../shared/navigation/handleNavigate'
import { paymentsTabSettings } from '../../../../shared/navigation/NavMenuData'
import { useNavigationContainer } from '../../../../shared/providers/NavigationContainer'
import { routes } from '../../../../shared/routes'
import { headerMainTranslationKeys } from '../../translations/headerMain.translationKeys'

export const PaymentInfo = () => {
  const { i18n } = useI18n()
  const {
    auth: { user },
  } = useAuth()

  const { redirect } = useNavigationContainer()

  const { paymentDate, nextPaymentDateIsFetching } = useGetNextPaymentDate()

  const paymentsHref = routes.ROUTES_PAYMENTS.paymentsTab.replace(':tabValue', paymentsTabSettings.regularAndRequests)

  if (!(user?.is_onboarding_passed && paymentDate)) {
    return null
  }

  return nextPaymentDateIsFetching ? (
    <Skeleton height={32} width={248} />
  ) : (
    <Group spacing={12}>
      <Text elementSize="s" elementVariant="important" colorVariant="greyIron.18">
        {i18n.t(headerMainTranslationKeys.paymentsNextPayment)}
      </Text>
      <Link elementVariant={ButtonVariantEnum.SecondaryMainLine} href={paymentsHref} onClick={handleNavigate(redirect, paymentsHref)}>
        <Badge elementVariant="header" elementSize="l">
          {paymentDate}
        </Badge>
      </Link>
    </Group>
  )
}
