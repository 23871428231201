import { createStyles } from '@affstore-ds/uikit'

export const useLayoutMainStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: theme.other.viewport.height,
    boxSizing: 'border-box',
    borderRight: `1px solid ${theme.other.colors.greyIron[4]}`,
  },
  body: {
    flexGrow: 2,
  },
  main: {
    padding: 0,
    'p, span, li': {
      '& > a': {
        fontSize: 'inherit !important',
      },
    },
  },
  scrollContainerRoot: {
    position: 'relative',
    height: theme.other.viewport.height,
    scrollBehavior: 'smooth',
  },
  scrollbar: {
    zIndex: 999_999,
  },
  container: {
    width: 1024,
    padding: 32,
  },
}))
