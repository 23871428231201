export const transferPartnerTranslationKeys = {
  guideModalCoverTitle: 'transfer-partner.guide-modal-video-cover-title',
  guideModalCoverSubtitle: 'transfer-partner.guide-modal-video-cover-subtitle',
  guideModalTitle: 'transfer-partner.guide-modal-title',
  guideModalDescriptionPart1: 'transfer-partner.guide-modal-description-part1',
  guideModalDescriptionPart1Ib: 'transfer-partner.guide-modal-description-part1-ib',
  guideModalDescriptionPart2: 'transfer-partner.guide-modal-description-part2',
  guideModalDescriptionPart2Ib: 'transfer-partner.guide-modal-description-part2-ib',
  guideModalButton: 'transfer-partner.guide-modal-button',
  legalNoticeModalTitle: 'transfer-partner.legal-notice-modal-title',
  legalNoticeModalDescriptionPart1: 'transfer-partner.legal-notice-modal-description-part1',
  legalNoticeModalDescriptionPart2: 'transfer-partner.legal-notice-modal-description-part2',
  legalNoticeModalDescriptionPart3: 'transfer-partner.legal-notice-modal-description-part3',
  legalNoticeModalCloseButton: 'transfer-partner.legal-notice-modal-close-button',
  legalNoticeModalAcceptButton: 'transfer-partner.legal-notice-modal-accept-button',
  infoTransferDescription: 'info-transfer.description',
  infoTransferTitle: 'info-transfer.title',
}
