export const linkTypesEntityTranslationKeys = {
  offersPageOfferTabLinksLinkWeb: 'offers.page-offer-tab-links-link-web',

  offersPageOfferTabLinksLinkMix: 'offers.page-offer-tab-links-link-mix',
  offersPageOfferTabLinksLinkMixTooltip: 'offers.page-offer-tab-links-link-mix-tooltip',

  offersPageOfferTabLinksLinkIos: 'offers.page-offer-tab-links-link-ios',
  offersPageOfferTabLinksLinkIosTooltip: 'offers.page-offer-tab-links-link-ios-tooltip',

  offersPageOfferTabLinksLinkAndroid: 'offers.page-offer-tab-links-link-android',

  offersPageOfferTabLinksLinkPwa: 'offers.page-offer-tab-links-link-pwa',
  offersPageOfferTabLinksLinkPwaTooltip: 'offers.page-offer-tab-links-link-pwa-tooltip',
}
