import { DomainsInterface } from '../../../../../appConfig'

export const resolveCookieDomain = (isForcedProduction?: boolean): string => {
  const host = window.location.hostname

  const config: DomainsInterface = window.__APP_CONFIG__.domains

  if (isForcedProduction) {
    return config.prod.cookieDomain
  }

  // Prod
  if (config.prod.apps.includes(host)) {
    return config.prod.cookieDomain
  }

  // Int
  if (config.int.apps.includes(host)) {
    return config.int.cookieDomain
  }

  // Sb
  if (config.sb.isSandbox(host)) {
    return config.sb.cookieDomain(host)
  }

  return ''
}
