import { useEffect, useMemo } from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { ModelAdvertParametersInterface } from '@affiliate-cabinet/rest'
import { useForm } from '@affstore-ds/uikit'

import { useApiAdvertisersIdModelsTranslationKeys } from '../../../../../../../entities/offer/model/useOffersModelsByAdvertiserId/translations/useApiAdvertisersIdModels.translationKeys'

import { useOffersModelsByAdvertiserId } from '../../../../../../../entities/offer'
import { offerTabSettings } from '../../../../../../../shared/navigation/NavMenuData'
import { getOfferTabLink } from '../../../../../../../shared/utils'
import { bannerModalGenerateLinkTranslationKeys } from '../../containers/BannerModalGenerateLink/translations/bannerModalGenerateLink.translationKeys'

export const usePromoBannerLinkGenerate = ({
  size,
  bannerLink,
  advertiserAlias,
  advertiserId,
}: { size: string; bannerLink: string; advertiserId: number } & Pick<ModelAdvertParametersInterface, 'advertiserAlias'>) => {
  const { i18n } = useI18n()
  const {
    auth: { user },
  } = useAuth()
  const { unavailableModels, modelsWithUnacceptedRules, defaultModel, isFetching } = useOffersModelsByAdvertiserId(advertiserId)

  const form = useForm({
    initialValues: {
      landingLink: '',
      model: '',
      afftrack: '',
    },
  })

  const [width, height] = size.split('x')

  const href = useMemo(() => {
    if (form.values.landingLink) {
      return (
        form.values.landingLink
          .replace(':aff:', user?.id.toString() || '')
          .replace(':model:', form.values.model)
          .replace(':afftrack:', form.values.afftrack) || ''
      )
    }

    return ''
  }, [form.values.afftrack, form.values.landingLink, form.values.model, user?.id])

  const unavailableModel = form.values.model && unavailableModels.find((model) => model.model === form.values.model)
  const modelWithUnacceptedRules = form.values.model && modelsWithUnacceptedRules.find((model) => model.model === form.values.model)

  const modelError = useMemo(() => {
    if (unavailableModel) {
      return i18n.t(useApiAdvertisersIdModelsTranslationKeys.offerModelsOfferStatusIsInactive)
    }

    if (modelWithUnacceptedRules) {
      return i18n.t(useApiAdvertisersIdModelsTranslationKeys.offerModelsOfferRulesAreNotAccepted)
    }

    return null
  }, [unavailableModel, modelWithUnacceptedRules, i18n])

  const tabOfferLink = useMemo(() => {
    if (unavailableModel) {
      return getOfferTabLink(advertiserAlias, unavailableModel.model, offerTabSettings.activation)
    }

    if (modelWithUnacceptedRules) {
      return getOfferTabLink(advertiserAlias, modelWithUnacceptedRules.model, offerTabSettings.rules.rulePaymentTerms)
    }

    return undefined
  }, [unavailableModel, modelWithUnacceptedRules, advertiserAlias])

  const tabOfferLinkText = useMemo(() => {
    if (unavailableModel) {
      return i18n.t(bannerModalGenerateLinkTranslationKeys.promoModalButtonActivateOffer)
    }

    if (modelWithUnacceptedRules) {
      return i18n.t(bannerModalGenerateLinkTranslationKeys.promoModalButtonAcceptRules)
    }

    return ''
  }, [unavailableModel, modelWithUnacceptedRules, i18n])

  useEffect(() => {
    if (!isFetching && !form.values.model && defaultModel) {
      form.setFieldValue('model', defaultModel.model)
    }
  }, [isFetching, defaultModel, form])

  return {
    form,
    tabOfferLink,
    tabOfferLinkText,
    link: `<a target="_blank" href="${href}"><img alt="banner image" src="${bannerLink}" width="${width}" height="${height}" /></a>`,
    modelError,
  }
}
