import { PromoNewApiPaths, PromoNewComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(PromoNewApiPaths.GetApiV3PromoBannersSizes, {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const getApiV3PromoBannersSizes = async () => client.get<PromoNewComponents['schemas']['BannerSizes']>('')
