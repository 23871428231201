import React, { FC, useMemo } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { MultiSelect, MultiSelectPropertiesInterface } from '@affstore-ds/uikit'

import { usePromoBannerSizes } from '../../entities/hooks/usePromoBannerSizes'

import { InputLoader } from '../../shared/components/InputLoader'
import { commonTranslationKeys } from '../../shared/translations/common.translationKeys'
import { selectTranslationKeys } from '../../shared/translations/select.translationKeys'

import { promoBannerSizesMultiSelectTranslationKeys } from './translations/promoBannerSizesMultiSelect.translationKeys'

export const PromoBannerSizesMultiSelect: FC<Omit<MultiSelectPropertiesInterface, 'data'> & { imageSizes?: string[] }> = ({
  label,
  disabled,
  imageSizes,
  ...rest
}) => {
  const { i18n } = useI18n()
  const { bannerSizes, bannerSizesIsLoading } = usePromoBannerSizes(imageSizes)

  const BANNER_SIZES_LIST_PREPARED = useMemo(
    () =>
      (imageSizes || bannerSizes).map((bannerSize) => ({
        value: bannerSize,
        label: bannerSize,
        'data-testid': `promo-banner-sizes-item-${bannerSize}`,
      })),
    [bannerSizes, imageSizes],
  )

  return (
    <MultiSelect
      data={BANNER_SIZES_LIST_PREPARED}
      label={label || i18n.t(promoBannerSizesMultiSelectTranslationKeys.promoBannerSizesLabel)}
      data-testid="promo-banner-sizes-multi-select"
      placeholder={i18n.t(selectTranslationKeys.selectPlaceholder)}
      selectAllLabel={i18n.t(selectTranslationKeys.selectSelectAll)}
      clearLabel={i18n.t(selectTranslationKeys.selectClear)}
      maxDropdownHeight={400}
      nothingFound={i18n.t(commonTranslationKeys.commonNoData)}
      disabled={disabled || bannerSizesIsLoading}
      rightSection={bannerSizesIsLoading && <InputLoader />}
      dropdownPosition="bottom"
      {...rest}
    />
  )
}
