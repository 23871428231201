import { lazy } from 'react'

import { TAnyType } from '@affstore-ds/types'
import { LoaderApp } from '@affstore-ds/uikit'

const LS_PAGE_WAS_FORCE_REFRESHED = 'page-was-force-refreshed'

export const lazyLoadPageWithRetry = (componentImport: () => Promise<TAnyType>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(window.localStorage.getItem(LS_PAGE_WAS_FORCE_REFRESHED) || 'false')

    try {
      const component = await componentImport()

      window.localStorage.setItem(LS_PAGE_WAS_FORCE_REFRESHED, 'false')

      return component
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(LS_PAGE_WAS_FORCE_REFRESHED, 'true')

        window.location.reload()

        return { default: LoaderApp }
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error
    }
  })
