import { useLocation } from 'react-router-dom'

import { getPostbacksSettings, useQuery } from '@affiliate-cabinet/rest'
import { PostbacksTypesComponents, ResponseErrorInterface } from '@affiliate-cabinet/types'

import { featuresStatuses } from '../../../../../config/brands'
import { routes } from '../../../../../shared/routes'

export const useIsPostbacksEnabledQuery = () => {
  const location = useLocation()
  const isPostbacksLocation = location.pathname.includes(routes.ROUTES_POSTBACKS.postbacksRoot)

  const { data: postbacksSettings, isFetching } = useQuery<unknown, ResponseErrorInterface, PostbacksTypesComponents['schemas']['UserSettings']>(
    ['getPostbacksSettings'],
    getPostbacksSettings,
    {
      retry: false,
      enabled: isPostbacksLocation,
    },
  )

  return {
    data: postbacksSettings?.isEnabled,
    isLoading: isFetching,
    isTelegramPostbacksEnabled: featuresStatuses.isTelegramPostbacksEnabled,
  }
}
