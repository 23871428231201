import React from 'react'

import { TranslationInterface } from './types'

import { DATA_TRANSLATION_ATTRIBUTE } from '../constants'

import { sanitizeHtml } from './sanitizeHtml'

// INSPIRED BY https://github.com/lukeed/rosetta

/** Draft wraps everything in paragraphs, so when we need a piece of html without paragraphs, we use InlineHTML */
const removeP = (string_?: string) => string_?.replace(/(^<p>)|(<\/p>$)/g, '')

/** Clean up target and set target="_blank" on external links */
const clear = (string_?: string) => string_?.replace(/target="\w+"/g, '').replace(/(href="\S*\/\/\S+")/g, '$1 target="_blank"')

export const translationHandler = (object?: Record<string, unknown>): TranslationInterface => {
  let locale = ''
  const tree = (object || {}) as Record<string, unknown>

  /** Condition variable to render translation keys as values */
  let keysAsValues: boolean | undefined = false

  const replaceByMap = (string?: string, replaceMap?: Record<string, string | number>) =>
    Object.entries(replaceMap || {}).reduce((accumulator, [key, value]) => accumulator?.replace(key, `${value}`), string || '')

  return {
    set(lang: string, table: Record<string, unknown>) {
      tree[lang] = Object.assign((tree[lang] || {}) as Record<string, unknown>, table)
    },

    locale(lang?: string) {
      return (locale = lang || locale)
    },

    table(lang: string) {
      return tree[lang] as Record<string, string> | void
    },

    t(key, parameters, lang) {
      if (keysAsValues) {
        return key
      }

      const value = (tree[lang || locale] as Record<string, unknown>)?.[key]

      if (typeof value === 'function') {
        return value(parameters)
      }

      return value || key
    },
    inlineHTML(key, { preprocess, replace, shouldReplaceTarget = true, useDataTranslationAttribute = true, ...restProperties } = {}) {
      const replaced = replace ? replaceByMap(this.t(key), replace) : this.t(key)
      const cleared = shouldReplaceTarget ? clear(removeP(replaced)) : removeP(replaced)
      const processed = preprocess ? preprocess(cleared) : cleared

      return (
        <span
          data-translation={useDataTranslationAttribute ? DATA_TRANSLATION_ATTRIBUTE : undefined}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(processed) }}
          {...restProperties}
        />
      )
    },
    blockHTML(key, { preprocess, replace, useDataTranslationAttribute = true, ...restProperties } = {}) {
      const replaced = replace ? replaceByMap(this.t(key), replace) : this.t(key)
      const cleared = clear(replaced)
      const processed = preprocess ? preprocess(cleared) : cleared

      return (
        <div
          data-translation={useDataTranslationAttribute ? DATA_TRANSLATION_ATTRIBUTE : undefined}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(processed) }}
          {...restProperties}
        />
      )
    },
    replace(key: string, replace) {
      return replace ? replaceByMap(this.t(key), replace) : this.t(key)
    },
    setKeysAsValues(isKeysAsValue) {
      keysAsValues = isKeysAsValue
    },
  }
}
