import { TwoFactorAuthTypesApiPaths } from '@affiliate-cabinet/types'

import { AuthorizationStatusType } from './types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(TwoFactorAuthTypesApiPaths.GetApiAuthIsauthorized, {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const checkIsAuthorizedHandler = async () => client.get<AuthorizationStatusType>('')
