export const managersInfoTranslationKeys = {
  navMenuManagersContacts: 'nav-menu.managers-contacts',

  navMenuButtonSendMessage: 'nav-menu.button-send-message',
  managerOnVacationUntilDate: 'user.manager-on-vacation-until-date',
  managerOnBusinessTripUntilDate: 'user.manager-on-trip-until-date',
  managerOnHolidaysUntilDate: 'user.manager-on-holidays-until-date',
  managerOnSickUntilDate: 'user.manager-on-sick-until-date',

  managerDaysWork: 'manager.days-work',
}
