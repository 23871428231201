import { parse } from 'qs'

import { Cookies } from '@affstore-ds/helpers'
import { TAnyType } from '@affstore-ds/types'

import { removeCookies } from '../removeCookies'
import { resolveDomain } from '../resolveDomain'

const handle401 = () => {
  const { search, host } = window.location

  removeCookies()

  // чтобы логин считал ретеншен трек
  // записываем в куку
  const query = parse(search.slice(1))

  if (query && query['retention_track']) {
    Cookies.setCookie('retentionTrack', query['retention_track'] as string, {
      expires: 2_592_000,
      path: '/',
      domain: host.replace('affstore.', ''),
    })
  }

  window.location.href = '/'
}

const handleJson = (response: Response) =>
  response.json().then((json) => {
    // Новые апишки

    if (typeof json !== 'object' || json === null || !('isSuccessful' in json)) {
      // c плохим ответом
      if (response.status >= 400) {
        throw json
      }

      if ('meta' in json) {
        return { data: json?.data, ...json?.meta }
      }

      // c хорошим ответом
      return json
    }

    // старые апишки

    if (!('result' in json) || json.isSuccessful === false) {
      const { message } = json
      let { messageParams } = json

      if (typeof message === 'object' && messageParams === undefined) {
        messageParams = {}
      }

      throw messageParams === undefined ? json.message : { message, messageParams }
    }

    return json.result
  })

const commonHandler = (promise: TAnyType) =>
  promise.then((response: Response) => {
    if (response.status === 401 && window.location.pathname !== '/') {
      handle401()
    }

    if (response.status === 204) {
      return response
    }

    const contentType = response.headers.get('content-type')

    if (!contentType || !contentType.includes('application/json')) {
      return response
    }

    return handleJson(response)
  })

export const postFormData = <TParameters extends object, TResult>(path: string, parameters: TParameters, restPath = resolveDomain()): TResult => {
  const body = new FormData()

  for (const k of Object.keys(parameters)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    body.append(k, parameters[k] as string | Blob)
  }

  return commonHandler(
    fetch(`${restPath}${path}`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      body,
    }),
  )
}
