import { createContext } from 'react'

import { I18nType } from './types'

import { translationHandler } from '../utils/translationHandler'

export const i18nTranslationHandler = translationHandler()

export const i18n: I18nType = {
  t: i18nTranslationHandler.t,
  locale: i18nTranslationHandler.locale,
  table: i18nTranslationHandler.table,
  replace: i18nTranslationHandler.replace,
  inlineHTML: i18nTranslationHandler.inlineHTML,
  blockHTML: i18nTranslationHandler.blockHTML,
  isTranslationsLoading: true,
  handleLocale: () => {
    throw new Error('You need to implement "handleLocale"')
  },
}

export const I18nContext = createContext(i18n)
