import { BillingTypesPaths } from '@affiliate-cabinet/types'
import { createEnumObject, KeysEnumType } from '@affstore-ds/types'

type BillingPathsEnumType = KeysEnumType<BillingTypesPaths>
type BillingPathsKeysType = keyof BillingPathsEnumType
type BillingPathsValuesType = BillingPathsEnumType[BillingPathsKeysType]

export const BillingPathsEnum = createEnumObject<BillingPathsValuesType>({
  '/': '/',
  '/balances': '/balances',
  '/balances/{id}/payment-conditions': '/balances/{id}/payment-conditions',
  '/offer': '/offer',
  '/payment-accounts': '/payment-accounts',
  '/payment-accounts/set-default': '/payment-accounts/set-default',
  '/payment-conditions': '/payment-conditions',
  '/payments': '/payments',
  '/payments/next-payment-date': '/payments/next-payment-date',
  '/payments/receipt/{hash}': '/payments/receipt/{hash}',
  '/public/v2/affstore-subscriptions': '/public/v2/affstore-subscriptions',
  '/public/v2/dictionary/countries': '/public/v2/dictionary/countries',
  '/public/v2/settings/country-restrictions': '/public/v2/settings/country-restrictions',
  '/public/v2/settings/country-restrictions/is-registration-allowed': '/public/v2/settings/country-restrictions/is-registration-allowed',
  '/public/v2/tds/backlinks': '/public/v2/tds/backlinks',
  '/public/v2/tds/instrument-links': '/public/v2/tds/instrument-links',
  '/public/v2/tds/redirection-rules': '/public/v2/tds/redirection-rules',
  '/statistic/user/aff-tracks': '/statistic/user/aff-tracks',
  '/user': '/user',
  '/v2/cpa/price-set/{id}/countries-prices': '/v2/cpa/price-set/{id}/countries-prices',
  '/v3/balance': '/v3/balance',
  '/v3/payments/regulars/settings': '/v3/payments/regulars/settings',
  '/v3/payments/requests/settings': '/v3/payments/requests/settings',
  '/v3/payments/requests': '/v3/payments/requests',
  '/v3/affstore-transfer': '/v3/affstore-transfer',
  '/v3/affstore-transfer-login': '/v3/affstore-transfer-login',
  '/v3/affstore-transfer-login/token': '/v3/affstore-transfer-login/token',
  '/v3/balances': '/v3/balances',
  '/public/v2/tasks': '/public/v2/tasks',
  '/exchange-rates/{pair}': '/exchange-rates/{pair}',
})

export enum BillingPathBaseEnum {
  '/api/rest/affiliate' = '/api/rest/affiliate',
}
