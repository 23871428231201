import React, { memo, PropsWithChildren } from 'react'

import { AppAnalyticsProvider } from './AppAnalyticsProvider'
import { AppApolloProvider } from './AppApolloProvider'
import { AppAuthProvider } from './AppAuthProvider'
import { AppErrorBoundaryProvider } from './AppErrorBoundaryProvider'
import { AppHelmetProvider } from './AppHelmetProvider'
import { AppI18nProvider } from './AppI18nProvider'
import { AppRestQueryClientProvider } from './AppRestQueryClientProvider'
import { AppRouterProvider } from './AppRouterProvider'
import { AppThemeProvider } from './AppThemeProvider'
import { AppTrackerProvider } from './AppTrackerProvider'

export const AppProviders = memo(({ children }: PropsWithChildren) => (
  <AppThemeProvider>
    <AppRouterProvider>
      <AppHelmetProvider>
        <AppRestQueryClientProvider>
          <AppApolloProvider>
            <AppAuthProvider>
              <AppI18nProvider>
                <AppAnalyticsProvider>
                  <AppTrackerProvider>
                    <AppErrorBoundaryProvider>{children}</AppErrorBoundaryProvider>
                  </AppTrackerProvider>
                </AppAnalyticsProvider>
              </AppI18nProvider>
            </AppAuthProvider>
          </AppApolloProvider>
        </AppRestQueryClientProvider>
      </AppHelmetProvider>
    </AppRouterProvider>
  </AppThemeProvider>
))
AppProviders.displayName = 'AppProviders'
