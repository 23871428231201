import React from 'react'

import { TransferGuideContainerContextInterface } from './types'

export const TransferGuideContainerContext = React.createContext<TransferGuideContainerContextInterface>({
  isOldLoginGuide: false,
  setIsOldLoginGuide: () => {
    throw new Error('Not implemented setIsOldLoginGuide method')
  },
  isGuideOpened: false,
  closeGuide: () => {
    throw new Error('Not implemented closeGuide method')
  },
  finishGuide: () => {
    throw new Error('Not implemented finishGuide method')
  },
  isIntroStep: true,
  setLegalStep: () => {
    throw new Error('Not implemented setLegalStep method')
  },
})

TransferGuideContainerContext.displayName = 'TransferGuideContainerContext'
