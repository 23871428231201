import { UsersTypesApiPaths, UsersTypesComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(UsersTypesApiPaths.GetApiV4User, {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const getAffiliateEntity = async () => client.get<UsersTypesComponents['schemas']['UserProfile']>('')
