import { CoreTypesApiPaths, CoreTypesComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(CoreTypesApiPaths.EmailConfirmController_confirm, {}, resolveDomain(), { mode: 'cors', referrerPolicy: 'no-referrer' })

export const emailConfirmHandler = async (data: CoreTypesComponents['schemas']['EmailConfirmDto']) => {
  await client.post<CoreTypesComponents['schemas']['EmailConfirmDto'], Response>(``, data, {
    'Content-Type': 'application/json',
  })
}
