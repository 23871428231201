import { useMemo } from 'react'

import { AffModels } from '@affiliate-cabinet/graphql'
import { useI18n } from '@affiliate-cabinet/i18n'
import { getApiV2Offers, useQuery } from '@affiliate-cabinet/rest'
import { OffersTypesComponents, ResponseErrorInterface } from '@affiliate-cabinet/types'

import { useApiAdvertisersIdModelsTranslationKeys } from './translations/useApiAdvertisersIdModels.translationKeys'

import { affModelEntity } from '../../../constants/affModel.entity'

export const useOffersModelsByAdvertiserId = (advertiserId: number) => {
  const { i18n } = useI18n()
  const {
    data: availableOffers,
    isFetching: availableOffersIsLoading,
    error: availableOffersError,
  } = useQuery<unknown, ResponseErrorInterface, OffersTypesComponents['schemas']['OffersCollectionV2']>(
    ['useOffersModelsByAdvertiserId-getApiOffersAvailable', advertiserId],
    () =>
      getApiV2Offers({
        advertiserIds: [Number(advertiserId)],
        status: 'available',
      }),
    {
      keepPreviousData: true,
    },
  )

  const {
    data: unavailableOffers,
    isFetching: unavailableOffersIsLoading,
    error: unavailableOffersError,
  } = useQuery<
    unknown,
    ResponseErrorInterface,
    OffersTypesComponents['schemas']['OffersCollection'] | OffersTypesComponents['schemas']['OffersCollectionV2']
  >(
    ['useOffersModelsByAdvertiserId-getApiOffersUnavailable', advertiserId],
    () =>
      getApiV2Offers({
        advertiserIds: [Number(advertiserId)],
        status: 'unavailable',
      }),
    {
      keepPreviousData: true,
    },
  )

  const preparedModels = useMemo(() => {
    const availableOffersData = availableOffers?.data || []
    const unavailableOffersData = unavailableOffers?.data || []

    const availableModels = availableOffersData.map((offer) => ({
      model: offer.model,
      offerId: offer.id,
    }))
    const unavailableModels = unavailableOffersData.map((offer) => ({
      model: offer.model,
      offerId: offer.id,
    }))

    const models = [...availableModels, ...unavailableModels]

    const availableModelsWithAcceptedRules = availableOffersData
      .filter((offer) => offer.marketingRulesAccepted)
      .map((offer) => ({
        model: offer.model,
        offerId: offer.id,
      }))

    const modelsWithUnacceptedRules = [...availableOffersData, ...unavailableOffersData]
      .filter((offer) => !offer.marketingRulesAccepted)
      .map((offer) => ({
        model: offer.model,
        offerId: offer.id,
      }))

    const getDefaultModel = () => {
      if (availableModelsWithAcceptedRules && availableModelsWithAcceptedRules.length > 0) {
        return availableModelsWithAcceptedRules[0]
      }

      if (availableModels && availableModels.length > 0) {
        return availableModels[0]
      }

      return unavailableModels?.[0]
    }

    return {
      models: models.map((model) => {
        const renderModelDescription = () => {
          const offerUnavailableModel = unavailableModels?.some((unavailableModel) => unavailableModel.model === model.model)

          if (offerUnavailableModel) {
            return i18n.t(useApiAdvertisersIdModelsTranslationKeys.offerModelsOfferStatusIsInactive)
          }

          const offerModelWithUnacceptedRules = modelsWithUnacceptedRules?.some(
            (modelWithUnacceptedRules) => modelWithUnacceptedRules.model === model.model,
          )

          if (offerModelWithUnacceptedRules) {
            return i18n.t(useApiAdvertisersIdModelsTranslationKeys.offerModelsOfferRulesAreNotAccepted)
          }

          return undefined
        }

        return {
          value: model.model,
          label: affModelEntity.KEY_TO_TRANSLATED_VALUE[(model.model as unknown as string)?.toUpperCase() as AffModels],
          description: renderModelDescription(),
        }
      }),
      availableModels,
      unavailableModels,
      modelsWithUnacceptedRules,
      getDefaultModel,
    }
  }, [availableOffers?.data, i18n, unavailableOffers?.data])

  return {
    availableOffersError,
    unavailableOffersError,
    isFetching: availableOffersIsLoading || unavailableOffersIsLoading,
    advertisersModels: preparedModels.models,
    availableModels: preparedModels.availableModels,
    unavailableModels: preparedModels.unavailableModels,
    modelsWithUnacceptedRules: preparedModels.modelsWithUnacceptedRules,
    defaultModel: preparedModels.getDefaultModel(),
  }
}
