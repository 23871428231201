import qs from 'qs'

import { GetAdvertiserLogoPathInterface } from './getAdvertiserLogoPath.types'

import { initEnvironmentReact } from '../../../initEnvironmentReact'
import { addCdnToUrl } from '../addCdnToUrl'

const defaultProperties: Omit<GetAdvertiserLogoPathInterface, 'iconName'> = {
  size: 's',
  theme: 'light',
}

export const getAdvertiserLogoPath = (properties: GetAdvertiserLogoPathInterface) => {
  const { size, theme, iconName } = { ...defaultProperties, ...properties }

  const serviceVersion = import.meta.env.VITE_SERVICE_VERSION || initEnvironmentReact.SERVICE_VERSION
  const serviceVersionQueryString = qs.stringify({ version: serviceVersion })

  if (!iconName) {
    console.error('assets > advertiser logo not passed')
  }

  return addCdnToUrl(`/assets/img/advertisers/${theme}/${size}/${iconName}.svg?${serviceVersionQueryString}`)
}
