import { createStyles } from '@affstore-ds/uikit'

export const useAuthWrapperStyles = createStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  formWrapper: {
    position: 'relative',
    boxSizing: 'border-box',
    backgroundColor: theme.other.colors.white[0],
    borderRadius: theme.other.borderRadius[8],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `48px 100px`,
  },
}))
