import { createStyles, rem } from '@affstore-ds/uikit'

export const useDropdownStyles = createStyles(() => ({
  dropdown: {
    '&&': {
      minWidth: rem(230),
      left: 'auto !important',
    },
  },
}))
