import { useMemo } from 'react'

import { getV3PaymentsRegularSettings, patchV3PaymentsRegularSettings, useMutation, useQuery } from '@affiliate-cabinet/rest'
import { BillingTypesPaths, ResponseErrorInterface } from '@affiliate-cabinet/types'

import { conditionsWhiteListTypes } from './constants'

export const useRegularPaymentsSettings = () => {
  const {
    data: regularPaymentMethod,
    isFetching: regularPaymentMethodIsLoading,
    isRefetching: regularPaymentMethodIsRefetching,
    refetch: regularPaymentMethodRefetch,
  } = useQuery<
    unknown,
    ResponseErrorInterface,
    BillingTypesPaths['/v3/payments/regulars/settings']['get']['responses']['200']['content']['application/json']
  >(['getV3PaymentsRegularSettings'], () => getV3PaymentsRegularSettings(), {
    retry: false,
  })
  const setRegularPaymentMethodMutation = useMutation<
    BillingTypesPaths['/v3/payments/regulars/settings']['patch']['responses']['204'],
    ResponseErrorInterface,
    BillingTypesPaths['/v3/payments/regulars/settings']['patch']['requestBody']['content']['application/json']
  >(patchV3PaymentsRegularSettings, {
    onError: (error) => {
      console.error(error)
    },
    retry: false,
  })

  const statuses = useMemo(() => {
    const isRegularPaymentsEnabled = regularPaymentMethod?.status === 'enabled'

    const isConditionsSatisfied = regularPaymentMethod?.conditions?.every((condition) => condition.isSatisfied)

    return {
      isRegularPaymentsEnabledAndConditionsSatisfied: isRegularPaymentsEnabled && isConditionsSatisfied,
      isRegularEnabledAndConditionsNotSatisfied: isRegularPaymentsEnabled && !isConditionsSatisfied,
      isRegularPaymentsEnabled,
      isConditionsSatisfied,
    }
  }, [regularPaymentMethod?.conditions, regularPaymentMethod?.status])

  const conditionsData = useMemo(() => {
    const conditions =
      regularPaymentMethod?.conditions?.filter((condition) => condition.type && conditionsWhiteListTypes.includes(condition.type)) || []
    const isConditionsDisplayable =
      statuses.isRegularPaymentsEnabled && conditions.length > 0 && !conditions.every((condition) => condition.isSatisfied)

    return {
      conditions,
      isConditionsDisplayable,
    }
  }, [regularPaymentMethod?.conditions, statuses.isRegularPaymentsEnabled])

  return {
    regularPaymentMethod,
    regularPaymentMethodRefetch,
    regularPaymentMethodIsRefetching,
    regularPaymentMethodIsLoading,
    setRegularPaymentMethod: setRegularPaymentMethodMutation.mutate,
    setRegularPaymentMethodIsLoading: setRegularPaymentMethodMutation.isLoading,
    statuses,
    conditionsData,
  }
}
