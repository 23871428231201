import React, { useEffect } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Modal } from '@affstore-ds/uikit'

import { useModal } from '../../../../../hooks'
import { commonTranslationKeys } from '../../../../../translations/common.translationKeys'
import { useNavigationContainer } from '../../..'

import { ConfirmRedirectModalTranslationKeys } from './translations/ConfirmRedirectModal.translationKeys'

export const ConfirmRedirectModal = () => {
  const { i18n } = useI18n()

  const { isRedirectConfirmationRequired, allowRedirect, resetRedirectRestrictions } = useNavigationContainer()

  const { opened: openedConfirmLeaveModal, openModal: openConfirmLeaveModal, closeModal: closeConfirmLeaveModal } = useModal()

  const handleConfirmLeaveAccept = () => {
    allowRedirect()
    closeConfirmLeaveModal()
  }

  const handleConfirmLeaveCancel = () => {
    resetRedirectRestrictions()
    closeConfirmLeaveModal()
  }

  useEffect(() => {
    if (!isRedirectConfirmationRequired) {
      return
    }

    openConfirmLeaveModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRedirectConfirmationRequired])

  return (
    <Modal.Confirm
      opened={openedConfirmLeaveModal}
      centered
      centeredContent
      elementVariant="warning"
      title={i18n.t(ConfirmRedirectModalTranslationKeys.confirmRedirectModalTitle)}
      description={i18n.t(ConfirmRedirectModalTranslationKeys.confirmRedirectModalDescription)}
      buttonCancelProperties={{
        children: i18n.t(commonTranslationKeys.commonCancel),
      }}
      buttonAcceptProperties={{
        children: i18n.t(commonTranslationKeys.commonLeave),
      }}
      hideOpenButton
      data-testid="confirm-redirect-modal"
      onCancel={handleConfirmLeaveCancel}
      onAccept={handleConfirmLeaveAccept}
    />
  )
}
