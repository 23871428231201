import { createStyles } from '@affstore-ds/uikit'

export const useGuestFooterStyles = createStyles((theme) => ({
  footer: {
    marginTop: theme.other.spacings[20],
    gap: theme.other.spacings[12],
  },

  footerAgreements: {
    gap: theme.other.spacings[16],
  },
  footerSupport: {
    gap: theme.other.spacings[12],
  },
}))
