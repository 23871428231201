import { BillingTypesComponents } from '@affiliate-cabinet/types'
import { createEnumObject } from '@affstore-ds/types'

const affstoreTransferStatusEnum = createEnumObject<BillingTypesComponents['schemas']['enums.AffstoreTransferStatusEnum']>({
  done: 'done',
  in_progress: 'in_progress',
  not_started: 'not_started',
})

const AFFSTORE_TRANSFER_STATUS = {
  done: affstoreTransferStatusEnum.done,
  inProgress: affstoreTransferStatusEnum.in_progress,
  notStarted: affstoreTransferStatusEnum.not_started,
}

const LEGAL_NOTICE_CONFIRMED = 'legalNoticeConfirmed'

export const affstoreTransferStatusEntity = {
  AFFSTORE_TRANSFER_STATUS,
  LEGAL_NOTICE_CONFIRMED,
}
