import qs from 'qs'

import { getModelAdvertAliasURLPart } from '@affiliate-cabinet/rest'

import { routes } from '../../routes'

// Param in search params for offer page to detect backlink URL
export const OFFER_FROM_URL_PARAM = 'enteredOfferFromURL'

const isFromAdvertiserPage = (pathname?: string) => pathname?.startsWith(routes.ROUTES_OFFERS.offersAdvertisers)

export const getOfferTabLink = (advertiserAlias: string, model: string, value: string) => {
  const currentSearchParameters = window.location.search.slice(1)
  const parsedCurrentSearchParameters = qs.parse(currentSearchParameters)

  const offerRoute = routes.ROUTES_OFFERS.offerTab
    .replace(':advertAlias/:model', getModelAdvertAliasURLPart({ model, advertiserAlias }))
    .replace(':tabValue', value)

  const isOfferPage = window.location.pathname === offerRoute
  const hasOfferFromURLParameter = Boolean(parsedCurrentSearchParameters[OFFER_FROM_URL_PARAM])

  const urlParameterValue =
    !hasOfferFromURLParameter && !isOfferPage
      ? window.location.pathname + window.location.search
      : parsedCurrentSearchParameters[OFFER_FROM_URL_PARAM]?.toString()

  const searchParameters = qs.stringify({
    ...parsedCurrentSearchParameters, // Save only offer page query params
    ...(hasOfferFromURLParameter
      ? { [OFFER_FROM_URL_PARAM]: parsedCurrentSearchParameters[OFFER_FROM_URL_PARAM] }
      : { [OFFER_FROM_URL_PARAM]: isFromAdvertiserPage(urlParameterValue) ? routes.ROUTES_OFFERS.offersAdvertisers : routes.ROUTES_OFFERS.offers }),
    ...(!hasOfferFromURLParameter && !isOfferPage ? { [OFFER_FROM_URL_PARAM]: window.location.pathname } : {}),
  })

  return `${offerRoute}?${searchParameters}`
}

export const getOfferLinkPathParameters = (searchParameters: string) => {
  const offerParameters = new URLSearchParams(searchParameters).get(OFFER_FROM_URL_PARAM) || ''
  const [offerFromUrlParametersValue] = offerParameters.split('?')

  return isFromAdvertiserPage(offerFromUrlParametersValue) ? routes.ROUTES_OFFERS.offersAdvertisers : routes.ROUTES_OFFERS.offers
}
