import React, { memo, PropsWithChildren } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { NavigationContainerProvider } from '../../shared/providers/NavigationContainer'

export const AppRouterProvider = memo(({ children }: PropsWithChildren) => (
  <BrowserRouter>
    <NavigationContainerProvider>{children}</NavigationContainerProvider>
  </BrowserRouter>
))
AppRouterProvider.displayName = 'AppRouterProvider'
