import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { NavLink } from '@affstore-ds/uikit'

import { NavLinkLogoPropertiesInterface } from './NavLinkLogo.types'

import { AdvertiserLogo } from '../../../../shared/assets'
import { handleNavigate } from '../../../../shared/navigation/handleNavigate'
import { useNavigationContainer } from '../../../../shared/providers'
import { routes } from '../../../../shared/routes'

import { useNavLinkLogoStyles } from './NavLinkLogo.styled'

export const NavLinkLogo: FC<NavLinkLogoPropertiesInterface> = ({ iconName }) => {
  const { classes } = useNavLinkLogoStyles()

  const { redirect } = useNavigationContainer()

  // TODO: revert after dashboard in release
  // const defaultLocation = routes.ROUTES_DASHBOARD.dashboard
  const defaultLocation = routes.ROUTES_OFFERS.offers

  return (
    <NavLink
      classNames={classes}
      component={Link}
      label={<AdvertiserLogo size="m" iconName={iconName} h="auto" w={144} />}
      to={defaultLocation}
      onClick={handleNavigate(redirect, defaultLocation)}
      data-testid="navbar-logo"
    />
  )
}
