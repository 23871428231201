import { useState } from 'react'

export const useScrollPosition = () => {
  const [isShowManagerBlockShadow, setIsShowManagerBlockShadow] = useState(false)

  const onScrollPositionChange = ({ y }: { x: number; y: number }) => {
    if (y > 0 && !isShowManagerBlockShadow) {
      setIsShowManagerBlockShadow(true)
    }

    if (y === 0 && isShowManagerBlockShadow) {
      setIsShowManagerBlockShadow(false)
    }
  }

  return {
    isShowManagerBlockShadow,
    onScrollPositionChange,
  }
}
