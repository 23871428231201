import React from 'react'

import { Loader } from '@affstore-ds/uikit'

import { useInputLoaderStyles } from './InputLoader.styled'

export const InputLoader = () => {
  const { classes } = useInputLoaderStyles()

  return <Loader classNames={classes} />
}
