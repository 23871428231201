export const promoLandingsTranslationKeys = {
  promoLandingsGenerateLink: 'promo.generate-link',
  promoLandingsGeneratedLink: 'promo.generated-link',
  promoLandingsCopiedLink: 'promo.copied-link',
  promoLandingsCopyLink: 'promo.copy-link',
  promoLandingsActivateOffer: 'promo.modal-button-activate-offer',
  promoLandingsAcceptRules: 'offers.page-offer-tab-activation-accordion-accept-rules-title',
  promoLandingsPreview: 'promo.preview',
  promoLandingsAfftrack: 'statistics.afftrack-label',
  promoLandingsModel: 'settings.affiliate-model',
  promoTypeHere: 'promo.type-here',
}
