import React from 'react'

import { resolveCookieDomain } from '@affiliate-cabinet/rest'
import { Cookies } from '@affstore-ds/helpers'

import {
  OnboardingToOfferConversionFunnelMapURLParametersType,
  OnboardingToOfferConversionFunnelParametersInterface,
  OnboardingToOfferConversionFunnelTrackedURLParametersType,
} from './OnboardingToOfferConversionFunnel.types'

import { AdvertiserLogo } from '../../../assets'

const TRACKED_URL_PARAMS: OnboardingToOfferConversionFunnelTrackedURLParametersType[] = ['advertiser', 'model', 'program']

const COOKIES_PARAMS_OBJECT: Record<OnboardingToOfferConversionFunnelMapURLParametersType, OnboardingToOfferConversionFunnelMapURLParametersType> = {
  m_oto_advertiser: 'm_oto_advertiser',
  m_oto_model: 'm_oto_model',
  m_oto_program: 'm_oto_program',
}
const COOKIES_PARAMS: OnboardingToOfferConversionFunnelMapURLParametersType[] = Object.values(COOKIES_PARAMS_OBJECT)

const parameterToCookieMap: Record<OnboardingToOfferConversionFunnelTrackedURLParametersType, OnboardingToOfferConversionFunnelMapURLParametersType> =
  {
    advertiser: 'm_oto_advertiser',
    model: 'm_oto_model',
    program: 'm_oto_program',
  }

const COOKIE_EXPIRY = 86_400 * 30
const DELETE_COOKIE_EXPIRY = -99_999_999
const ADVERTISER_ALIASES_TO_LOGO: Record<string, React.ReactNode> = {
  quadcode: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoQuadcode" />,
  iq_option: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoIQ" />,
  iq_option_europe: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoIQ" />,
  exnova: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoExnova" />,
  capital_bear: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoCapitalBear" />,
  qcm_eu: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoQCMAU" />,
  '8xtrade': <AdvertiserLogo size="m" w="100%" h="20px" iconName="Logo8xtrade" />,
  sabiotrade: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoSabioTrade" />,
  'qcm-global': <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoQCMAU" />,
  xoption: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoXOption" />,
  dayprofit: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoDayProfit" />,
  'qcm-eu': <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoQCMAU" />,
  'qcm-au': <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoQCMAU" />,
  'qcm-global2': <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoQCMAU" />,
  invetra: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoInvetra" />,
  'quadcode-b2b': <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoQuadcode" />,
  avalon: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoAvalon" />,
  fxoption: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoFxOption" />,
  bullex: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoBullex" />,
  xq_trade: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoXqTrade" />,
  mangotrade: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoMangoTrade" />,
  assetise: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoAssetise" />,
  optinary: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoOptinary" />,
  OPTGO: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoOptGo" />,
  capitalbear: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoCapitalBear" />,
  pixtrade: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoPIXTRADE" />,
  broker10: <AdvertiserLogo size="m" w="100%" h="20px" iconName="LogoBroker10" />,
  '9expert': <AdvertiserLogo size="m" w="100%" h="20px" iconName="Logo9expert" />,
}

const ADVERTISER_ALIASES_TO_BRAND_NAME: Record<string, string> = {
  quadcode: 'Quadcode',
  iq_option: 'IQ Option',
  iq_option_europe: 'IQ Option Europe',
  exnova: 'Exnova',
  capital_bear: 'Capital Bear',
  qcm_eu: 'Quadcode Markets EU',
  '8xtrade': '8xtrade',
  sabiotrade: 'SabioTrade',
  'qcm-global': 'Quadcode Markets Global',
  xoption: 'X Option',
  dayprofit: 'DayProfit',
  'qcm-eu': 'Quadcode Markets EU',
  'qcm-au': 'Quadcode Markets AU',
  'qcm-global2': 'Quadcode Markets Global',
  invetra: 'Invetra',
  'quadcode-b2b': 'Quadcode B2B White Label',
  avalon: 'Avalon',
  fxoption: 'FxOption',
  bullex: 'Bullex',
  xq_trade: 'XQ Trade',
  mangotrade: 'MangoTrade',
  assetise: 'Assetise.io',
  optinary: 'OPTINARY',
  OPTGO: 'OPTGO',
  capitalbear: 'Capital Bear',
  pixtrade: 'PIXTRADE',
  broker10: 'Broker10',
  '9expert': '9expert',
}

const parametersToObject = (): OnboardingToOfferConversionFunnelParametersInterface => {
  const searchParametersString = window.location.search
  const searchParameters = new URLSearchParams(searchParametersString)
  const parametersEntries = searchParameters.entries()
  const result: OnboardingToOfferConversionFunnelParametersInterface = {}

  for (const [key, value] of parametersEntries) {
    result[key] = value
  }

  return result
}

const filterAndRenameParameters = (
  parameters: OnboardingToOfferConversionFunnelParametersInterface,
): Map<OnboardingToOfferConversionFunnelMapURLParametersType, string> =>
  TRACKED_URL_PARAMS.reduce((accumulator, key) => {
    const value = parameters[key]

    if (value !== undefined && value !== null) {
      const cookieKey = parameterToCookieMap[key] || key

      accumulator.set(cookieKey, value)
    }

    return accumulator
  }, new Map())

const parameters = parametersToObject()
const filteredParameters = filterAndRenameParameters(parameters)

export const OnboardingToOfferConversionFunnel = {
  setMarketingURLParametersToCookies: () => {
    const cookieDomain = resolveCookieDomain()

    if (filteredParameters.size <= 0) {
      return
    }

    for (const key of COOKIES_PARAMS) {
      const value = filteredParameters.get(key)

      Cookies.setCookie(key, value || '', {
        domain: cookieDomain,
        expires: value ? COOKIE_EXPIRY : DELETE_COOKIE_EXPIRY,
      })
    }
  },
  getMarketingURLParametersFromCookies: () =>
    COOKIES_PARAMS.reduce((accumulator, key) => {
      const trackedParameterValue = Cookies.getCookie(key)

      if (trackedParameterValue) {
        accumulator.set(key, trackedParameterValue)
      }

      return accumulator
    }, new Map<OnboardingToOfferConversionFunnelMapURLParametersType, string>()),
  clearCookies: () => {
    for (const key of COOKIES_PARAMS) {
      const value = filteredParameters.get(key)

      const cookieDomain = resolveCookieDomain()

      Cookies.setCookie(key, value || '', {
        domain: cookieDomain,
        expires: DELETE_COOKIE_EXPIRY,
      })
    }
  },
  COOKIES_PARAMS_OBJECT,
  ADVERTISER_ALIASES_TO_LOGO,
  ADVERTISER_ALIASES_TO_BRAND_NAME,
}
