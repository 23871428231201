import { useLocation, useSearchParams } from 'react-router-dom'

export const useFrom = (fallbackPath: string) => {
  const location = useLocation()
  const [search] = useSearchParams()

  const returnToFromReactRouter = `${location.state?.from?.pathname || ''}${location.state?.from?.search || ''}`.trim() || fallbackPath
  const returnToFromURLSearch = search.get('returnTo') || location.state?.returnTo

  return {
    returnToFromReactRouter,
    returnToFromURLSearch: returnToFromURLSearch ? decodeURIComponent(returnToFromURLSearch) : undefined,
  }
}
