import { addTransitionForCssProperties, createStyles } from '@affstore-ds/uikit'

import { DATA_TRANSLATION_ATTRIBUTE } from '../constants'

export const useHtmlStyles = createStyles((theme) => ({
  html: {
    [`& [data-translation="${DATA_TRANSLATION_ATTRIBUTE}"]`]: {
      font: theme.other.fonts.desktop.s.s.default,
      color: theme.other.colors.greyIron[16],

      '& *': {
        font: theme.other.fonts.desktop.s.s.default,
        color: theme.other.colors.greyIron[16],
      },

      '& p': {
        padding: 0,
        margin: 0,
        marginBottom: 8,
      },
      '& b': {
        font: theme.other.fonts.desktop.s.s.header,
        color: theme.other.colors.greyIron[16],
      },
      '& ul': {
        paddingLeft: 30,

        '& li': {
          marginTop: 4,
          paddingLeft: 8,

          '&:first-of-type': {
            marginTop: 0,
          },
          '&::marker': {
            fontSize: 18,
          },
        },
      },
      '& table': {
        borderCollapse: 'collapse',
      },
      '& table, th, td': {
        border: '1px solid',
      },
      '& td': {
        padding: '4px',
      },
      '& h3, & h3 > span': {
        font: theme.other.fonts.desktop.s.m.header,
        fontWeight: 500,
        color: theme.other.colors.greyIron[18],
      },
      '& a': {
        color: theme.other.colors.blue[13],
        textDecoration: 'underline',
        textUnderlineOffset: 4,
        textDecorationColor: theme.other.colors.blue[9],
        transition: addTransitionForCssProperties(['color', 'text-decoration-color']),

        '&::selection': {
          backgroundColor: theme.other.colors.blue[2],
        },

        '&:hover': {
          textDecorationColor: theme.other.colors.blue[13],
          color: theme.other.colors.blue[13],
        },

        '&:active': {
          color: theme.other.colors.blue[15],
          textDecorationColor: theme.other.colors.blue[15],
        },

        '&:visited': {
          color: theme.other.colors.blue[15],
          textDecorationColor: theme.other.colors.blue[15],

          '&:hover': {
            textDecorationColor: theme.other.colors.blue[15],
          },

          '&:active': {
            color: theme.other.colors.blue[17],
            textDecorationColor: theme.other.colors.blue[17],
          },
        },
      },
    },
  },
}))
