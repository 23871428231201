import { createStyles } from '@affstore-ds/uikit'

export const useMainScrollContainerStyles = createStyles((theme) => ({
  scrollContainerRoot: {
    position: 'relative',
    height: theme.other.viewport.height,
    scrollBehavior: 'smooth',
  },
  scrollbar: {
    zIndex: 999_999,
  },
}))
