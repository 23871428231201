import { AffModels } from '@affiliate-cabinet/graphql'
import { capitaliseString } from '@affstore-ds/helpers'

import { selectTranslationKeys } from '../../shared/translations/select.translationKeys'

const KEY_TO_TRANSLATED_VALUE = {
  [AffModels.Cpa]: AffModels.Cpa,
  [AffModels.Lot]: AffModels.Lot,
  [AffModels.Revenue]: capitaliseString(AffModels.Revenue),
  [AffModels.Spread]: capitaliseString(AffModels.Spread),
}

const LIST = [
  {
    value: '',
    translationKey: selectTranslationKeys.selectNotSelected,
    'data-testid': `affModels-item-not-selected`,
  },
  { value: AffModels.Cpa, label: KEY_TO_TRANSLATED_VALUE[AffModels.Cpa], 'data-testid': `affModels-item-${AffModels.Cpa}` },
  { value: AffModels.Lot, label: KEY_TO_TRANSLATED_VALUE[AffModels.Lot], 'data-testid': `affModels-item-${AffModels.Lot}` },
  { value: AffModels.Revenue, label: KEY_TO_TRANSLATED_VALUE[AffModels.Revenue], 'data-testid': `affModels-item-${AffModels.Revenue}` },
  { value: AffModels.Spread, label: KEY_TO_TRANSLATED_VALUE[AffModels.Spread], 'data-testid': `affModels-item-${AffModels.Spread}` },
]

export const affModelEntity = {
  LIST,
  KEY_TO_TRANSLATED_VALUE,
}
