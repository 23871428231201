import { errorCallback } from '@affiliate-cabinet/auth'
import { ConfigType, initConfig } from '@affiliate-frontend-analytics/events'

import { getBrandConfig } from '../brands'

import { appVersion } from './appVersion'
import { SYSTEM_EVENTS_NAMES } from './constants'
import { FGP_TOKEN } from './fingerprintToken'

const {
  brandConfig: { brandId, brandName },
} = getBrandConfig()

const baseConfig: ConfigType = {
  brandId,
  apiKey: FGP_TOKEN,
  appVersion: `${brandName}/version-${appVersion}`,
  systemEventsNames: SYSTEM_EVENTS_NAMES,
  errorCallback,
}

export const createEventConfig = (additionalConfig: ConfigType) => {
  initConfig({
    ...baseConfig,
    ...additionalConfig,
  })
}
