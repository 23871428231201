import React, { useMemo, useState } from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { useI18n } from '@affiliate-cabinet/i18n'
import { ChevronDown13 } from '@affstore-ds/icons'
import { Avatar, ButtonVariantEnum, Divider, getContactAvatar, Link, NavLink, Popover, Stack, Text } from '@affstore-ds/uikit'

import { featuresStatuses } from '../../../../config/brands'
import { addCdnToUrl } from '../../../../shared/assets'
import { DATE_HH_MM, getCreateSupportTicketLink, NO_MANAGER_ID } from '../../../../shared/constants'

import { ManagerContact } from './components/ManagerContact'
import { managersInfoTranslationKeys } from './translations/managersInfo.translationKeys'
import { DEFAULT_MANAGER_ID } from './constants'
import { useNavMenuManagerInfo } from './NavMenuManagerInfo.styled'
import { getVacationData, getWorkingHours, mapManagerStatusToAvatarElementVariant } from './utils'

export const NavMenuManagerInfo = () => {
  const {
    auth: { user },
  } = useAuth()
  const { i18n } = useI18n()

  const { classes, cx } = useNavMenuManagerInfo()
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)

  const isDefaultManager = String(user?.manager?.id) === DEFAULT_MANAGER_ID

  const getDescriptionData = useMemo(() => {
    const { vacationType, managerOnVacationTypeTranslationKey, dateToFormatted } = getVacationData({
      vacation: user?.manager?.leave,
      locale: i18n.locale(),
    })

    if (vacationType) {
      return (
        <Text elementSize="xs" elementVariant="header">
          {i18n.replace(managerOnVacationTypeTranslationKey, {
            '%s': dateToFormatted,
          })}
        </Text>
      )
    }

    if (user?.manager?.working_hours) {
      const { adaptedManagerFromTime, adaptedManagerToTime } = getWorkingHours({ workingHours: user?.manager?.working_hours, locale: i18n.locale() })

      return (
        adaptedManagerFromTime.isValid &&
        adaptedManagerToTime.isValid && (
          <>
            <Text elementSize="s" elementVariant="default" colorVariant="greyIron.12">
              {adaptedManagerFromTime.toFormat(DATE_HH_MM)} {'–'} {adaptedManagerToTime.toFormat(DATE_HH_MM)}
            </Text>
            <Text elementSize="s" elementVariant="default" colorVariant="greyIron.12">
              {i18n.t(managersInfoTranslationKeys.managerDaysWork)}
            </Text>
          </>
        )
      )
    }

    return null
  }, [i18n, user?.manager?.leave, user?.manager?.working_hours])

  const renderContacts = () => {
    if (user?.manager?.alt_contact.length) {
      return user?.manager.alt_contact.map((contact) => (
        <ManagerContact
          key={contact.type}
          contactAvatar={getContactAvatar(contact.type)}
          contactName={contact.value}
          contactType={contact.type}
          data-testid={`manager-contact-${contact.type}`}
        />
      ))
    }

    return null
  }

  return user?.is_onboarding_passed && user?.manager?.id !== NO_MANAGER_ID ? (
    <Popover
      width={224}
      position="top"
      offset={0}
      transitionProps={{
        transition: 'fade',
        duration: 300,
      }}
      opened={isDropdownOpened}
      onChange={setIsDropdownOpened}
    >
      <Popover.Target>
        <div>
          <NavLink
            onClick={() => setIsDropdownOpened(!isDropdownOpened)}
            classNames={{
              root: cx(classes.navLink, { [classes.navLinkIsOpened]: isDropdownOpened }),
              label: classes.label,
            }}
            leftSection={
              <Avatar
                src={user.manager?.photo ? addCdnToUrl(`/assets/img/managers/${user.manager?.photo}`) : undefined}
                elementVariant={mapManagerStatusToAvatarElementVariant(user?.manager)}
                alt={`${user?.manager?.first_name} ${user?.manager?.last_name}`}
                data-testid="avatar-manager"
              />
            }
            leftSectionSize={40}
            leftSectionOffset={12}
            label={`${(user?.manager?.first_name || '') as string} ${((user?.manager?.last_name || '') as string).slice(0, 1)}.`}
            rightSection={<ChevronDown13 className={cx(classes.rightSection, { [classes.rightSectionOpened]: isDropdownOpened })} />}
            description={getDescriptionData}
            data-testid="navbar-manager"
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown className={classes.dropdown}>
        <Text elementSize="s" elementVariant="default" colorVariant="greyIron.12">
          {i18n.t(managersInfoTranslationKeys.navMenuManagersContacts)}
        </Text>
        <Stack mt={16} spacing={12}>
          {renderContacts()}
        </Stack>
        {featuresStatuses.isInfoLinkEnabled && !isDefaultManager && (
          <Link
            fullWidth
            elementVariant={ButtonVariantEnum.PrimaryMain}
            href={getCreateSupportTicketLink()}
            target="_blank"
            rel="noreferrer noopener"
            mt={20}
            data-testid="manager-sendMessage"
          >
            {i18n.t(managersInfoTranslationKeys.navMenuButtonSendMessage)}
          </Link>
        )}
        <Divider className={classes.divider} />
      </Popover.Dropdown>
    </Popover>
  ) : null
}
