import { createStyles, rem } from '@affstore-ds/uikit'

export const useVideoCoverStyles = createStyles((theme) => ({
  background: {
    margin: rem(-25),
    width: `${rem(540)} !important`,
    borderTopLeftRadius: `${theme.other.spacings[8]} !important`,
    borderTopRightRadius: `${theme.other.spacings[8]} !important`,
    cursor: 'pointer',
    position: 'relative',
  },
  container: {
    position: 'relative',
    justifyContent: 'space-between',
    width: rem(540),
    height: rem(304),
    padding: theme.other.spacings[24],
  },
  playIcon: {
    fill: theme.other.colors.white[0],
    color: `${theme.other.colors.white[0]} !important`,
  },
  playButton: {
    width: 68,
    height: 48,
    backgroundColor: theme.fn.rgba(theme.other.colors.white[0], 0.12),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    color: theme.other.colors.white[0],
  },
  subtitle: {
    color: theme.other.colors.greyIron[9],
  },
}))
