import { createStyles, rem } from '@affstore-ds/uikit'

const MANTINE_REFFERENCE_BUTTON_ICON = 'mantine-Button-icon'

export const useNavMenuButtonLogoutStyles = createStyles((theme) => ({
  root: {
    border: 'none !important',
    [`&:disabled .${MANTINE_REFFERENCE_BUTTON_ICON}`]: {
      color: 'currentcolor',
    },
    padding: `${rem(8)} ${rem(12)}`,
  },
  inner: {
    justifyContent: 'space-between',
    color: theme.other.colors.greyIron[18],
  },
  icon: {
    color: theme.other.colors.brand[13],
  },
}))
