import { addTransitionForCssProperties, createStyles, rem } from '@affstore-ds/uikit'

export const useNavMenuManagerInfo = createStyles((theme) => ({
  navLink: {
    transition: addTransitionForCssProperties(['background-color', 'outline-color', 'color', 'border-top-left-radius', 'border-top-right-radius']),
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  navLinkIsOpened: {
    backgroundColor: theme.other.colors.greyIron[2],
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  dropdown: {
    boxSizing: 'border-box',
    backgroundColor: theme.other.colors.greyIron[2],
    border: 'none',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: 'none',
    padding: 12,
    paddingBottom: 16,
  },
  divider: {
    marginTop: theme.other.spacings[20],
    marginBottom: rem(-4),
  },
  rightSection: {
    transition: addTransitionForCssProperties(['transform']),
  },
  rightSectionOpened: {
    transform: 'rotate(180deg)',
  },
  dateVacation: {
    paddingRight: 2,
  },
}))
