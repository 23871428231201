export const navMenuTranslationKeys = {
  navMenuItemDashboard: 'nav-menu.item-dashboard',
  navMenuItemPromo: 'nav-menu.item-promo',
  navMenuItemOffers: 'nav-menu.item-offers',
  navMenuItemOffersByAdvertisers: 'nav-menu.item-offers-by-advertisers',
  navMenuItemOffersByOffers: 'nav-menu.item-offers-by-offers',
  navMenuItemStatistics: 'nav-menu.item-statistics',
  navMenuItemTop: 'nav-menu.item-top',
  navMenuItemContests: 'nav-menu.item-contests',
  navMenuItemStatisticsGeneral: 'nav-menu.item-statistics-general',
  navMenuItemStatisticsUsers: 'nav-menu.item-statistics-by-users',
  navMenuItemPayments: 'nav-menu.item-payments',
  navMenuItemInfo: 'nav-menu.item-info',
  navMenuItemBlog: 'nav-menu.item-blog',
  navMenuItemSubAffiliate: 'nav-menu.item-sub-affiliate',
  navMenuItemPostbacks: 'nav-menu.item-postbacks',
  navMenuItemPostbacksGeneral: 'nav-menu.item-postbacks-general',
  navMenuItemPostbacksTelegram: 'nav-menu.item-postbacks-telegram',
  navMenuItemProfile: 'nav-menu.item-profile',
  navMenuItemProfileSettings: 'nav-menu.item-profile-settings',
  navMenuItemProfileSecurity: 'nav-menu.item-profile-security',
  navMenuItemProfileKyc: 'nav-menu.item-profile-kyc',
}
