import qs from 'qs'

import { PromoNewApiPaths, PromoNewOperations } from '@affiliate-cabinet/types'

import { resolveDomain } from '../../../utils'

export const getApiV3PromoBannersIdDownload = ({
  id,
  ...query
}: PromoNewOperations['GetApiV3PromoBannersIdDownload']['parameters']['path'] &
  PromoNewOperations['GetApiV3PromoBannersIdDownload']['parameters']['query']) => {
  const queryParameters = qs.stringify(query, { indices: false })

  return `${resolveDomain()}${PromoNewApiPaths.GetApiV3PromoBannersIdDownload.replace(':id', id.toString())}?${queryParameters}`
}
