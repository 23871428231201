import { OffersTypesComponents } from '@affiliate-cabinet/types'

import { advertisersTranslationKeys } from '../translations/advertisersTranslationKeys'

export const unknownAdvertiser: OffersTypesComponents['schemas']['Advertiser'] & {
  value: string
  translationKey: string
  'data-testid': string
} = {
  id: 1,
  value: '1',
  name: 'unknown',
  alias: 'unknown',
  description: 'unknown',
  shortDescription: 'unknown',
  links: [],
  platforms: [],
  logo: '',
  regions: [],
  restrictionGrade: 0,
  theme: [],
  website: 'unknown',
  translationKey: advertisersTranslationKeys.advertiserXXX.replace('xxx', 'unknown'),
  'data-testid': `advertiser-item-1`,
}
