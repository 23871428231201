import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getApiV3PromoBannersId, useQuery } from '@affiliate-cabinet/rest'
import { PromoNewComponents, PromoNewOperations, ResponseErrorInterface } from '@affiliate-cabinet/types'
import { groupBy } from '@affstore-ds/helpers'

import { useBannersIdFilters } from '../useBannersIdFilters'

import { promoTabSettings } from '../../../../../../../shared/navigation/NavMenuData'
import { getPromoTabLink } from '../../../../../base/utils/getPromoTabLink'

export const usePromoBannersId = () => {
  const { id = '' } = useParams()
  const navigate = useNavigate()

  const { filters, handleFiltersChange } = useBannersIdFilters(id)

  const { data: rootBanner, isFetching } = useQuery<
    PromoNewOperations['GetApiV3PromoBannersId']['parameters']['path'],
    ResponseErrorInterface,
    PromoNewComponents['schemas']['Banner']
  >(['getApiV3PromoBannersId', id], () => getApiV3PromoBannersId({ id: Number(id) }), { keepPreviousData: true })

  const handleBackLink = useCallback(() => {
    navigate(getPromoTabLink(promoTabSettings.banners))
  }, [navigate])

  const childBannersFiltered = useMemo(() => {
    let bannersList = Object.keys(rootBanner?.links || {}).map((bannerLinkKey) => rootBanner?.links[bannerLinkKey])

    if (filters.locales?.length) {
      bannersList = bannersList.filter((bannerItem) => bannerItem && filters.locales?.includes(bannerItem.locale))
    }

    if (filters.imageSizes?.length) {
      bannersList = bannersList.filter((bannerItem) => bannerItem && filters.imageSizes?.includes(bannerItem.size))
    }

    if (filters.fileFormats?.length) {
      bannersList = bannersList.filter((bannerItem) => bannerItem && filters.fileFormats?.includes(bannerItem.type))
    }

    return bannersList
  }, [filters.fileFormats, filters.locales, filters.imageSizes, rootBanner?.links])

  const childBannersGroupedByLocale = useMemo(() => groupBy(childBannersFiltered, (value) => value?.locale || ''), [childBannersFiltered])
  const childBannersLocales = useMemo(() => Object.keys(childBannersGroupedByLocale), [childBannersGroupedByLocale])

  return {
    rootBanner,
    childBannersLocales,
    childBanners: childBannersGroupedByLocale,
    isFetching,
    handleBackLink,
    filters,
    handleFiltersChange,
  }
}
