import { createStyles, rem } from '@affstore-ds/uikit'

export const useHeaderMainStyles = createStyles((theme) => ({
  header: {
    zIndex: 9_999_999,
    position: 'sticky',
    top: 0,
    left: 0,
    padding: `${theme.other.spacings[16]} ${theme.other.spacings[32]}`,
    borderBottom: `1px solid ${theme.other.colors.greyIron[4]}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerControls: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  divider: {
    display: 'block',
    height: rem(32),
  },
  balance: {
    marginRight: theme.other.spacings[12],
  },
}))
