import { BillingTypesPaths } from '@affiliate-cabinet/types'

import { BillingPathBaseEnum, BillingPathsEnum } from '../types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(BillingPathBaseEnum['/api/rest/affiliate'], {}, resolveDomain(), {
  mode: 'cors',
  referrerPolicy: 'no-referrer',
})

export const getV3PaymentsRegularSettings = () =>
  client.get<BillingTypesPaths['/v3/payments/regulars/settings']['get']['responses']['200']['content']['application/json']>(
    BillingPathsEnum['/v3/payments/regulars/settings'],
  )
