import React from 'react'
import { Navigate, Route } from 'react-router-dom'

import { featuresStatuses } from '../config/brands'
import { profileTabSettingsPersonal, promoTabSettings } from '../shared/navigation/NavMenuData'
import { routes } from '../shared/routes'
import { lazyLoadPageWithRetry } from '../shared/utils'

import { AuthWrapper } from './auth/base/containers/AuthWrapper'
import { PromoBannersById } from './promo/banners/:id'
import { renderPostbacks } from './postbacks'

const OnBoarding = lazyLoadPageWithRetry(() => import('./onboarding'))

// const Dashboard = lazyLoadPageWithRetry(() => import('./dashboard'))
const Promo = lazyLoadPageWithRetry(() => import('./promo'))

const Offers = lazyLoadPageWithRetry(() => import('./offers'))
const OffersByAdvertisers = lazyLoadPageWithRetry(() => import('./offers/advertisers'))
const Offer = lazyLoadPageWithRetry(() => import('./offers/offer'))

const StatisticsGeneral = lazyLoadPageWithRetry(() => import('./statistics/general'))
const StatisticsByUsers = lazyLoadPageWithRetry(() => import('./statistics/users'))
const StatisticsReferral = lazyLoadPageWithRetry(() => import('./statistics/referral'))

const Payments = lazyLoadPageWithRetry(() => import('./payments'))
const Top = lazyLoadPageWithRetry(() => import('./top'))

const Contests = lazyLoadPageWithRetry(() => import('./contests'))
const ContestsType = lazyLoadPageWithRetry(() => import('./contests/_contestsType'))
const Contest = lazyLoadPageWithRetry(() => import('./contests/contest'))
const ContestTab = lazyLoadPageWithRetry(() => import('./contests/contest/_contestTab'))

const ProfileSettings = lazyLoadPageWithRetry(() => import('./profile/settings'))
const ProfileSecurity = lazyLoadPageWithRetry(() => import('./profile/security'))
const ProfileKYC = lazyLoadPageWithRetry(() => import('./profile/kyc'))

const SignIn = lazyLoadPageWithRetry(() => import('./auth/signin'))
const SignUp = lazyLoadPageWithRetry(() => import('./auth/signup'))
const TwoFactorAuth = lazyLoadPageWithRetry(() => import('./auth/two-factor-auth'))
const ResetPasswordRequest = lazyLoadPageWithRetry(() => import('./auth/reset-password-request'))
const ResetPassword = lazyLoadPageWithRetry(() => import('./auth/reset-password'))
const Activate = lazyLoadPageWithRetry(() => import('./auth/activate'))

const DownloadAuthenticatorApp = lazyLoadPageWithRetry(() => import('./download-authenticator-app'))
const PaymentAccountConfirm = lazyLoadPageWithRetry(() => import('./payment-account-confirm'))
const PrivacyPolicy = lazyLoadPageWithRetry(() => import('./privacy-policy'))
const TermsAndConditions = lazyLoadPageWithRetry(() => import('./terms-and-conditions'))
const ByTransferTokenPage = lazyLoadPageWithRetry(() => import('./auth/by-transfer-token'))

export const renderRoutingProtected = () => (
  <>
    <Route path={routes.ROUTES_ONBOARDING.onboarding} element={<OnBoarding />} />
    {/* TODO: revert after dashboard in release */}
    {/*<Route path={routes.ROUTES_DASHBOARD.dashboard} element={<Dashboard />} />*/}
    {featuresStatuses.isPromoPageEnabled && (
      <>
        <Route path={routes.ROUTES_PROMO.promoTab} element={<Promo />} />
        <Route path={routes.ROUTES_PROMO.promoTabById.replace(':tabValue', promoTabSettings.banners)} element={<PromoBannersById />} />
      </>
    )}

    <Route>
      <Route path={routes.ROUTES_OFFERS.offers} element={<Offers />} />
      <Route path={routes.ROUTES_OFFERS.offersAdvertisers} element={<OffersByAdvertisers />} />
      <Route path={routes.ROUTES_OFFERS.offerTab} element={<Offer />} />
      <Route path={routes.ROUTES_OFFERS.offerModel} element={<Offer />} />
    </Route>

    <Route>
      <Route path={routes.ROUTES_STATISTICS.general} element={<StatisticsGeneral />} />
      <Route path={routes.ROUTES_STATISTICS.users} element={<StatisticsByUsers />} />
    </Route>
    <Route path={routes.ROUTES_PAYMENTS.paymentsTab} element={<Payments />} />
    <Route path={routes.ROUTES_TOP.root} element={featuresStatuses.isTop10PageEnabled ? <Top /> : <Navigate to={routes.ROUTES_ERROR['404']} />} />

    <Route>
      <Route
        path={routes.ROUTES_CONTESTS.root}
        element={featuresStatuses.isContestsPageEnabled ? <Contests /> : <Navigate to={routes.ROUTES_ERROR['404']} />}
      >
        <Route path={routes.ROUTES_CONTESTS.root} element={<Navigate to={routes.ROUTES_ERROR['404']} />} />
        <Route path={routes.ROUTES_CONTESTS.tabsPath} element={<ContestsType />} />
      </Route>
      <Route
        path={routes.ROUTES_CONTESTS.contestPath}
        element={featuresStatuses.isContestsPageEnabled ? <Contest /> : <Navigate to={routes.ROUTES_ERROR['404']} />}
      >
        <Route path={routes.ROUTES_CONTESTS.contestTabsPath} element={<ContestTab />} />
      </Route>
    </Route>

    {featuresStatuses.isSubAffiliatePageEnabled && <Route path={routes.ROUTES_SUB_AFFILIATE.subAffiliate} element={<StatisticsReferral />} />}

    {renderPostbacks()}

    <Route>
      {featuresStatuses.isKYCEnabled && <Route path={routes.ROUTES_PROFILE.profileKYC} element={<ProfileKYC />} />}
      <Route path={routes.ROUTES_PROFILE.profileSecurity} element={<ProfileSecurity />} />
      <Route path={routes.ROUTES_PROFILE.profileSettings} element={<Navigate to={profileTabSettingsPersonal} />} />
      <Route path={routes.ROUTES_PROFILE.profileSettingsTab} element={<ProfileSettings />} />
    </Route>
  </>
)

export const renderRoutingGuest = () => (
  <>
    <Route path="/auth" element={<AuthWrapper />}>
      <Route path={routes.ROUTES_AUTH.signIn} element={<SignIn />} />
      <Route path={routes.ROUTES_AUTH.signUp} element={<SignUp />} />
      <Route path={routes.ROUTES_AUTH.twoFA} element={<TwoFactorAuth />} />
      <Route path={routes.ROUTES_AUTH.resetPasswordRequest} element={<ResetPasswordRequest />} />
      <Route path={routes.ROUTES_AUTH.resetPassword} element={<ResetPassword />} />
      <Route path={routes.ROUTES_AUTH.activate} element={<Activate />} />
      <Route path="*" element={<Navigate to={routes.ROUTES_AUTH.signIn} />} />
    </Route>
  </>
)

export const renderSharedRoutes = () => (
  <>
    <Route path={routes.ROUTES_SHARED.authenticatorRedirect} element={<DownloadAuthenticatorApp />} />
    <Route path={routes.ROUTES_SHARED.paymentAccountConfirm} element={<PaymentAccountConfirm />} />
    <Route path={routes.ROUTES_SHARED.privacyPolicy} element={<PrivacyPolicy />} />
    <Route path={routes.ROUTES_SHARED.termsAndConditions} element={<TermsAndConditions />} />
    <Route path={routes.ROUTES_SHARED.byTransferToken} element={<ByTransferTokenPage />} />
  </>
)
