import React, { useCallback, useMemo } from 'react'

import { I18nDictionaryLanguageKeysType, useI18n } from '@affiliate-cabinet/i18n'

import { useTransferGuideContainer } from '../../hooks/useTransferGuideContainer'
import { usePostV3AffstoreTransferLogin } from './base/hooks/usePostV3AffstoreTransferLogin'

import { GuideModal } from './base/components/GuideModal'
import { LegalNoticeModal } from './base/components/LegalNoticeModal'
import { TransferInfoModal } from './base/components/TransferInfoModal'
import { mapLocaleToGuideVideoUrl } from './base/constants/mapLocaleToGuideVideoUrl'
import { transferPartnerTranslationKeys } from './base/translations/TransferPartner.translationKeys'

export const TransferGuide = () => {
  const { i18n } = useI18n()
  const { postV3AffstoreTransferLoginMutation } = usePostV3AffstoreTransferLogin()
  const { isGuideOpened, closeGuide, finishGuide, isIntroStep, setLegalStep, isOldLoginGuide, setIsOldLoginGuide } = useTransferGuideContainer()

  const isGuideStep = useMemo(() => isGuideOpened && isIntroStep && !isOldLoginGuide, [isOldLoginGuide, isGuideOpened, isIntroStep])
  const isLegalNoticeStep = useMemo(() => isGuideOpened && !isIntroStep && !isOldLoginGuide, [isOldLoginGuide, isGuideOpened, isIntroStep])

  const onAcceptTransferInfoModal = useCallback(() => {
    postV3AffstoreTransferLoginMutation.mutate(
      {
        popupText: i18n.t(transferPartnerTranslationKeys.infoTransferDescription),
      },
      {
        onSuccess: () => {
          setIsOldLoginGuide(false)
        },
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <GuideModal
        opened={isGuideStep}
        onClose={closeGuide}
        onButtonClick={setLegalStep}
        iFrameURL={mapLocaleToGuideVideoUrl[i18n.locale() as I18nDictionaryLanguageKeysType]}
      />
      <LegalNoticeModal opened={isLegalNoticeStep} onAccept={finishGuide} onClose={closeGuide} />
      <TransferInfoModal opened={isOldLoginGuide} onAccept={onAcceptTransferInfoModal} isLoading={postV3AffstoreTransferLoginMutation.isLoading} />
    </>
  )
}
