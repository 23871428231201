import { TwoFactorAuthTypesApiPaths, TwoFactorAuthTypesComponents } from '@affiliate-cabinet/types'

import { resolveDomain, RestClient } from '../../../utils'

const client = new RestClient(TwoFactorAuthTypesApiPaths.PostApiAuth2faEnable, {}, resolveDomain(), { mode: 'cors', referrerPolicy: 'no-referrer' })

export const enable2FaAuthHandler = async (data: TwoFactorAuthTypesComponents['schemas']['2FaEnableInput']) => {
  await client.post<TwoFactorAuthTypesComponents['schemas']['2FaEnableInput'], Response>('', data, {
    'Content-Type': 'application/json',
  })
}
