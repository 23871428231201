import React, { memo } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import { RestQueryClientProviderPropertiesInterface } from './RestQueryClientProvider.types'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

export const RestQueryClientProvider = memo(({ children, withDevelopmentTools }: RestQueryClientProviderPropertiesInterface) => (
  <QueryClientProvider client={queryClient}>
    {children}
    {withDevelopmentTools && (
      <ReactQueryDevtools
        initialIsOpen={false}
        panelProps={{
          style: { zIndex: 1_000_000 },
        }}
      />
    )}
  </QueryClientProvider>
))
RestQueryClientProvider.displayName = 'RestQueryClientProvider'
